import {history, LocationMatch} from 'components/Router'
import {createUrl, hostname, track} from 'hooks'
import React from 'react'
import PrimaryButton from "../../components/PrimaryButton"

type PrivacyProps = {
  match: LocationMatch
}

const Privacy = (props: PrivacyProps) => (
  <div>
    <div className={'my-6 md:-ml-4'}>
      <PrimaryButton event={'back-button'} title={'Zurück'} active={true} onClick={() => {
        track('button-back-to-homepage-clicked')
        history.go(-1)
      }} buttonId={'privacy-back-button'}/>
    </div>

    <div className="flex flex-col mb-2 container md:mt-6 lg:mt-10 bg-gray-100 rounded shadow px-8 py-5 overflow-x-hidden">
      <img src="/images/Logo.png" className="w-1/4 min-w-150 cursor-pointer" alt={'logo'} onClick={() => {
        track('logo-clicked')
        history.push(createUrl('', props.match))
      }}/>

      <h3 className={'text-bold text-3xl'}>Datenschutzerklärung für {hostname()}</h3>

      <span className={'mt-8 font-semibold text-2xl'}>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten</span>
      <span>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</span>
      <span>Verantwortlicher: AUTOHAUSEN® AG, Vorstand Frank Ritter, In der Spöck 4, 77656 Offenburg, Deutschland. E-Mail: frank.ritter@autohausen.de, Telefon: +49 (0) 151 547 748 66, Fax: +49 (0) 781 611 115.</span>
      <span>Ein betrieblicher Datenschutzbeauftragter wurde nicht bestellt.</span>
      <span className={'mt-8 font-semibold text-2xl'}>2. Allgemeines, Geltungsbereich der Datenschutzerklärung</span>
      <span>Diese Datenschutzerklärung gilt für {hostname()}. {hostname()} ermöglicht Ihnen, wenn Sie Ihr Gebrauchtfahrzeug verkaufen oder in Zahlung geben wollen, die Ermittlung des voraussichtlichen Händlerankaufspreises und die Übermittlung Ihrer Anfrage an das von Ihnen ausgewählte Autohaus. Wenn Sie ein Autohaus auswählen und ihm eine Anfrage übermitteln lassen, gilt die Datenschutzerklärung des jeweiligen Autohauses.</span>
      <span className={'mt-8 font-semibold text-2xl'}>3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck ihrer Verwendung</span>
      <span>Beim Aufrufen unserer Website {hostname()} werden durch den auf Ihrem Endgerät eingesetzten Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</span>
      <span>• IP-Adresse des anfragenden Rechners,</span>
      <span>• Datum und Uhrzeit des Zugriffs,</span>
      <span>• Name und URL der abgerufenen Datei,</span>
      <span>• Website, von der aus der Zugriff erfolgt (Referrer-URL),</span>
      <span>• verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</span>
      <span>Diese Daten werden durch uns zu folgenden Zwecken verarbeitet:</span>
      <span>• Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</span>
      <span>• Gewährleistung einer komfortablen Nutzung unserer Website,</span>
      <span>• Auswertung der Systemsicherheit und -stabilität sowie</span>
      <span>• zu weiteren administrativen Zwecken.</span>
      <span>Dies sind berechtigte Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</span>
      <span>Darüber hinaus setzen wir beim Besuch unserer Website Cookies ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 5 und 6 dieser Datenschutzerklärung.</span>
      <span>Die von Ihnen zur Weitergabe an das ausgewählte Autohaus eingegebenen personenbezogenen Daten werden innerhalb von 90 Tagen gelöscht.</span>
      <span className={'mt-8 font-semibold text-2xl'}>4. Weitergabe von Daten</span>
      <span>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</span>
      <span>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</span>
      <span>• Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben;</span>
      <span>• die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben;</span>
      <span>• für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</span>
      <span>• dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</span>
      <span className={'mt-8 font-semibold text-2xl'}>5. Cookies</span>
      <span>Wir setzen auf unserer Seite Cookies ein. Diese kleinen Dateien werden von Ihrem Browser automatisch erstellt und auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, sie enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</span>
      <span>Eine Speicherung von Ihnen eingegebener Daten im lokalen Speicher Ihres Browsers wird möglicherweise nicht als Cookie angezeigt. Sie können Ihren Browser so einstellen, dass der Speicher (Cache) beim Beenden des Browsers automatisch geleert wird. Ebenso können Sie den Speicher des Browsers manuell löschen.</span>
      <span>Im Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem von Ihnen verwendeten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.</span>
      <span>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</span>
      <span>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</span>
      <span>Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Auswertung erfolgt durch unsere eigene Software, eine Weitergabe von personenbezogenen Daten an Dritte zu Zwecken der Auswertung findet daher nicht statt.</span>
      <span>Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.</span>
      <span>Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der berechtigten Interessen Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.</span>
      <span>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</span>

      <span className={'mt-8 font-semibold text-2xl'}>6. Betroffenenrechte</span>
      <span>Sie haben das Recht:</span>
      <span>• Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen (Art. 15 DSGVO). Hierzu zählen die Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</span>
      <span>• unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen (Art. 16 DSGVO);</span>
      <span>• die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist (Art. 17 DSGVO);</span>
      <span>• die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben (Art. 18 DSGVO);</span>
      <span>• Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen (Art. 20 DSGVO);</span>
      <span>• Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen (Art. 7 Abs. 3 DSGVO) und</span>
      <span>• sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden (Art. 77 DSGVO).</span>
      <span className={'mt-8 font-semibold text-2xl'}>7. Widerspruchsrecht</span>
      <span>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben, oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</span>
      <span>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an: frank.ritter@autohausen.de.</span>
      <span className={'mt-8 font-semibold text-2xl'}>8. Datensicherheit</span>
      <span>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</span>
      <span>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</span>

      <span className={'mt-8 font-semibold text-2xl'}>9. Widerspruchsrecht</span>
      <span>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO</span>
      <span>Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben, oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an: frank.ritter@autohausen.de.</span>

      <span className={'mt-8 font-semibold text-2xl'}>10. Datensicherheit</span>
      <span>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</span>
      <span>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</span>

      <span className={'mt-8 font-semibold text-2xl'}>11. Stand der Datenschutzerklärung</span>
      <span>Diese Datenschutzerklärung hat den Stand März 2023.</span>
    </div>
  </div>
)


export default Privacy
