import App from 'App'
import {createBrowserHistory} from 'history'
import _ from 'lodash'
import React from 'react'
import {Route, Router as BrowserRouter, Switch} from 'react-router-dom'
import Contact from 'views/Contact'
import EmailConfirmation from 'views/EmailConfirmation'
import Intro from 'views/Intro'
import ProcedureSelection from 'views/ProcedureSelection'
import SelectDealer from 'views/SelectDealer'
import Imprint from 'views/Service/Imprint'
import NotFound from 'views/Service/NotFound'
import Privacy from 'views/Service/Privacy'
import Valuation from 'views/Valuation'
import VehicleExterior from 'views/VehicleExterior'
import VehicleInterior from 'views/VehicleInterior'
import VehicleMakes from 'views/VehicleMakes'
import VehicleTransmission from 'views/VehicleTransmission'
import VehicleVin from 'views/VehicleVin'
import ThanksForRating from "../views/ThanksForRating"
import {Provider} from "react-redux"
import {store} from "../store"

export type LocationMatch = {
  path: string
  url: string
  isExact: boolean
  params: { [param: string]: string | undefined }
}

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_PUBLIC_URL,
})

history.listen(listener => {
  window.scrollTo(0, 0)
})

const RoutesPrefix = [
  '',
  '/:domain',
  '/:domain/:dealerId',
]

const routeComponents = _.flatten(RoutesPrefix.map((prefix, key) =>
  [
    <Route key={prefix + '1'} exact path={prefix + '/'} render={(props) =>
      <App {...props}><ProcedureSelection {...props}/></App>}/>,
    <Route key={prefix + '2'} exact path={prefix + '/procedure-selection'} render={(props) =>
      <App {...props}><ProcedureSelection {...props}/></App>}/>,
    <Route key={prefix + '3'} exact path={prefix + '/vehicle-vin'} render={(props) =>
      <App {...props}><VehicleVin {...props}/></App>}/>,
    <Route key={prefix + '4'} exact path={prefix + '/vehicle-makes'} render={(props) =>
      <App {...props}><VehicleMakes {...props}/></App>}/>,
    <Route key={prefix + '5'} exact path={prefix + '/vehicle-transmission'} render={(props) =>
      <App {...props}><VehicleTransmission {...props}/></App>}/>,
    <Route key={prefix + '6'} exact path={prefix + '/vehicle-exterior'} render={(props) =>
      <App {...props}><VehicleExterior {...props}/></App>}/>,
    <Route key={prefix + '7'} exact path={prefix + '/vehicle-interior'} render={(props) =>
      <App {...props}><VehicleInterior {...props}/></App>}/>,
    <Route key={prefix + '8'} exact path={prefix + '/contact'} render={(props) => <App {...props}><Contact {...props}/></App>}/>,
    <Route key={prefix + '9'} exact path={prefix + '/email-confirmation'} render={(props) =>
      <App {...props}><EmailConfirmation {...props}/></App>}/>,
    <Route key={prefix + '9'} exact path={prefix + '/valuation'} render={(props) =>
      <App {...props}><Valuation {...props}/></App>}/>,
    <Route key={prefix + '9'} exact path={prefix + '/valuation/:valuationHash'} render={(props) =>
      <App {...props}><Valuation {...props}/></App>}/>,
    <Route key={prefix + '10'} exact path={prefix + '/imprint'} render={(props) => <App {...props}><Imprint {...props}/></App>}/>,
    <Route key={prefix + '11'} exact path={prefix + '/privacy'} render={(props) => <App {...props}><Privacy {...props}/></App>}/>,
  ]
))

const Router = () => {
  return (
    <Provider store={store}>

      <BrowserRouter history={history} key={history.location.hash}>

        <Switch>

          <Route key={'1'} exact path={'/'} render={(props) => <App {...props}><Intro/></App>}/>,

          <Route key={'2'} exact path={'/select-dealer'} render={(props) =>
            <App {...props}><SelectDealer {...props}/></App>}/>,

          <Route key={'3'} exact path={'/procedure-selection'} render={(props) =>
            <App {...props}><ProcedureSelection {...props}/></App>}/>,

          <Route key={'4'} exact path={'/vehicle-vin'} render={(props) =>
            <App {...props}><VehicleVin {...props}/></App>}/>,

          <Route key={'5'} exact path={'/vehicle-makes'} render={(props) =>
            <App {...props}><VehicleMakes {...props}/></App>}/>,

          <Route key={'6'} exact path={'/vehicle-transmission'} render={(props) =>
            <App {...props}><VehicleTransmission {...props}/></App>}/>,

          <Route key={'7'} exact path={'/vehicle-exterior'} render={(props) =>
            <App {...props}><VehicleExterior {...props}/></App>}/>,

          <Route key={'8'} exact path={'/vehicle-interior'} render={(props) =>
            <App {...props}><VehicleInterior {...props}/></App>}/>,

          <Route key={'9'} exact path={'/contact'} render={(props) => <App {...props}><Contact {...props}/></App>}/>,

          <Route key={'10'} exact path={'/email-confirmation'} render={(props) =>
            <App {...props}><EmailConfirmation {...props}/></App>}/>,

          <Route key={'11'} exact path={'/valuation'} render={(props) =>
            <App {...props}><Valuation {...props}/></App>}/>, <Route key={'10'} exact path={'/valuation/:valuationHash'} render={(props) =>
          <App {...props}><Valuation {...props}/></App>}/>,

          <Route key={'12'} exact path={'/imprint'} render={(props) => <App {...props}><Imprint {...props}/></App>}/>,

          <Route key={'13'} exact path={'/privacy'} render={(props) => <App {...props}><Privacy {...props}/></App>}/>,

          <Route key={'14'} exact path={'/docs/api'} render={() => window.open('https://ars-mia.de/apps/pricetool-v2/docs/', '_self')}/>,

          <Route key={'14'} exact path={'/docs/api/en'} render={() => window.open('https://ars-mia.de/apps/pricetool-v2/docs/', '_self')}/>,

          <Route key={'15'} exact path={'/rating/:rating'} render={(props) => <App {...props}><ThanksForRating {...props}/></App>}/>,

          {routeComponents}

          <Route render={(props) => <NotFound {...props}/>}/>

        </Switch>

      </BrowserRouter>

    </Provider>
  )
}

export default Router
