import axios from 'axios'
import Header from 'components/Header'
import {LocationMatch} from 'components/Router'
import sha256 from 'crypto-js/sha256'
import {Dealer} from 'domain-models'
import _ from 'lodash'
import React from 'react'
import {getStoredAppString, getStoredLeadString, getStoredVehicleBoolean, getStoredVehicleNumber, getStoredVehicleObject, getStoredVehicleString, getVehicleInterest, storeAppItem} from 'redux/actions'
import swal from 'sweetalert2'
import PrimaryButton from "../components/PrimaryButton"
import {faSpinner} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'

type EmailConfirmationProps = {
  match: LocationMatch
}
type EmailConfirmationState = {
  hash: string | null
  requestParams: {} | null
  isLoading: boolean
}

class EmailConfirmation extends React.Component<EmailConfirmationProps, EmailConfirmationState> {
  constructor(props: EmailConfirmationProps) {
    super(props)

    this.state = {
      hash: null,
      requestParams: null,
      isLoading: false
    }
  }

  componentDidMount = async () => {
    const procedure = await getStoredAppString('procedure')
    const vin = await getStoredVehicleString('vin')

    if (procedure === 'vehicle-vin' && vin) {
      await this.getHashFromVin(vin)
    } else {
      await this.getHashFromData()
    }

    this.sendConfirmationEmail()
  }

  sendConfirmationEmail = async (sendAgain = false) => {
    const model = await getStoredVehicleNumber('model')
    const make = await _.get(await getStoredVehicleObject('make'), 'value', null)

    const params = {
      valuationHash: this.state.hash,
      params: this.state.requestParams,
      vehicleInterest: getVehicleInterest(),
      email: await getStoredLeadString('email'),
      make: make && make.value ? make.value : (make || null),
      model: model,
    }

    const paramsHash = await this.hashCode(JSON.stringify(params)).toString()

    if (!sendAgain && paramsHash === getStoredAppString('emailConfirmationHash')) {
      return
    }

    await this.setState({isLoading: true})
    const emailSentConfirmation = _.debounce(this.emailSentConfirmation, 5000)

    storeAppItem('emailConfirmationHash', paramsHash)
    axios.post('/api/email/send-confirmation-email', params).then((res) => {
      if (res.status === 418) {
        return
      }

      emailSentConfirmation()
    }).catch(res => {
      if (!res.includes('status code 418')) {
        emailSentConfirmation()
      }
    })
  }

  emailSentConfirmation = () => {
    this.setState({isLoading: false})
    swal.fire({
      title: 'E-Mail versendet',
      text: 'Wir haben Ihnen erneut eine E-Mail zugesandt. Es kann ein paar Minuten dauern, bis diese in Ihren Posteingang gelangt.',
      icon: 'success',
    })
  }

  getHashFromVin = async (vin: string) => {
    const dealer = await getStoredVehicleObject('dealer') as Dealer
    const DealerId = dealer ? dealer.DealerId : _.get(dealer, 'Id', undefined)

    const prevOwners = await getStoredVehicleNumber('prevOwners')

    let gender = null
    if (getStoredLeadString('salutation') === 'mr') {
      gender = 'male'
    }
    if (getStoredLeadString('salutation') === 'mrs') {
      gender = 'female'
    }

    let upSell = null
    if (getStoredLeadString('intention') === 'new-vehicle-purchase') {
      upSell = 1
    }
    if (getStoredLeadString('intention') === 'used-vehicle-purchase') {
      upSell = 2
    }

    const params = {
      referrer: getStoredAppString('referrer', document.referrer),
      DealerId,
      vehicleInterest: getVehicleInterest(),
      Vatable: getStoredVehicleBoolean('vatable', false),
      params: {
        vin,
        mileage: getStoredVehicleNumber('kilometers'),
        registrationDate: getStoredVehicleString('registrationDate'),
        prevOwners: (prevOwners === 0 ? 1 : (prevOwners || 1)),
        Visible: true,
      },
      lead: {
        FirstName: getStoredLeadString('firstName'),
        LastName: getStoredLeadString('lastName'),
        Email: getStoredLeadString('email'),
        Phone: getStoredLeadString('phone'),
        Gender: gender,
        UpSell: upSell,
        Notice: getStoredLeadString('dealerNotice')
      },
    }

    await this.setState({hash: this.hashCode(JSON.stringify(params)).toString(), requestParams: params})
  }

  getHashFromData = async () => {
    const dealer = await getStoredVehicleObject('dealer') as Dealer
    const DealerId = dealer ? dealer.DealerId : _.get(dealer, 'Id', undefined)

    const prevOwners = getStoredVehicleNumber('prevOwners', 1)
    const numSeats = getStoredVehicleNumber('numSeats', 0) || null
    const Make = _.get(getStoredVehicleObject('make'), 'value', null)

    let gender = null
    if (getStoredLeadString('salutation') === 'mr') {
      gender = 'male'
    }
    if (getStoredLeadString('salutation') === 'mrs') {
      gender = 'female'
    }

    let upSell = null
    if (getStoredLeadString('intention') === 'new-vehicle-purchase') {
      upSell = 1
    }
    if (getStoredLeadString('intention') === 'used-vehicle-purchase') {
      upSell = 2
    }

    const params = {
      referrer: document.referrer,
      DealerId,
      Vatable: getStoredVehicleBoolean('vatable', false),
      lead: {
        FirstName: getStoredLeadString('firstName'),
        LastName: getStoredLeadString('lastName'),
        Email: getStoredLeadString('email'),
        Phone: getStoredLeadString('phone'),
        Gender: gender,
        UpSell: upSell,
        Notice: getStoredLeadString('dealerNotice')
      },
      params: {
        Make: Make.value || Make || null,
        Model: getStoredVehicleNumber('model') || null,
        Kilometers: getStoredVehicleNumber('kilometers'),
        Drive: getStoredVehicleBoolean('fourWheelDrive') ? 3 : null,
        Transmission: getStoredVehicleNumber('transmission'),
        SeatCover: getStoredVehicleNumber('interior'),
        Fuel: getStoredVehicleNumber('fuel'),
        Power: getStoredVehicleNumber('power'),
        RegistrationDate: getStoredVehicleString('registrationDate'),
        NumOwners: prevOwners,
        NumSeats: numSeats,
        Body: getStoredVehicleNumber('body') || null,
        Color: getStoredVehicleNumber('color') || null,
        EquipmentFlags0: getStoredVehicleNumber('EquipmentFlags0'),
        EquipmentFlags1: getStoredVehicleNumber('EquipmentFlags1'),
        EquipmentFlags2: getStoredVehicleNumber('EquipmentFlags2'),
        EquipmentFlags3: getStoredVehicleNumber('EquipmentFlags3'),
        EquipmentFlags4: getStoredVehicleNumber('EquipmentFlags4'),
        EquipmentFlags5: getStoredVehicleNumber('EquipmentFlags5'),
        EquipmentFlags6: getStoredVehicleNumber('EquipmentFlags6'),
      }
    }

    await this.setState({hash: this.hashCode(JSON.stringify(params)).toString(), requestParams: params})
  }

  hashCode = (string: string) => {
    return sha256(string)
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={3} match={this.props.match}/>

        <div className={'mt-4 md:mt-6 lg:mt-24 mx-auto flex flex-col w-1/2'}>
          <h3 className="text-xl lg:text-3xl font-bold">Bitte bestätigen Sie Ihre E-Mail Adresse um die Preisbewertung zu erhalten</h3>

          <span className={'mt-4'}>
            Damit Sie Ihren Preis sehen können, müssen Sie zunächst Ihre E-Mail Adresse bestätigen.
            Bitte folgen Sie den Anweisungen in der E-Mail, welche wir Ihnen an <strong>{getStoredLeadString('email')}</strong> zugesandt haben, damit wir Ihnen den Wert Ihres Fahrzeuges anzeigen können. Bitte überprüfen Sie auch ihren Spam-Ordner.
          </span>

          <span className={'mt-8'}>
            {
              this.state.isLoading ?
                <p className={'mb-3'}>
                  <Icon icon={faSpinner} spin={true}/> E-Mail wird gesendet. </p>
                : null
            }
            <PrimaryButton event={'resend-email-confirmation'} margin={'m-0'} title={'E-Mail erneut senden'} active={!this.state.isLoading} onClick={() => {
              this.sendConfirmationEmail(true)
            }} buttonId={'resend-email-confirmation-button'}/>
          </span>
        </div>
      </div>
    )
  }
}

export default EmailConfirmation
