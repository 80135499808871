import React, {ReactNode, ReactNodeArray} from 'react'

type Props = {
  children: ReactNodeArray
}

const TimeLineContext = React.createContext({})


const Timeline = ({children}: Props) => {
  return (
    <TimeLineContext.Provider value={{}}>
      <div className="mt-6 flow-root">
        <ul className="-mb-8">
          {children.map(child => child)}
        </ul>
      </div>
    </TimeLineContext.Provider>
  )
}

type EntryProps = {
  children: ReactNode,
  Icon: ReactNode
}

Timeline.Entry = ({children, Icon}: EntryProps) => {
  return (
    <li>
      <div className="relative pb-8">
        <span className="timeline-divider absolute top-4 left-6 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"/>
        <div className="relative flex space-x-20">
          <div>
            <span className="h-12 w-12 absolute rounded-full bg-white flex items-center justify-center ring-8 ring-white">
              {Icon}
            </span>
          </div>
          {children}
        </div>
      </div>
    </li>
  )
}

export default Timeline