import {track} from 'hooks'
import React from 'react'
import {NavLink} from 'react-router-dom'
import {classNames} from "../utils/class-names"

type ButtonLinkProps = {
  event: string
  to: string
  title: string
  active: boolean
  margin?: string
  buttonId: string
  tagManagerData?: {}
}

const PrimaryButtonLink = (props: ButtonLinkProps) => (
  <NavLink id={props.buttonId} onClick={(e) => {
    if (!props.active) {
      return e.preventDefault()
    }
    if (props.event) {
      track(props.event)
    }
  }} to={props.to} className={classNames(
    'rounded-skin-button border border-skin-button-secondary-border hover:border-skin-button-secondary-border-muted text-center p-skin-button shadow',
    props.margin ? props.margin : 'ml-0 md:ml-4',
    props.active ? 'text-skin-button-text bg-skin-button-fill hover:bg-skin-button-muted' : 'text-skin-button-text bg-skin-button-fill opacity-50 cursor-not-allowed'
  )}>{props.title}</NavLink>
)

export default PrimaryButtonLink