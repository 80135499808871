import {track} from 'hooks'
import React from 'react'
import {classNames} from 'utils/class-names'

type ButtonProps = {
  event: string
  title: string
  active: boolean
  margin?: string
  onClick: CallableFunction
  buttonId: string
}

export const PrimaryButtonStyling = 'inline-flex items-center border border-transparent justify-center p-skin-button rounded-skin-button border border-skin-button-secondary-border hover:border-skin-button-secondary-border-muted shadow-sm font-medium text-skin-button-text'
export const PrimaryButtonStylingActive = 'text-skin-button-text bg-skin-button-fill hover:bg-skin-button-muted focus:outline-none'

const PrimaryButton = (props: ButtonProps) => (
  <button id={props.buttonId} onClick={(e) => {
    if (!props.active) {
      return e.preventDefault()
    }
    if (props.event) {
      track(props.event)
    }
    props.onClick && props.onClick()
  }} disabled={!props.active} className={classNames(PrimaryButtonStyling,
    (props.margin ? props.margin : 'ml-0 md:ml-4'),
    (props.active ? PrimaryButtonStylingActive : 'text-skin-button-text bg-skin-button-fill opacity-50 cursor-not-allowed')
  )}>{props.title}</button>
)

export default PrimaryButton