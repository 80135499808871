import React from "react"
import {history, LocationMatch} from "../components/Router"
import PrimaryButton from "../components/PrimaryButton"
import {hostname, isVolkswagenSite} from "../hooks"


type Props = {
  match: LocationMatch
}


const ThanksForRating = ({match}: Props) => {
  return (
    <div>
      <div className="px-6 lg:px-16">
        <div className="flex flex-col lg:flex-row lg:justify-between mt-4 lg:mt-2">
          {
            isVolkswagenSite() ?
              <span className={'font-bold text-xl'}>{hostname().includes('gib-uns-deinen-gebrauchten.de') ? 'gib-uns-deinen-gebrauchten.de' : 'was-ist-mein-gebrauchter-wert.de'}</span>
              :
              null
          }
        </div>
        <div className="pt-12">
          <div className="flex flex-col">
            <h3 className="text-2xl lg:text-3xl font-bold">Danke für Ihr Feedback</h3>

            <div className="pt-8 flex flex-col lg:flex-row">
              <p>Ihre Stimme wurde gewertet.</p>
            </div>

            <div className={'mt-6'}>
              <PrimaryButton event={'back-to-landing-page'} margin={'m-0'} title={'Zurück zur Startseite'} active={true} onClick={() => history.push('/')} buttonId={'back-to-landing-page'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThanksForRating
