import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import {history, LocationMatch} from 'components/Router'
import {createUrl, track} from 'hooks'
import React from 'react'

type NotFoundProps = {
  match: LocationMatch
}

const NotFound = (props: NotFoundProps) => (
  <div className="flex flex-col justify-center items-center w-full h-full overflow-hidden">
    <div className="">
      <button id={'button-back'} className={'bg-blue-800 hover:bg-blue-700 text-blue-100 rounded text-center px-4 py-2 my-4 font-semibold text-2xl'} onClick={() => {
        track('button-back-to-homepage-clicked')
        history.push(createUrl('', props.match))
      }}>
        <Icon icon={faArrowLeft} className={'mr-2'}/> Zurück zur Startseite
      </button>

    </div>
    <img src={'/images/undraw_page_not_found.png'} alt="page not found"/>
  </div>
)

export default NotFound
