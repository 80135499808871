import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import React from 'react'

type Option = {
  label: string
  value: string | number
}

type SelectInputProps = {
  value: string
  label: string
  options: Array<Option>
  onChange: CallableFunction
  title?: string
  classes?: string
  required?: boolean
  error?: boolean
}

const SelectInput: React.FC<SelectInputProps> = ({value, label, options, onChange, title, classes, required, error}) => {

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value)
  }

  const labelId = label + '-select-input'

  return (
    <FormControl style={{minWidth: '120px'}} className={'m-1 ' + classes}>
      <InputLabel required={required || false} error={error || false} htmlFor={labelId}>{label}</InputLabel>
      <NativeSelect error={error || false} className={''} value={value} onChange={handleChange} inputProps={{
        name: label,
        id: labelId,
      }}>
        <option value=""/>

        {options.map((option: Option) => {
          return (<option key={option.value} value={option.value}>{option.label}</option>)
        })}
      </NativeSelect> <FormHelperText>{title}</FormHelperText> </FormControl>
  )
}

export default SelectInput