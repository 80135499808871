import {createStore, Store} from 'redux'
import {GlobalState} from 'state'
import rootReducers from './redux/reducers'
// import thunk from 'redux-thunk'

// const devTools = process.env.REACT_APP_RELEASE_STAGE === 'local'
// const composeEnhancers = devTools ? (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

export const store: Store<GlobalState> = createStore(
  rootReducers,
  // composeEnhancers(
  //   applyMiddleware(thunk)
  // )
)
