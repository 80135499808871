import React from "react"
import {isAudiSite} from "../hooks"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome"

export default function InfoIcon() {
  return <>
    {isAudiSite() ?
      <svg className={'mt-1'} width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5,17 L11.5,10 M11.5,8 L11.5,6 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
      </svg>
      : <Icon className={'text-skin-button-fill'} icon={faInfoCircle}/>}
  </>
}