import React, {ReactNode, ReactNodeArray} from 'react'

type Props = {
  children: ReactNode | ReactNodeArray
}

const ListContext = React.createContext({})

export function List({children}: Props) {
  return (
    <ListContext.Provider value={{}}>
      <ul>
        {children}
      </ul>
    </ListContext.Provider>
  )
}

type ItemProps = {
  children?: ReactNode
}

List.Item = ({children}: ItemProps) => {
  return (
    <li>
      {children}
    </li>
  )
}

export default List