import {Codes, Dealer, Model} from 'domain-models'
import {AppConfig, Themes} from "../../App"

export type AppState = AppStrings & AppObjects & AppNumbers & AppBooleans

export type AppStrings = {
  emailConfirmationHash?: string
  procedure?: 'manuel' | 'vehicle-vin'
  requestHash?: string
  trackingId?: string
  recaptchaToken?: string
  referrer?: string
}

export type AppObjects = {
  codes?: Codes
  models?: Array<Model>
  response?: object
  defaultDealers?: Array<Dealer>
  trackingHashes?: Array<string>
  eventPayload?: Array<{ event: string, data: any, type: 'track' }>
  config?: AppConfig
}

export type AppNumbers = {
  rating?: number
  theme?: Themes
}

export type AppBooleans = {
  cookiesAccepted?: boolean
  ratingDisabled?: boolean
  vinValid?: boolean
  marketing?: boolean
  preferences?: boolean
  statistic?: boolean
  gaLoaded?: boolean
}

type AppAction = {
  type: string
  app: AppState
}

const initialState = {}

const appReducer = (state: AppState = initialState, action: AppAction) => {
  switch (action.type) {
    case 'UPDATE_APP':
      return {
        ...state,
        ...action.app
      }
    default:
      return state
  }
}

export default appReducer
