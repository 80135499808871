import DateFnsUtils from '@date-io/moment'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {track} from 'hooks'
import moment from 'moment'
import 'moment/locale/de'
import React from 'react'
import {getStoredVehicleString, storeVehicleItem} from 'redux/actions'

moment.locale('de')

type RegistrationDatePickerProps = {
  registrationDate: string | null
  onChange: CallableFunction
}

type RegistrationDatePickerState = {
  registrationDate: string | null
}

class RegistrationDatePicker extends React.Component<RegistrationDatePickerProps, RegistrationDatePickerState> {
  constructor(props: RegistrationDatePickerProps) {
    super(props)

    this.state = {
      registrationDate: this.props.registrationDate,
    }
  }

  dateChange = (date: MaterialUiPickersDate) => {
    getStoredVehicleString('registrationDate')
    this.props.onChange()

    if (!date || !date.isValid()) {
      return
    }
    storeVehicleItem('registrationDate', date.format('YYYY-MM-DD'))

    this.setState({
      registrationDate: date.format('YYYY-MM-DD'),
    })

    this.props.onChange()
  }

  render() {
    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={'de'}>

        <KeyboardDatePicker placeholder={'TT.MM.JJJJ'} openTo={'year'} disableFuture={true} cancelLabel="Abbrechen" required={true} format="DD.MM.YYYY" id="date-picker-inline" label="Erstzulassung" className={'w-full'} value={this.state.registrationDate} onChange={this.dateChange} onBlur={() => track('registration-date-entered', this.state.registrationDate)} KeyboardButtonProps={{
          'aria-label': 'change date',
        }}/>

      </MuiPickersUtilsProvider>
    )
  }
}

export default RegistrationDatePicker