import {Make} from 'domain-models'

export const makesString = '[\n' +
  '  {"label": "Abarth", "value": 1566},\n' +
  '  {"label": "ABG", "value": 1001},\n' +
  '  {"label": "ABI", "value": 1002},\n' +
  '  {"label": "Ackermann", "value": 1004},\n' +
  '  {"label": "Acura", "value": 900},\n' +
  '  {"label": "Adly", "value": 1005},\n' +
  '  {"label": "Adria", "value": 1006},\n' +
  '  {"label": "Aeon", "value": 1007},\n' +
  '  {"label": "Agados", "value": 1008},\n' +
  '  {"label": "Agco / Massey Ferguson", "value": 1009},\n' +
  '  {"label": "Ahlmann", "value": 1010},\n' +
  '  {"label": "Ahorn", "value": 1011},\n' +
  '  {"label": "Airstream", "value": 1012},\n' +
  '  {"label": "Aixam", "value": 1013},\n' +
  '  {"label": "Alfa Romeo", "value": 10},\n' +
  '  {"label": "Algema", "value": 1014},\n' +
  '  {"label": "Alpha", "value": 1015},\n' +
  '  {"label": "Alpina", "value": 901},\n' +
  '  {"label": "Amazone", "value": 1016},\n' +
  '  {"label": "Ammann", "value": 1017},\n' +
  '  {"label": "andere", "value": 9999},\n' +
  '  {"label": "Annaburger", "value": 1018},\n' +
  '  {"label": "Anssems", "value": 1019},\n' +
  '  {"label": "Aprilia", "value": 1020},\n' +
  '  {"label": "Arca", "value": 1021},\n' +
  '  {"label": "Arctic Cat", "value": 1022},\n' +
  '  {"label": "Artison", "value": 1023},\n' +
  '  {"label": "Asia Motors", "value": 102},\n' +
  '  {"label": "Aston Martin", "value": 902},\n' +
  '  {"label": "Atec", "value": 1024},\n' +
  '  {"label": "Atlas", "value": 1025},\n' +
  '  {"label": "Atlas Copco", "value": 1026},\n' +
  '  {"label": "Audi", "value": 11},\n' +
  '  {"label": "AUSA", "value": 1027},\n' +
  '  {"label": "Austin", "value": 53},\n' +
  '  {"label": "Austin Rover", "value": 903},\n' +
  '  {"label": "Autostar", "value": 1028},\n' +
  '  {"label": "Auwärter", "value": 1029},\n' +
  '  {"label": "Avant Tecno", "value": 1030},\n' +
  '  {"label": "Avento", "value": 1031},\n' +
  '  {"label": "Baotian", "value": 1032},\n' +
  '  {"label": "Barkas", "value": 1033},\n' +
  '  {"label": "Barossa", "value": 1034},\n' +
  '  {"label": "Barthau", "value": 1035},\n' +
  '  {"label": "Bashan", "value": 1036},\n' +
  '  {"label": "Baumann", "value": 1037},\n' +
  '  {"label": "Bavaria-Camp", "value": 1038},\n' +
  '  {"label": "Bawemo", "value": 1039},\n' +
  '  {"label": "Becker", "value": 1040},\n' +
  '  {"label": "Beeline", "value": 1041},\n' +
  '  {"label": "Bela", "value": 1042},\n' +
  '  {"label": "Benalu", "value": 1043},\n' +
  '  {"label": "Benelli", "value": 1044},\n' +
  '  {"label": "Benimar", "value": 1045},\n' +
  '  {"label": "Bentley", "value": 904},\n' +
  '  {"label": "Berger", "value": 1046},\n' +
  '  {"label": "Bergmann", "value": 1047},\n' +
  '  {"label": "Beyerland", "value": 1049},\n' +
  '  {"label": "Big Dog Motorcycles", "value": 1050},\n' +
  '  {"label": "Bimobil", "value": 1051},\n' +
  '  {"label": "Bimota", "value": 1052},\n' +
  '  {"label": "Blata", "value": 1053},\n' +
  '  {"label": "Blomenröhr", "value": 1054},\n' +
  '  {"label": "Blomert", "value": 1055},\n' +
  '  {"label": "Blyss", "value": 1056},\n' +
  '  {"label": "BMW", "value": 13},\n' +
  '  {"label": "BNG", "value": 1057},\n' +
  '  {"label": "Bobcat", "value": 1058},\n' +
  '  {"label": "Böckmann", "value": 1078},\n' +
  '  {"label": "BOMAG", "value": 1059},\n' +
  '  {"label": "Bombardier", "value": 1060},\n' +
  '  {"label": "Boom", "value": 1061},\n' +
  '  {"label": "Borco-Höhns", "value": 1062},\n' +
  '  {"label": "Brandl", "value": 1063},\n' +
  '  {"label": "Branson", "value": 1064},\n' +
  '  {"label": "Bravia", "value": 1065},\n' +
  '  {"label": "Brenderup", "value": 1066},\n' +
  '  {"label": "Brian James", "value": 1067},\n' +
  '  {"label": "Broshuis", "value": 1068},\n' +
  '  {"label": "BRP", "value": 1069},\n' +
  '  {"label": "BSA", "value": 1070},\n' +
  '  {"label": "BT", "value": 1071},\n' +
  '  {"label": "Buell", "value": 1072},\n' +
  '  {"label": "Bugatti", "value": 905},\n' +
  '  {"label": "Buick", "value": 23},\n' +
  '  {"label": "Bunge", "value": 1073},\n' +
  '  {"label": "Burelli", "value": 1074},\n' +
  '  {"label": "Burow", "value": 1075},\n' +
  '  {"label": "Bürstner", "value": 1079},\n' +
  '  {"label": "Busatis", "value": 1076},\n' +
  '  {"label": "BvL - Van Lengerich", "value": 1077},\n' +
  '  {"label": "Cadillac", "value": 24},\n' +
  '  {"label": "Cagiva", "value": 1080},\n' +
  '  {"label": "Can Am", "value": 1081},\n' +
  '  {"label": "Carado", "value": 1082},\n' +
  '  {"label": "Caravelair", "value": 1083},\n' +
  '  {"label": "Carnehl", "value": 1084},\n' +
  '  {"label": "Caro", "value": 1085},\n' +
  '  {"label": "Carraro", "value": 1086},\n' +
  '  {"label": "Carthago", "value": 1087},\n' +
  '  {"label": "Case", "value": 1088},\n' +
  '  {"label": "CAT", "value": 1089},\n' +
  '  {"label": "Caterham", "value": 1570},\n' +
  '  {"label": "Cectek", "value": 1090},\n' +
  '  {"label": "Cesab", "value": 1091},\n' +
  '  {"label": "CFMOTO", "value": 1092},\n' +
  '  {"label": "Challenger", "value": 1571},\n' +
  '  {"label": "Chateau", "value": 1094},\n' +
  '  {"label": "Chausson", "value": 1095},\n' +
  '  {"label": "Chereau", "value": 1096},\n' +
  '  {"label": "Cheval Liberte", "value": 1097},\n' +
  '  {"label": "Chevrolet", "value": 25},\n' +
  '  {"label": "Chrysler", "value": 1098},\n' +
  '  {"label": "CI International", "value": 1099},\n' +
  '  {"label": "Citroën", "value": 14},\n' +
  '  {"label": "Claas", "value": 1101},\n' +
  '  {"label": "Clark", "value": 1102},\n' +
  '  {"label": "Clever", "value": 1103},\n' +
  '  {"label": "Coachmen", "value": 1104},\n' +
  '  {"label": "Combilift", "value": 1105},\n' +
  '  {"label": "Concorde", "value": 1106},\n' +
  '  {"label": "Corvette", "value": 96},\n' +
  '  {"label": "CPI", "value": 1107},\n' +
  '  {"label": "Cristall", "value": 1108},\n' +
  '  {"label": "CS Reisemobile", "value": 1110},\n' +
  '  {"label": "Cupra", "value": 10000},\n' +
  '  {"label": "Dacia", "value": 114},\n' +
  '  {"label": "Daelim", "value": 1111},\n' +
  '  {"label": "Daewoo", "value": 106},\n' +
  '  {"label": "DAF", "value": 1112},\n' +
  '  {"label": "Daihatsu", "value": 16},\n' +
  '  {"label": "Daltec", "value": 1113},\n' +
  '  {"label": "Dehler", "value": 1114},\n' +
  '  {"label": "Demag", "value": 1116},\n' +
  '  {"label": "Derbi", "value": 1117},\n' +
  '  {"label": "Dethleffs", "value": 1118},\n' +
  '  {"label": "Detomaso", "value": 907},\n' +
  '  {"label": "Deutz-Fahr", "value": 1119},\n' +
  '  {"label": "Dinkel", "value": 1120},\n' +
  '  {"label": "Dinli", "value": 1121},\n' +
  '  {"label": "Ditch Witch", "value": 1122},\n' +
  '  {"label": "DKW", "value": 1123},\n' +
  '  {"label": "Dodge", "value": 908},\n' +
  '  {"label": "Doll", "value": 1124},\n' +
  '  {"label": "DOMO", "value": 1125},\n' +
  '  {"label": "Doosan", "value": 1126},\n' +
  '  {"label": "Dopfer", "value": 1127},\n' +
  '  {"label": "Dresser", "value": 1128},\n' +
  '  {"label": "Drögmöller", "value": 1129},\n' +
  '  {"label": "DS Automobiles", "value": 1569},\n' +
  '  {"label": "Ducati", "value": 1130},\n' +
  '  {"label": "Dücker", "value": 1133},\n' +
  '  {"label": "Due Erre", "value": 1131},\n' +
  '  {"label": "Düvelsdorf", "value": 1134},\n' +
  '  {"label": "Dynapac", "value": 1132},\n' +
  '  {"label": "e-max", "value": 1135},\n' +
  '  {"label": "E-Ton", "value": 1136},\n' +
  '  {"label": "Eberhardt", "value": 1137},\n' +
  '  {"label": "Eduard", "value": 1138},\n' +
  '  {"label": "Eicher", "value": 1139},\n' +
  '  {"label": "Eifelland", "value": 1140},\n' +
  '  {"label": "Elnagh", "value": 1141},\n' +
  '  {"label": "emco", "value": 1142},\n' +
  '  {"label": "ES-GE", "value": 1144},\n' +
  '  {"label": "Esterel", "value": 1145},\n' +
  '  {"label": "Eura Mobil", "value": 1146},\n' +
  '  {"label": "Euro Liner", "value": 1147},\n' +
  '  {"label": "EVM", "value": 1148},\n' +
  '  {"label": "Evobus", "value": 1149},\n' +
  '  {"label": "Excalibur", "value": 1150},\n' +
  '  {"label": "eXtreme", "value": 1152},\n' +
  '  {"label": "Fahr", "value": 1153},\n' +
  '  {"label": "Faun", "value": 1154},\n' +
  '  {"label": "Faymonville", "value": 1155},\n' +
  '  {"label": "Feber", "value": 1156},\n' +
  '  {"label": "Feldbinder", "value": 1157},\n' +
  '  {"label": "Fella", "value": 1158},\n' +
  '  {"label": "Fendt", "value": 1159},\n' +
  '  {"label": "Fermec", "value": 1160},\n' +
  '  {"label": "Ferrari", "value": 909},\n' +
  '  {"label": "FFB - Tabbert", "value": 1161},\n' +
  '  {"label": "Fiat", "value": 17},\n' +
  '  {"label": "Fischer", "value": 1162},\n' +
  '  {"label": "Fitzel", "value": 1163},\n' +
  '  {"label": "Fliegl", "value": 1165},\n' +
  '  {"label": "Floor", "value": 1166},\n' +
  '  {"label": "Ford", "value": 21},\n' +
  '  {"label": "Fortschritt", "value": 1167},\n' +
  '  {"label": "Foton", "value": 1168},\n' +
  '  {"label": "Four-Wheel Camper", "value": 1169},\n' +
  '  {"label": "FR-Mobil", "value": 1170},\n' +
  '  {"label": "Frankenstein", "value": 1171},\n' +
  '  {"label": "Frankia", "value": 1172},\n' +
  '  {"label": "Freightliner", "value": 1173},\n' +
  '  {"label": "Frost", "value": 1174},\n' +
  '  {"label": "Frühauf", "value": 1175},\n' +
  '  {"label": "Fuchs", "value": 1176},\n' +
  '  {"label": "Furukawa", "value": 1177},\n' +
  '  {"label": "FUSO", "value": 1178},\n' +
  '  {"label": "GAC Gonow", "value": 1179},\n' +
  '  {"label": "Gasgas", "value": 1180},\n' +
  '  {"label": "Gaspardo", "value": 1181},\n' +
  '  {"label": "Gehl", "value": 1182},\n' +
  '  {"label": "Gehlmax", "value": 1183},\n' +
  '  {"label": "General Motors", "value": 1184},\n' +
  '  {"label": "General Trailer", "value": 1185},\n' +
  '  {"label": "Generic", "value": 1186},\n' +
  '  {"label": "Genie", "value": 1187},\n' +
  '  {"label": "Gergen-Jung", "value": 1188},\n' +
  '  {"label": "GG Motorradtechnik", "value": 1189},\n' +
  '  {"label": "Gilera", "value": 1190},\n' +
  '  {"label": "Ginaf", "value": 1191},\n' +
  '  {"label": "GiottiLine", "value": 1192},\n' +
  '  {"label": "Globecar", "value": 1193},\n' +
  '  {"label": "Glücksmobil", "value": 1194},\n' +
  '  {"label": "GMC", "value": 910},\n' +
  '  {"label": "GOES", "value": 1195},\n' +
  '  {"label": "Goldhofer", "value": 1196},\n' +
  '  {"label": "Gorilla", "value": 1197},\n' +
  '  {"label": "Granduca", "value": 1198},\n' +
  '  {"label": "Grimme", "value": 1199},\n' +
  '  {"label": "Groenewegen", "value": 1200},\n' +
  '  {"label": "Grove", "value": 1201},\n' +
  '  {"label": "Güldner", "value": 1203},\n' +
  '  {"label": "Gutbrod", "value": 1202},\n' +
  '  {"label": "Hako", "value": 1204},\n' +
  '  {"label": "Halla", "value": 1205},\n' +
  '  {"label": "Hamm", "value": 1206},\n' +
  '  {"label": "Hanix", "value": 1207},\n' +
  '  {"label": "Hanomag", "value": 1208},\n' +
  '  {"label": "Hapert", "value": 1209},\n' +
  '  {"label": "Harley-Davidson", "value": 1210},\n' +
  '  {"label": "Hassia", "value": 1211},\n' +
  '  {"label": "Haulotte", "value": 1212},\n' +
  '  {"label": "HDPIC", "value": 93},\n' +
  '  {"label": "Hehn", "value": 1213},\n' +
  '  {"label": "Heinemann", "value": 1214},\n' +
  '  {"label": "Heku", "value": 1215},\n' +
  '  {"label": "Hendricks", "value": 1216},\n' +
  '  {"label": "Henra", "value": 1217},\n' +
  '  {"label": "Hercules", "value": 1218},\n' +
  '  {"label": "Herkules", "value": 1219},\n' +
  '  {"label": "Hinowa", "value": 1220},\n' +
  '  {"label": "Hitachi", "value": 1221},\n' +
  '  {"label": "HKM", "value": 1222},\n' +
  '  {"label": "HN Schörling", "value": 1223},\n' +
  '  {"label": "Hobby", "value": 1224},\n' +
  '  {"label": "Hoffmann", "value": 1225},\n' +
  '  {"label": "Holder", "value": 1226},\n' +
  '  {"label": "Holiday Rambler", "value": 1227},\n' +
  '  {"label": "Homar", "value": 1228},\n' +
  '  {"label": "Home-Car", "value": 1229},\n' +
  '  {"label": "Honda", "value": 28},\n' +
  '  {"label": "Horex", "value": 1230},\n' +
  '  {"label": "Howard", "value": 1231},\n' +
  '  {"label": "HRD", "value": 1232},\n' +
  '  {"label": "HRZ", "value": 1233},\n' +
  '  {"label": "Hüffermann", "value": 1243},\n' +
  '  {"label": "Hulco", "value": 1234},\n' +
  '  {"label": "Humbaur", "value": 1235},\n' +
  '  {"label": "Hummer", "value": 911},\n' +
  '  {"label": "Husaberg", "value": 1236},\n' +
  '  {"label": "Husqvarna", "value": 1237},\n' +
  '  {"label": "Huttner", "value": 1238},\n' +
  '  {"label": "Hydrema", "value": 1239},\n' +
  '  {"label": "Hymer-Eriba", "value": 1240},\n' +
  '  {"label": "HYMERCAR", "value": 1567},\n' +
  '  {"label": "Hyosung", "value": 1241},\n' +
  '  {"label": "Hyster", "value": 1242},\n' +
  '  {"label": "Hyundai", "value": 34},\n' +
  '  {"label": "Ifor Williams", "value": 1244},\n' +
  '  {"label": "IHC", "value": 1245},\n' +
  '  {"label": "IHI", "value": 1246},\n' +
  '  {"label": "Ikarus", "value": 1247},\n' +
  '  {"label": "Ilusion", "value": 1248},\n' +
  '  {"label": "Indian", "value": 1249},\n' +
  '  {"label": "Infiniti", "value": 912},\n' +
  '  {"label": "Innocenti", "value": 913},\n' +
  '  {"label": "Intrall", "value": 1250},\n' +
  '  {"label": "Irion", "value": 1251},\n' +
  '  {"label": "Irisbus", "value": 1252},\n' +
  '  {"label": "Irizar", "value": 1253},\n' +
  '  {"label": "Iseki", "value": 1254},\n' +
  '  {"label": "Isuzu", "value": 54},\n' +
  '  {"label": "Italjet", "value": 1255},\n' +
  '  {"label": "Itineo", "value": 1256},\n' +
  '  {"label": "Iveco", "value": 108},\n' +
  '  {"label": "Jacobsen", "value": 1257},\n' +
  '  {"label": "Jaguar", "value": 33},\n' +
  '  {"label": "Jawa", "value": 1258},\n' +
  '  {"label": "JCB", "value": 1259},\n' +
  '  {"label": "Jeep", "value": 914},\n' +
  '  {"label": "Jinling", "value": 1260},\n' +
  '  {"label": "JLG", "value": 1261},\n' +
  '  {"label": "John Deere", "value": 1262},\n' +
  '  {"label": "Joint", "value": 1263},\n' +
  '  {"label": "Jungheinrich", "value": 1264},\n' +
  '  {"label": "Kabe", "value": 1265},\n' +
  '  {"label": "Kaercher", "value": 1266},\n' +
  '  {"label": "Kaeser", "value": 1267},\n' +
  '  {"label": "Kaiser", "value": 1268},\n' +
  '  {"label": "Kälble", "value": 1300},\n' +
  '  {"label": "Kalmar", "value": 1269},\n' +
  '  {"label": "Kamaz", "value": 1270},\n' +
  '  {"label": "Karmann", "value": 1271},\n' +
  '  {"label": "Kässbohrer", "value": 1301},\n' +
  '  {"label": "Kawasaki", "value": 1272},\n' +
  '  {"label": "Keeway", "value": 1273},\n' +
  '  {"label": "Kel-Berg", "value": 1274},\n' +
  '  {"label": "Kempf", "value": 1275},\n' +
  '  {"label": "Kenworth", "value": 1276},\n' +
  '  {"label": "Kia", "value": 101},\n' +
  '  {"label": "Kimi", "value": 1277},\n' +
  '  {"label": "King Long", "value": 1278},\n' +
  '  {"label": "Kingway", "value": 1279},\n' +
  '  {"label": "Kip", "value": 1280},\n' +
  '  {"label": "Klagie", "value": 1281},\n' +
  '  {"label": "Knapen", "value": 1282},\n' +
  '  {"label": "Knaus", "value": 1283},\n' +
  '  {"label": "Knievel", "value": 1284},\n' +
  '  {"label": "Kobelco", "value": 1285},\n' +
  '  {"label": "Koch", "value": 1286},\n' +
  '  {"label": "Kögel", "value": 1302},\n' +
  '  {"label": "Komatsu", "value": 1287},\n' +
  '  {"label": "Kotschenreuther", "value": 1288},\n' +
  '  {"label": "Kraker", "value": 1289},\n' +
  '  {"label": "Kramer", "value": 1290},\n' +
  '  {"label": "Kreidler", "value": 1291},\n' +
  '  {"label": "Kroeger", "value": 1292},\n' +
  '  {"label": "Krone", "value": 1293},\n' +
  '  {"label": "Krukenmeier", "value": 1294},\n' +
  '  {"label": "Krupp", "value": 1295},\n' +
  '  {"label": "KTM", "value": 1296},\n' +
  '  {"label": "Kubota", "value": 1297},\n' +
  '  {"label": "Kumpan", "value": 1298},\n' +
  '  {"label": "Kymco", "value": 1299},\n' +
  '  {"label": "La Strada", "value": 1303},\n' +
  '  {"label": "Lada", "value": 29},\n' +
  '  {"label": "Ladog", "value": 1304},\n' +
  '  {"label": "Lafaro", "value": 1305},\n' +
  '  {"label": "Lag", "value": 1306},\n' +
  '  {"label": "Laika", "value": 1307},\n' +
  '  {"label": "Lamberet", "value": 1308},\n' +
  '  {"label": "Lamborghini", "value": 915},\n' +
  '  {"label": "Lancia", "value": 30},\n' +
  '  {"label": "Land Rover", "value": 31},\n' +
  '  {"label": "Landini", "value": 1309},\n' +
  '  {"label": "Landsberg", "value": 1310},\n' +
  '  {"label": "Langendorf", "value": 1311},\n' +
  '  {"label": "Lansing", "value": 1312},\n' +
  '  {"label": "Lanz", "value": 1313},\n' +
  '  {"label": "Laverda", "value": 1314},\n' +
  '  {"label": "LDV", "value": 95},\n' +
  '  {"label": "Lecinena", "value": 1315},\n' +
  '  {"label": "LeciTrailer", "value": 1316},\n' +
  '  {"label": "Lely", "value": 1317},\n' +
  '  {"label": "Lemken", "value": 1318},\n' +
  '  {"label": "Lexus", "value": 61},\n' +
  '  {"label": "Ley", "value": 1319},\n' +
  '  {"label": "Liebherr", "value": 1320},\n' +
  '  {"label": "Lifan", "value": 1321},\n' +
  '  {"label": "Ligier", "value": 916},\n' +
  '  {"label": "Lincoln", "value": 917},\n' +
  '  {"label": "Linde", "value": 1322},\n' +
  '  {"label": "Linhai", "value": 1323},\n' +
  '  {"label": "LinTrailers", "value": 1324},\n' +
  '  {"label": "LMC", "value": 1325},\n' +
  '  {"label": "LML", "value": 1326},\n' +
  '  {"label": "Lotus", "value": 918},\n' +
  '  {"label": "Lück", "value": 1328},\n' +
  '  {"label": "Luxxon", "value": 1327},\n' +
  '  {"label": "M+M Mobile", "value": 1329},\n' +
  '  {"label": "Mack", "value": 1330},\n' +
  '  {"label": "Magirus Deutz", "value": 1331},\n' +
  '  {"label": "Magyar", "value": 1332},\n' +
  '  {"label": "Mahindra", "value": 919},\n' +
  '  {"label": "Maico", "value": 1333},\n' +
  '  {"label": "Malaguti", "value": 1334},\n' +
  '  {"label": "MAN", "value": 1335},\n' +
  '  {"label": "Manitou", "value": 1336},\n' +
  '  {"label": "Maschio", "value": 1337},\n' +
  '  {"label": "Maserati", "value": 920},\n' +
  '  {"label": "Massey Ferguson", "value": 1338},\n' +
  '  {"label": "Max Holland", "value": 1339},\n' +
  '  {"label": "Maybach", "value": 921},\n' +
  '  {"label": "Mazda", "value": 37},\n' +
  '  {"label": "MBK", "value": 1340},\n' +
  '  {"label": "McCormick", "value": 1341},\n' +
  '  {"label": "McLouis", "value": 1342},\n' +
  '  {"label": "MECALAC", "value": 1343},\n' +
  '  {"label": "MEGA", "value": 1344},\n' +
  '  {"label": "Megelli", "value": 1345},\n' +
  '  {"label": "Meierling", "value": 1346},\n' +
  '  {"label": "Meiller", "value": 1347},\n' +
  '  {"label": "Menci", "value": 1348},\n' +
  '  {"label": "Mengele", "value": 1349},\n' +
  '  {"label": "Mercedes-Benz", "value": 38},\n' +
  '  {"label": "Merker", "value": 1350},\n' +
  '  {"label": "Merlo", "value": 1351},\n' +
  '  {"label": "Meusburger", "value": 1352},\n' +
  '  {"label": "MF", "value": 1353},\n' +
  '  {"label": "MG", "value": 18},\n' +
  '  {"label": "Michigan", "value": 1354},\n' +
  '  {"label": "Miller", "value": 1355},\n' +
  '  {"label": "Mini (BMW)", "value": 113},\n' +
  '  {"label": "Mini (Rover)", "value": 231},\n' +
  '  {"label": "Mirage", "value": 1356},\n' +
  '  {"label": "Mitsubishi", "value": 39},\n' +
  '  {"label": "Mobilvetta", "value": 1357},\n' +
  '  {"label": "Monaco", "value": 1358},\n' +
  '  {"label": "Moncayo", "value": 1359},\n' +
  '  {"label": "Montenegro", "value": 1360},\n' +
  '  {"label": "Morelo", "value": 1361},\n' +
  '  {"label": "Morgan", "value": 922},\n' +
  '  {"label": "Morooka", "value": 1362},\n' +
  '  {"label": "Möslein", "value": 1371},\n' +
  '  {"label": "Moto Guzzi", "value": 1363},\n' +
  '  {"label": "Moto Morini", "value": 1364},\n' +
  '  {"label": "Motobi", "value": 1365},\n' +
  '  {"label": "Motowell", "value": 1366},\n' +
  '  {"label": "Moxy", "value": 1367},\n' +
  '  {"label": "Müller-Mitteltal", "value": 1372},\n' +
  '  {"label": "Multicar", "value": 1368},\n' +
  '  {"label": "MV Agusta", "value": 1369},\n' +
  '  {"label": "Mz", "value": 1370},\n' +
  '  {"label": "Neoplan", "value": 1373},\n' +
  '  {"label": "Neptun", "value": 1374},\n' +
  '  {"label": "Neuson", "value": 1375},\n' +
  '  {"label": "New Holland", "value": 1376},\n' +
  '  {"label": "NFP-Eurotrailer", "value": 1377},\n' +
  '  {"label": "Niemeyer", "value": 1378},\n' +
  '  {"label": "Niesmann + Bischoff", "value": 1379},\n' +
  '  {"label": "Niewiadów", "value": 1380},\n' +
  '  {"label": "Niftylift", "value": 1381},\n' +
  '  {"label": "Nissan", "value": 40},\n' +
  '  {"label": "NobelART", "value": 1382},\n' +
  '  {"label": "Nooteboom", "value": 1383},\n' +
  '  {"label": "Nordstar", "value": 1384},\n' +
  '  {"label": "Norton", "value": 1385},\n' +
  '  {"label": "NPK", "value": 1386},\n' +
  '  {"label": "NSU", "value": 1387},\n' +
  '  {"label": "O & K", "value": 1388},\n' +
  '  {"label": "Obermaier", "value": 1389},\n' +
  '  {"label": "Oldsmobile", "value": 923},\n' +
  '  {"label": "Online", "value": 1390},\n' +
  '  {"label": "Opel", "value": 41},\n' +
  '  {"label": "Orangecamp", "value": 1391},\n' +
  '  {"label": "Ormocar", "value": 1392},\n' +
  '  {"label": "Orten", "value": 1393},\n' +
  '  {"label": "Orthaus", "value": 1394},\n' +
  '  {"label": "Pacton", "value": 1395},\n' +
  '  {"label": "Palfinger", "value": 1396},\n' +
  '  {"label": "Paul & Paula", "value": 1397},\n' +
  '  {"label": "Paus", "value": 1398},\n' +
  '  {"label": "Pegasus", "value": 1399},\n' +
  '  {"label": "Pel-Job", "value": 1400},\n' +
  '  {"label": "Peterbilt", "value": 1401},\n' +
  '  {"label": "Peugeot", "value": 42},\n' +
  '  {"label": "Pezzaioli", "value": 1402},\n' +
  '  {"label": "PGO", "value": 1403},\n' +
  '  {"label": "Phoenix", "value": 1404},\n' +
  '  {"label": "Piaggio", "value": 924},\n' +
  '  {"label": "Pilote", "value": 1405},\n' +
  '  {"label": "Pimespo", "value": 1406},\n' +
  '  {"label": "Plymouth", "value": 925},\n' +
  '  {"label": "Polaris", "value": 1407},\n' +
  '  {"label": "Pongratz", "value": 1408},\n' +
  '  {"label": "Pontiac", "value": 27},\n' +
  '  {"label": "Porsche", "value": 43},\n' +
  '  {"label": "Pössl", "value": 1412},\n' +
  '  {"label": "Potain", "value": 1409},\n' +
  '  {"label": "Pöttinger", "value": 1413},\n' +
  '  {"label": "Proton", "value": 107},\n' +
  '  {"label": "Puch", "value": 1410},\n' +
  '  {"label": "PZ-Vicon", "value": 1411},\n' +
  '  {"label": "Quadro", "value": 1414},\n' +
  '  {"label": "Rabe", "value": 1415},\n' +
  '  {"label": "Raclet", "value": 1416},\n' +
  '  {"label": "Rammax", "value": 1417},\n' +
  '  {"label": "Rapido", "value": 1418},\n' +
  '  {"label": "Rau", "value": 1419},\n' +
  '  {"label": "Rauch", "value": 1420},\n' +
  '  {"label": "Reformwerke Wels", "value": 1421},\n' +
  '  {"label": "Regent", "value": 1422},\n' +
  '  {"label": "Reimo", "value": 1423},\n' +
  '  {"label": "Reisemobile Beier", "value": 1424},\n' +
  '  {"label": "Renault", "value": 44},\n' +
  '  {"label": "Renders", "value": 1425},\n' +
  '  {"label": "Reuter", "value": 1426},\n' +
  '  {"label": "Rewaco", "value": 1427},\n' +
  '  {"label": "Rieju", "value": 1428},\n' +
  '  {"label": "Rimor", "value": 1429},\n' +
  '  {"label": "Riva", "value": 1430},\n' +
  '  {"label": "Rivero", "value": 1431},\n' +
  '  {"label": "Riviera", "value": 1432},\n' +
  '  {"label": "RMB", "value": 1433},\n' +
  '  {"label": "Roadtrek", "value": 1434},\n' +
  '  {"label": "Robel-Mobil", "value": 1435},\n' +
  '  {"label": "Robur", "value": 1436},\n' +
  '  {"label": "ROHR", "value": 1437},\n' +
  '  {"label": "ROKA", "value": 1438},\n' +
  '  {"label": "Roller Team", "value": 1439},\n' +
  '  {"label": "Rolls Royce", "value": 926},\n' +
  '  {"label": "Rover", "value": 35},\n' +
  '  {"label": "Royal Enfield", "value": 1440},\n' +
  '  {"label": "Saab", "value": 45},\n' +
  '  {"label": "Sachs", "value": 1441},\n' +
  '  {"label": "Sambron", "value": 1442},\n' +
  '  {"label": "Same", "value": 1443},\n' +
  '  {"label": "Samro", "value": 1444},\n' +
  '  {"label": "Saris", "value": 1445},\n' +
  '  {"label": "SAXAS", "value": 1446},\n' +
  '  {"label": "Scania", "value": 1447},\n' +
  '  {"label": "Schaeff", "value": 1448},\n' +
  '  {"label": "Schäffer", "value": 1455},\n' +
  '  {"label": "Scheuerle", "value": 1449},\n' +
  '  {"label": "Schlüter", "value": 1450},\n' +
  '  {"label": "Schmidt", "value": 1451},\n' +
  '  {"label": "Schmitz Cargobull", "value": 1452},\n' +
  '  {"label": "Schrader", "value": 1453},\n' +
  '  {"label": "Schwarzmüller", "value": 1454},\n' +
  '  {"label": "SEA", "value": 1456},\n' +
  '  {"label": "Seat", "value": 19},\n' +
  '  {"label": "Seico", "value": 1457},\n' +
  '  {"label": "Seikel", "value": 1458},\n' +
  '  {"label": "Seitz", "value": 1459},\n' +
  '  {"label": "SEKA", "value": 1460},\n' +
  '  {"label": "Selbstbau", "value": 1461},\n' +
  '  {"label": "Sennebogen", "value": 1462},\n' +
  '  {"label": "Setra", "value": 1463},\n' +
  '  {"label": "Shatal", "value": 1464},\n' +
  '  {"label": "Sherco", "value": 1465},\n' +
  '  {"label": "Shineray", "value": 1466},\n' +
  '  {"label": "Sichelschmidt", "value": 1467},\n' +
  '  {"label": "Simson", "value": 1468},\n' +
  '  {"label": "Six-Pac", "value": 1469},\n' +
  '  {"label": "ŠKODA", "value": 46},\n' +
  '  {"label": "Skyteam", "value": 1470},\n' +
  '  {"label": "Sloop", "value": 1471},\n' +
  '  {"label": "Sluis", "value": 1472},\n' +
  '  {"label": "Smart", "value": 94},\n' +
  '  {"label": "SMC", "value": 1473},\n' +
  '  {"label": "Solaris", "value": 1474},\n' +
  '  {"label": "Sommer", "value": 1475},\n' +
  '  {"label": "Spitzer", "value": 1476},\n' +
  '  {"label": "Sprite", "value": 1477},\n' +
  '  {"label": "SsangYong", "value": 109},\n' +
  '  {"label": "Stas", "value": 1478},\n' +
  '  {"label": "Stedele", "value": 1479},\n' +
  '  {"label": "Steinbock", "value": 1480},\n' +
  '  {"label": "Stema", "value": 1481},\n' +
  '  {"label": "Sterckeman", "value": 1482},\n' +
  '  {"label": "Steyr", "value": 1483},\n' +
  '  {"label": "Still", "value": 1484},\n' +
  '  {"label": "Strautmann", "value": 1485},\n' +
  '  {"label": "Subaru", "value": 47},\n' +
  '  {"label": "Sun Living", "value": 1486},\n' +
  '  {"label": "Sunlight", "value": 1487},\n' +
  '  {"label": "Suzuki", "value": 48},\n' +
  '  {"label": "SYM", "value": 1488},\n' +
  '  {"label": "T@b", "value": 1489},\n' +
  '  {"label": "Tabbert", "value": 1490},\n' +
  '  {"label": "Takeuchi", "value": 1491},\n' +
  '  {"label": "Talbot", "value": 927},\n' +
  '  {"label": "Talson", "value": 1492},\n' +
  '  {"label": "Tatra", "value": 1493},\n' +
  '  {"label": "Tauris", "value": 1494},\n' +
  '  {"label": "TCM", "value": 1495},\n' +
  '  {"label": "TEC", "value": 1496},\n' +
  '  {"label": "Tempus", "value": 1497},\n' +
  '  {"label": "Temsa", "value": 1498},\n' +
  '  {"label": "Terberg", "value": 1499},\n' +
  '  {"label": "Terex", "value": 1500},\n' +
  '  {"label": "TGB", "value": 1501},\n' +
  '  {"label": "Thiel", "value": 1502},\n' +
  '  {"label": "THULE", "value": 1503},\n' +
  '  {"label": "Thwaites", "value": 1504},\n' +
  '  {"label": "Tijhof", "value": 1505},\n' +
  '  {"label": "Tischer", "value": 1506},\n' +
  '  {"label": "TM", "value": 1507},\n' +
  '  {"label": "Toyo", "value": 1508},\n' +
  '  {"label": "Toyota", "value": 50},\n' +
  '  {"label": "TPV", "value": 1509},\n' +
  '  {"label": "Trabant", "value": 928},\n' +
  '  {"label": "Trailor", "value": 1510},\n' +
  '  {"label": "Trebbiner", "value": 1511},\n' +
  '  {"label": "Trigano", "value": 1512},\n' +
  '  {"label": "Triple E", "value": 1513},\n' +
  '  {"label": "Triton", "value": 1514},\n' +
  '  {"label": "Triumph", "value": 929},\n' +
  '  {"label": "TSL Landsberg / Rockwood", "value": 1515},\n' +
  '  {"label": "TVR", "value": 930},\n' +
  '  {"label": "Unimog", "value": 1516},\n' +
  '  {"label": "Unsinn", "value": 1517},\n' +
  '  {"label": "Upright", "value": 1518},\n' +
  '  {"label": "Ural", "value": 1519},\n' +
  '  {"label": "Van Eck", "value": 1520},\n' +
  '  {"label": "Vanhool", "value": 1521},\n' +
  '  {"label": "Vario", "value": 1522},\n' +
  '  {"label": "Variotrail", "value": 1523},\n' +
  '  {"label": "Vauxhall", "value": 931},\n' +
  '  {"label": "VDL Berkhof", "value": 1524},\n' +
  '  {"label": "VDL BOVA", "value": 1525},\n' +
  '  {"label": "VDL Jonckheere", "value": 1526},\n' +
  '  {"label": "Vermeer", "value": 1527},\n' +
  '  {"label": "Vespa", "value": 1528},\n' +
  '  {"label": "Vezeko", "value": 1529},\n' +
  '  {"label": "VICTORY", "value": 1530},\n' +
  '  {"label": "Vogel&Noot", "value": 1531},\n' +
  '  {"label": "Vögele", "value": 1534},\n' +
  '  {"label": "Volkswagen", "value": 52},\n' +
  '  {"label": "Volvo", "value": 51},\n' +
  '  {"label": "Voss", "value": 1532},\n' +
  '  {"label": "Voxan", "value": 1533},\n' +
  '  {"label": "Wackenhut", "value": 1535},\n' +
  '  {"label": "Wacker", "value": 1536},\n' +
  '  {"label": "Wagner", "value": 1537},\n' +
  '  {"label": "Wartburg", "value": 932},\n' +
  '  {"label": "Weber", "value": 1538},\n' +
  '  {"label": "Wecon", "value": 1539},\n' +
  '  {"label": "Weidemann", "value": 1540},\n' +
  '  {"label": "Weinsberg", "value": 1541},\n' +
  '  {"label": "Weippert", "value": 1542},\n' +
  '  {"label": "Westfalia", "value": 1543},\n' +
  '  {"label": "Wielton", "value": 1544},\n' +
  '  {"label": "Wiesmann", "value": 933},\n' +
  '  {"label": "Wilk", "value": 1545},\n' +
  '  {"label": "Wingamm", "value": 1546},\n' +
  '  {"label": "Winnebago", "value": 1547},\n' +
  '  {"label": "Wirtgen", "value": 1548},\n' +
  '  {"label": "WM Meyer", "value": 1549},\n' +
  '  {"label": "WMI", "value": 1550},\n' +
  '  {"label": "Woelcke", "value": 1551},\n' +
  '  {"label": "Woodford", "value": 1552},\n' +
  '  {"label": "XGO", "value": 1553},\n' +
  '  {"label": "Xingyue", "value": 1568},\n' +
  '  {"label": "XXTrail", "value": 1554},\n' +
  '  {"label": "Yale", "value": 1555},\n' +
  '  {"label": "Yamaguchi", "value": 1556},\n' +
  '  {"label": "Yamaha", "value": 1557},\n' +
  '  {"label": "Yanmar", "value": 1558},\n' +
  '  {"label": "ZASLAW TRAILIS", "value": 1559},\n' +
  '  {"label": "Zeppelin", "value": 1560},\n' +
  '  {"label": "Zero", "value": 1561},\n' +
  '  {"label": "Zetor", "value": 1562},\n' +
  '  {"label": "Zettelmeyer", "value": 1563},\n' +
  '  {"label": "Zhongyu", "value": 1564},\n' +
  '  {"label": "Zündapp", "value": 1565}\n' +
  ']'


export const models = [
  {'label': '146', 'value': 29, make: 10},
  {'label': '147', 'value': 30, make: 10},
  {'label': '149', 'value': 31, make: 10},
  {'label': '155', 'value': 32, make: 10},
  {'label': '156', 'value': 33, make: 10},
  {'label': '159', 'value': 34, make: 10},
  {'label': '164', 'value': 35, make: 10},
  {'label': '166', 'value': 36, make: 10},
  {'label': '33', 'value': 118, make: 10},
  {'label': '4C', 'value': 1999, make: 10},
  {'label': '75', 'value': 214, make: 10},
  {'label': '8C', 'value': 229, make: 10},
  {'label': '90', 'value': 239, make: 10},
  {'label': 'Alfasud', 'value': 287, make: 10},
  {'label': 'Alfetta', 'value': 288, make: 10},
  {'label': 'Brera', 'value': 366, make: 10},
  {'label': 'Crosswagon', 'value': 534, make: 10},
  {'label': 'Giulia', 'value': 716, make: 10},
  {'label': 'Giulietta', 'value': 717, make: 10},
  {'label': 'GT', 'value': 1622, make: 10},
  {'label': 'GTV', 'value': 780, make: 10},
  {'label': 'MiTo', 'value': 943, make: 10},
  {'label': 'Spider', 'value': 2000, make: 10},
  {'label': 'Spider', 'value': 1246, make: 10},
  {'label': 'Sprint', 'value': 1251, make: 10},
  {'label': 'Stelvio', 'value': 2023, make: 10},
  {'label': '', 'value': 271, make: 11},
  {'label': '100', 'value': 2, make: 11},
  {'label': '200', 'value': 43, make: 11},
  {'label': '80', 'value': 219, make: 11},
  {'label': '90', 'value': 239, make: 11},
  {'label': 'A1', 'value': 266, make: 11},
  {'label': 'A1 Sportback', 'value': 1672, make: 11},
  {'label': 'A2', 'value': 267, make: 11},
  {'label': 'A3', 'value': 268, make: 11},
  {'label': 'A3 Sportback', 'value': 1671, make: 11},
  {'label': 'A4', 'value': 270, make: 11},
  {'label': 'A4 Allroad', 'value': 269, make: 11},
  {'label': 'A5 Cabriolet', 'value': 1727, make: 11},
  {'label': 'A5 Coupé', 'value': 1726, make: 11},
  {'label': 'A5 Sportback', 'value': 1669, make: 11},
  {'label': 'A6', 'value': 273, make: 11},
  {'label': 'A6 allroad quattro', 'value': 272, make: 11},
  {'label': 'A7 Sportback', 'value': 1670, make: 11},
  {'label': 'A8', 'value': 275, make: 11},
  {'label': 'A8 L', 'value': 1549, make: 11},
  {'label': 'A8 L W12', 'value': 1548, make: 11},
  {'label': 'e-tron', 'value': 2032, make: 11},
  {'label': 'Q1', 'value': 1773, make: 11},
  {'label': 'Q2', 'value': 1772, make: 11},
  {'label': 'Q3', 'value': 1053, make: 11},
  {'label': 'Q5', 'value': 1055, make: 11},
  {'label': 'Q7', 'value': 1056, make: 11},
  {'label': 'Q8', 'value': 2019, make: 11},
  {'label': 'QUATTRO', 'value': 2048, make: 11},
  {'label': 'R8', 'value': 1083, make: 11},
  {'label': 'R8 Spyder', 'value': 1728, make: 11},
  {'label': 'RS 2', 'value': 1107, make: 11},
  {'label': 'RS 3', 'value': 1108, make: 11},
  {'label': 'RS 4', 'value': 1109, make: 11},
  {'label': 'RS 5', 'value': 1110, make: 11},
  {'label': 'RS 5 Cabriolet', 'value': 1732, make: 11},
  {'label': 'RS 5 Coupé', 'value': 1731, make: 11},
  {'label': 'RS 6', 'value': 1111, make: 11},
  {'label': 'RS 7 Sportback', 'value': 1530, make: 11},
  {'label': 'RS Q3', 'value': 1606, make: 11},
  {'label': 'RS Q8', 'value': 2031, make: 11},
  {'label': 'S1', 'value': 1624, make: 11},
  {'label': 'S1 Sportback', 'value': 1623, make: 11},
  {'label': 'S3', 'value': 1147, make: 11},
  {'label': 'S3 Sportback', 'value': 1724, make: 11},
  {'label': 'S4', 'value': 1148, make: 11},
  {'label': 'S5', 'value': 1150, make: 11},
  {'label': 'S5 Cabriolet', 'value': 1730, make: 11},
  {'label': 'S5 Coupé', 'value': 1729, make: 11},
  {'label': 'S5 Sportback', 'value': 1673, make: 11},
  {'label': 'S6', 'value': 1151, make: 11},
  {'label': 'S7', 'value': 1458, make: 11},
  {'label': 'S7 Sportback', 'value': 1674, make: 11},
  {'label': 'S8', 'value': 1154, make: 11},
  {'label': 'SQ2', 'value': 2029, make: 11},
  {'label': 'SQ5', 'value': 1529, make: 11},
  {'label': 'SQ7', 'value': 1780, make: 11},
  {'label': 'SQ8', 'value': 2030, make: 11},
  {'label': 'TT', 'value': 1336, make: 11},
  {'label': 'TT Coupé', 'value': 1733, make: 11},
  {'label': 'TT Roadster', 'value': 1734, make: 11},
  {'label': 'TT RS', 'value': 1335, make: 11},
  {'label': 'TT RS Coupé', 'value': 1737, make: 11},
  {'label': 'TT RS Roadster', 'value': 1738, make: 11},
  {'label': 'TTS', 'value': 1337, make: 11},
  {'label': 'TTS Coupé', 'value': 1735, make: 11},
  {'label': 'TTS Roadster', 'value': 1736, make: 11},
  {'label': 'V8', 'value': 1360, make: 11},
  {'label': '{1502}', 'value': 1502, make: 13},
  {'label': '{1920}', 'value': 1920, make: 13},
  {'label': '114', 'value': 12, make: 13},
  {'label': '114d', 'value': 1565, make: 13},
  {'label': '114i', 'value': 1562, make: 13},
  {'label': '116', 'value': 14, make: 13},
  {'label': '116d', 'value': 1519, make: 13},
  {'label': '116i', 'value': 1501, make: 13},
  {'label': '118', 'value': 15, make: 13},
  {'label': '118d', 'value': 1513, make: 13},
  {'label': '118i', 'value': 1514, make: 13},
  {'label': '120', 'value': 16, make: 13},
  {'label': '1200 R', 'value': 1929, make: 13},
  {'label': '1200 RT', 'value': 1930, make: 13},
  {'label': '120d', 'value': 1500, make: 13},
  {'label': '120i', 'value': 1508, make: 13},
  {'label': '123', 'value': 19, make: 13},
  {'label': '123d', 'value': 1570, make: 13},
  {'label': '125', 'value': 21, make: 13},
  {'label': '125d', 'value': 1925, make: 13},
  {'label': '125i', 'value': 1569, make: 13},
  {'label': '130', 'value': 24, make: 13},
  {'label': '135i', 'value': 1589, make: 13},
  {'label': '1er M Coupé', 'value': 1817, make: 13},
  {'label': '2002', 'value': 1819, make: 13},
  {'label': '214 Active Tourer', 'value': 1818, make: 13},
  {'label': '214 Gran Tourer', 'value': 1820, make: 13},
  {'label': '216', 'value': 65, make: 13},
  {'label': '216 Active Tourer', 'value': 1821, make: 13},
  {'label': '216 Gran Tourer', 'value': 1822, make: 13},
  {'label': '216d', 'value': 1912, make: 13},
  {'label': '216i', 'value': 1913, make: 13},
  {'label': '218', 'value': 66, make: 13},
  {'label': '218 Active Tourer', 'value': 1823, make: 13},
  {'label': '218 Gran Tourer', 'value': 1824, make: 13},
  {'label': '218d', 'value': 1643, make: 13},
  {'label': '218i', 'value': 1660, make: 13},
  {'label': '220 Active Tourer', 'value': 1825, make: 13},
  {'label': '220 Gran Tourer', 'value': 1826, make: 13},
  {'label': '220d', 'value': 1580, make: 13},
  {'label': '220i', 'value': 1592, make: 13},
  {'label': '225', 'value': 1857, make: 13},
  {'label': '225 Active Tourer', 'value': 1827, make: 13},
  {'label': '225xe', 'value': 1921, make: 13},
  {'label': '228i', 'value': 1803, make: 13},
  {'label': '230i', 'value': 1907, make: 13},
  {'label': '235', 'value': 1926, make: 13},
  {'label': '235i', 'value': 1927, make: 13},
  {'label': '316', 'value': 110, make: 13},
  {'label': '316d', 'value': 1511, make: 13},
  {'label': '316i', 'value': 1521, make: 13},
  {'label': '318', 'value': 111, make: 13},
  {'label': '318 Gran Turismo', 'value': 1828, make: 13},
  {'label': '318d', 'value': 1490, make: 13},
  {'label': '318i', 'value': 1453, make: 13},
  {'label': '320', 'value': 1537, make: 13},
  {'label': '320 Gran Turismo', 'value': 1829, make: 13},
  {'label': '320d', 'value': 112, make: 13},
  {'label': '320i', 'value': 1469, make: 13},
  {'label': '323', 'value': 114, make: 13},
  {'label': '324', 'value': 115, make: 13},
  {'label': '325', 'value': 116, make: 13},
  {'label': '325 Gran Turismo', 'value': 1830, make: 13},
  {'label': '325d', 'value': 1567, make: 13},
  {'label': '325i', 'value': 1488, make: 13},
  {'label': '328', 'value': 117, make: 13},
  {'label': '328 Gran Turismo', 'value': 1831, make: 13},
  {'label': '328i', 'value': 1561, make: 13},
  {'label': '330', 'value': 119, make: 13},
  {'label': '330 Gran Turismo', 'value': 1832, make: 13},
  {'label': '330d', 'value': 1496, make: 13},
  {'label': '330i', 'value': 1656, make: 13},
  {'label': '335', 'value': 120, make: 13},
  {'label': '335 Gran Turismo', 'value': 1833, make: 13},
  {'label': '335d', 'value': 1522, make: 13},
  {'label': '335i', 'value': 1575, make: 13},
  {'label': '340', 'value': 122, make: 13},
  {'label': '418 Gran Coupé', 'value': 1835, make: 13},
  {'label': '420', 'value': 147, make: 13},
  {'label': '420 Gran Coupé', 'value': 1836, make: 13},
  {'label': '420d', 'value': 1527, make: 13},
  {'label': '420i', 'value': 1644, make: 13},
  {'label': '425 Gran Coupé', 'value': 1837, make: 13},
  {'label': '425d', 'value': 1918, make: 13},
  {'label': '425d Gran Coupé', 'value': 1919, make: 13},
  {'label': '428 Gran Coupé', 'value': 1838, make: 13},
  {'label': '428i', 'value': 1888, make: 13},
  {'label': '428iA', 'value': 1498, make: 13},
  {'label': '430', 'value': 151, make: 13},
  {'label': '430 Gran Coupé', 'value': 1839, make: 13},
  {'label': '430d', 'value': 1909, make: 13},
  {'label': '430i', 'value': 1908, make: 13},
  {'label': '435', 'value': 1806, make: 13},
  {'label': '435 Gran Coupé', 'value': 1840, make: 13},
  {'label': '435d', 'value': 1805, make: 13},
  {'label': '435i', 'value': 1538, make: 13},
  {'label': '440 Gran Coupé', 'value': 1841, make: 13},
  {'label': '440i', 'value': 1911, make: 13},
  {'label': '518', 'value': 167, make: 13},
  {'label': '518d', 'value': 1664, make: 13},
  {'label': '520', 'value': 168, make: 13},
  {'label': '520 Gran Turismo', 'value': 1842, make: 13},
  {'label': '520d', 'value': 1491, make: 13},
  {'label': '520i', 'value': 1574, make: 13},
  {'label': '523', 'value': 169, make: 13},
  {'label': '523i', 'value': 1506, make: 13},
  {'label': '524', 'value': 170, make: 13},
  {'label': '525', 'value': 171, make: 13},
  {'label': '525d', 'value': 1512, make: 13},
  {'label': '525i', 'value': 1571, make: 13},
  {'label': '528', 'value': 172, make: 13},
  {'label': '528i', 'value': 1517, make: 13},
  {'label': '530', 'value': 174, make: 13},
  {'label': '530d', 'value': 1505, make: 13},
  {'label': '530i', 'value': 1499, make: 13},
  {'label': '530i Gran Turismo', 'value': 173, make: 13},
  {'label': '535', 'value': 176, make: 13},
  {'label': '535d', 'value': 1507, make: 13},
  {'label': '535i', 'value': 1504, make: 13},
  {'label': '535i Gran Turismo', 'value': 175, make: 13},
  {'label': '540', 'value': 177, make: 13},
  {'label': '540i', 'value': 1910, make: 13},
  {'label': '545', 'value': 178, make: 13},
  {'label': '550', 'value': 180, make: 13},
  {'label': '550 Gran Turismo', 'value': 179, make: 13},
  {'label': '550i', 'value': 1533, make: 13},
  {'label': '628', 'value': 198, make: 13},
  {'label': '630', 'value': 199, make: 13},
  {'label': '630i', 'value': 1590, make: 13},
  {'label': '630iA', 'value': 1657, make: 13},
  {'label': '633', 'value': 200, make: 13},
  {'label': '635', 'value': 201, make: 13},
  {'label': '640', 'value': 1525, make: 13},
  {'label': '640 Gran Coupé', 'value': 1844, make: 13},
  {'label': '640d', 'value': 1526, make: 13},
  {'label': '645', 'value': 202, make: 13},
  {'label': '645i', 'value': 1576, make: 13},
  {'label': '650', 'value': 203, make: 13},
  {'label': '650 Gran Coupé', 'value': 1845, make: 13},
  {'label': '650i', 'value': 1516, make: 13},
  {'label': '725', 'value': 206, make: 13},
  {'label': '728', 'value': 207, make: 13},
  {'label': '730', 'value': 208, make: 13},
  {'label': '730d', 'value': 1503, make: 13},
  {'label': '735', 'value': 210, make: 13},
  {'label': '740', 'value': 211, make: 13},
  {'label': '740d', 'value': 1568, make: 13},
  {'label': '740i', 'value': 1915, make: 13},
  {'label': '740L', 'value': 1916, make: 13},
  {'label': '740Ld', 'value': 1922, make: 13},
  {'label': '745', 'value': 213, make: 13},
  {'label': '750d', 'value': 1608, make: 13},
  {'label': '750i', 'value': 215, make: 13},
  {'label': '750L', 'value': 1923, make: 13},
  {'label': '750Ld', 'value': 1924, make: 13},
  {'label': '750Li', 'value': 1536, make: 13},
  {'label': '760', 'value': 216, make: 13},
  {'label': '760i', 'value': 1510, make: 13},
  {'label': '760Li', 'value': 1914, make: 13},
  {'label': '840', 'value': 226, make: 13},
  {'label': '850', 'value': 227, make: 13},
  {'label': 'ActiveHybrid 3', 'value': 1834, make: 13},
  {'label': 'ActiveHybrid 5', 'value': 1843, make: 13},
  {'label': 'ActiveHybrid 7', 'value': 278, make: 13},
  {'label': 'ActiveHybrid X6', 'value': 279, make: 13},
  {'label': 'C 600', 'value': 1945, make: 13},
  {'label': 'C 600 Sport', 'value': 1946, make: 13},
  {'label': 'C 650', 'value': 1947, make: 13},
  {'label': 'C 650 GT', 'value': 1948, make: 13},
  {'label': 'C 650 Sport', 'value': 1949, make: 13},
  {'label': 'F 700 GS', 'value': 1943, make: 13},
  {'label': 'F 800 GS', 'value': 1951, make: 13},
  {'label': 'F 800 GT', 'value': 1950, make: 13},
  {'label': 'F 800 R', 'value': 1933, make: 13},
  {'label': 'G 310 R', 'value': 1940, make: 13},
  {'label': 'GT', 'value': 1622, make: 13},
  {'label': 'i3', 'value': 1846, make: 13},
  {'label': 'i8', 'value': 1847, make: 13},
  {'label': 'K 1300 R', 'value': 1941, make: 13},
  {'label': 'K 1300 S', 'value': 1942, make: 13},
  {'label': 'K 1600 GT', 'value': 1931, make: 13},
  {'label': 'M 550d', 'value': 1573, make: 13},
  {'label': 'M1', 'value': 894, make: 13},
  {'label': 'M135', 'value': 1848, make: 13},
  {'label': 'M140i', 'value': 1849, make: 13},
  {'label': 'M2', 'value': 1850, make: 13},
  {'label': 'M235i', 'value': 1610, make: 13},
  {'label': 'M240i', 'value': 1851, make: 13},
  {'label': 'M3', 'value': 895, make: 13},
  {'label': 'M4', 'value': 1655, make: 13},
  {'label': 'M5', 'value': 899, make: 13},
  {'label': 'M550', 'value': 1852, make: 13},
  {'label': 'M6', 'value': 900, make: 13},
  {'label': 'R 1100 S', 'value': 1944, make: 13},
  {'label': 'R 1150 RT', 'value': 1953, make: 13},
  {'label': 'R 1200 GS', 'value': 1939, make: 13},
  {'label': 'R 1200 R', 'value': 1938, make: 13},
  {'label': 'R 1200 RS', 'value': 1952, make: 13},
  {'label': 'R nineT', 'value': 1932, make: 13},
  {'label': 'S 1000 R', 'value': 1934, make: 13},
  {'label': 'S 1000 RR', 'value': 1718, make: 13},
  {'label': 'S 1000 XR', 'value': 1935, make: 13},
  {'label': 'X1', 'value': 1408, make: 13},
  {'label': 'X3', 'value': 1409, make: 13},
  {'label': 'X4', 'value': 1651, make: 13},
  {'label': 'X4 M40', 'value': 1854, make: 13},
  {'label': 'X5', 'value': 1411, make: 13},
  {'label': 'X5 M', 'value': 1410, make: 13},
  {'label': 'X5 M50', 'value': 1855, make: 13},
  {'label': 'X6', 'value': 1472, make: 13},
  {'label': 'X6 M', 'value': 1412, make: 13},
  {'label': 'X6 M50', 'value': 1856, make: 13},
  {'label': 'Z1', 'value': 1440, make: 13},
  {'label': 'Z3', 'value': 1442, make: 13},
  {'label': 'Z3 M', 'value': 1441, make: 13},
  {'label': 'Z4', 'value': 1444, make: 13},
  {'label': 'Z4 M', 'value': 1443, make: 13},
  {'label': 'Z8', 'value': 1445, make: 13},
  {'label': 'AX', 'value': 323, make: 14},
  {'label': 'Berlingo', 'value': 349, make: 14},
  {'label': 'BX', 'value': 372, make: 14},
  {'label': 'C-Crosser', 'value': 390, make: 14},
  {'label': 'C-Elysée', 'value': 1799, make: 14},
  {'label': 'C-Zero', 'value': 394, make: 14},
  {'label': 'C1', 'value': 396, make: 14},
  {'label': 'C2', 'value': 398, make: 14},
  {'label': 'C3', 'value': 400, make: 14},
  {'label': 'C3 Picasso', 'value': 399, make: 14},
  {'label': 'C4', 'value': 403, make: 14},
  {'label': 'C4 Aircross', 'value': 2035, make: 14},
  {'label': 'C4 Cactus', 'value': 2034, make: 14},
  {'label': 'C4 Picasso', 'value': 402, make: 14},
  {'label': 'C4 SpaceTourer', 'value': 2036, make: 14},
  {'label': 'C5', 'value': 404, make: 14},
  {'label': 'C6', 'value': 405, make: 14},
  {'label': 'C8', 'value': 407, make: 14},
  {'label': 'CX', 'value': 542, make: 14},
  {'label': 'DS3', 'value': 575, make: 14},
  {'label': 'DS4', 'value': 576, make: 14},
  {'label': 'DS5', 'value': 577, make: 14},
  {'label': 'Evasion', 'value': 634, make: 14},
  {'label': 'Grand C4 Picasso', 'value': 758, make: 14},
  {'label': 'Jumper', 'value': 837, make: 14},
  {'label': 'Jumpy', 'value': 838, make: 14},
  {'label': 'Nemo', 'value': 985, make: 14},
  {'label': 'SAXO', 'value': 1165, make: 14},
  {'label': 'SpaceTourer', 'value': 1886, make: 14},
  {'label': 'Visa', 'value': 1383, make: 14},
  {'label': 'Xantia', 'value': 1413, make: 14},
  {'label': 'XM', 'value': 1432, make: 14},
  {'label': 'Xsara', 'value': 1434, make: 14},
  {'label': 'Xsara Picasso', 'value': 1433, make: 14},
  {'label': 'ZX', 'value': 1452, make: 14},
  {'label': 'Applause', 'value': 305, make: 16},
  {'label': 'Charade', 'value': 443, make: 16},
  {'label': 'Charmant', 'value': 445, make: 16},
  {'label': 'Copen', 'value': 509, make: 16},
  {'label': 'Cuore', 'value': 541, make: 16},
  {'label': 'Feroza', 'value': 660, make: 16},
  {'label': 'Freeclimber', 'value': 682, make: 16},
  {'label': 'Gran Move', 'value': 752, make: 16},
  {'label': 'MATERIA', 'value': 912, make: 16},
  {'label': 'Move', 'value': 968, make: 16},
  {'label': 'Rocky', 'value': 1102, make: 16},
  {'label': 'Sirion', 'value': 1205, make: 16},
  {'label': 'Terios', 'value': 1297, make: 16},
  {'label': 'TREVIS', 'value': 1330, make: 16},
  {'label': 'YRV', 'value': 1439, make: 16},
  {'label': '124', 'value': 20, make: 17},
  {'label': '124 Spider', 'value': 1784, make: 17},
  {'label': '126', 'value': 22, make: 17},
  {'label': '145', 'value': 28, make: 17},
  {'label': '146', 'value': 29, make: 17},
  {'label': '147', 'value': 30, make: 17},
  {'label': '155', 'value': 32, make: 17},
  {'label': '156', 'value': 33, make: 17},
  {'label': '159', 'value': 34, make: 17},
  {'label': '164', 'value': 35, make: 17},
  {'label': '33', 'value': 118, make: 17},
  {'label': '500', 'value': 162, make: 17},
  {'label': '500C', 'value': 1466, make: 17},
  {'label': '500L', 'value': 1635, make: 17},
  {'label': '500S', 'value': 1706, make: 17},
  {'label': '500X', 'value': 1708, make: 17},
  {'label': '75', 'value': 214, make: 17},
  {'label': '840', 'value': 226, make: 17},
  {'label': '8C', 'value': 229, make: 17},
  {'label': 'Barchetta', 'value': 345, make: 17},
  {'label': 'Brava', 'value': 364, make: 17},
  {'label': 'Bravo', 'value': 365, make: 17},
  {'label': 'Brera', 'value': 366, make: 17},
  {'label': 'Cargo', 'value': 423, make: 17},
  {'label': 'Cinquecento', 'value': 450, make: 17},
  {'label': 'Croma', 'value': 531, make: 17},
  {'label': 'Dedra', 'value': 561, make: 17},
  {'label': 'Delta', 'value': 563, make: 17},
  {'label': 'Doblo', 'value': 573, make: 17},
  {'label': 'Ducato', 'value': 578, make: 17},
  {'label': 'Fiorino', 'value': 663, make: 17},
  {'label': 'Freeclimber', 'value': 682, make: 17},
  {'label': 'Freemont', 'value': 1583, make: 17},
  {'label': 'Fullback', 'value': 1897, make: 17},
  {'label': 'Grande Punto', 'value': 768, make: 17},
  {'label': 'GT', 'value': 1622, make: 17},
  {'label': 'GTV', 'value': 780, make: 17},
  {'label': 'Idea', 'value': 809, make: 17},
  {'label': 'Integra', 'value': 819, make: 17},
  {'label': 'Linea', 'value': 882, make: 17},
  {'label': 'Logan', 'value': 886, make: 17},
  {'label': 'Marea', 'value': 907, make: 17},
  {'label': 'Marengo', 'value': 908, make: 17},
  {'label': 'MiTo', 'value': 943, make: 17},
  {'label': 'Multipla', 'value': 972, make: 17},
  {'label': 'New Panda', 'value': 1678, make: 17},
  {'label': 'Panda', 'value': 1012, make: 17},
  {'label': 'Prisma', 'value': 1044, make: 17},
  {'label': 'Punto', 'value': 1052, make: 17},
  {'label': 'Punto Evo', 'value': 1051, make: 17},
  {'label': 'Qubo', 'value': 1061, make: 17},
  {'label': 'Regata', 'value': 1093, make: 17},
  {'label': 'Ritmo', 'value': 1099, make: 17},
  {'label': 'Scudo', 'value': 1174, make: 17},
  {'label': 'Sedici', 'value': 1177, make: 17},
  {'label': 'Seicento', 'value': 1178, make: 17},
  {'label': 'Spider', 'value': 2000, make: 17},
  {'label': 'Stilo', 'value': 1259, make: 17},
  {'label': 'Strada', 'value': 1260, make: 17},
  {'label': 'Talento', 'value': 1796, make: 17},
  {'label': 'Tempra', 'value': 1294, make: 17},
  {'label': 'Thema', 'value': 1303, make: 17},
  {'label': 'Tipo', 'value': 1309, make: 17},
  {'label': 'Ulysse', 'value': 1344, make: 17},
  {'label': 'Uno', 'value': 1345, make: 17},
  {'label': '75', 'value': 214, make: 18},
  {'label': 'MGA', 'value': 935, make: 18},
  {'label': 'MGB', 'value': 936, make: 18},
  {'label': 'MGF', 'value': 937, make: 18},
  {'label': 'Midget', 'value': 939, make: 18},
  {'label': 'TF', 'value': 1302, make: 18},
  {'label': 'ZR', 'value': 1449, make: 18},
  {'label': 'ZS', 'value': 1450, make: 18},
  {'label': 'ZT', 'value': 1451, make: 18},
  {'label': 'Alhambra', 'value': 289, make: 19},
  {'label': 'Altea', 'value': 297, make: 19},
  {'label': 'Arona', 'value': 1962, make: 19},
  {'label': 'Ateca', 'value': 1767, make: 19},
  {'label': 'Cordoba', 'value': 511, make: 19},
  {'label': 'Exeo', 'value': 640, make: 19},
  {'label': 'Ibiza', 'value': 808, make: 19},
  {'label': 'Inca', 'value': 815, make: 19},
  {'label': 'Leon', 'value': 879, make: 19},
  {'label': 'Malaga', 'value': 903, make: 19},
  {'label': 'Marbella', 'value': 906, make: 19},
  {'label': 'Mii', 'value': 1528, make: 19},
  {'label': 'Octavia', 'value': 998, make: 19},
  {'label': 'Tarraco', 'value': 2047, make: 19},
  {'label': 'Terra', 'value': 1298, make: 19},
  {'label': 'Toledo', 'value': 1311, make: 19},
  {'label': '{0}', 'value': 0, make: 21},
  {'label': '250', 'value': 78, make: 21},
  {'label': '270', 'value': 83, make: 21},
  {'label': 'Almera', 'value': 293, make: 21},
  {'label': 'B-Max', 'value': 333, make: 21},
  {'label': 'C-Max', 'value': 392, make: 21},
  {'label': 'Capri', 'value': 419, make: 21},
  {'label': 'Cougar', 'value': 520, make: 21},
  {'label': 'Courier', 'value': 524, make: 21},
  {'label': 'Econoline', 'value': 608, make: 21},
  {'label': 'Econovan', 'value': 609, make: 21},
  {'label': 'EcoSport', 'value': 1663, make: 21},
  {'label': 'Edge', 'value': 610, make: 21},
  {'label': 'Escape', 'value': 626, make: 21},
  {'label': 'Escort', 'value': 627, make: 21},
  {'label': 'Explorer', 'value': 645, make: 21},
  {'label': 'Fiesta', 'value': 662, make: 21},
  {'label': 'Focus', 'value': 673, make: 21},
  {'label': 'Focus C-Max', 'value': 671, make: 21},
  {'label': 'FT 300', 'value': 1607, make: 21},
  {'label': 'Fusion', 'value': 688, make: 21},
  {'label': 'Galaxy', 'value': 707, make: 21},
  {'label': 'Granada', 'value': 753, make: 21},
  {'label': 'Grand C-Max', 'value': 756, make: 21},
  {'label': 'GT', 'value': 1622, make: 21},
  {'label': 'Ka', 'value': 845, make: 21},
  {'label': 'Kuga', 'value': 858, make: 21},
  {'label': 'LS', 'value': 888, make: 21},
  {'label': 'Maverick', 'value': 915, make: 21},
  {'label': 'Mondeo', 'value': 960, make: 21},
  {'label': 'Mustang', 'value': 977, make: 21},
  {'label': 'Orion', 'value': 1003, make: 21},
  {'label': 'Probe', 'value': 1047, make: 21},
  {'label': 'Puma', 'value': 1050, make: 21},
  {'label': 'Ranger', 'value': 1088, make: 21},
  {'label': 'S-Max', 'value': 1141, make: 21},
  {'label': 'Scorpio', 'value': 1173, make: 21},
  {'label': 'Sierra', 'value': 1193, make: 21},
  {'label': 'Sportka', 'value': 1249, make: 21},
  {'label': 'Streetka', 'value': 1264, make: 21},
  {'label': 'Taunus', 'value': 1291, make: 21},
  {'label': 'Taurus', 'value': 1292, make: 21},
  {'label': 'Thunderbird', 'value': 1305, make: 21},
  {'label': 'Tourneo', 'value': 1319, make: 21},
  {'label': 'Tourneo Connect', 'value': 2025, make: 21},
  {'label': 'Tourneo Courier', 'value': 2026, make: 21},
  {'label': 'Tourneo Custom', 'value': 2024, make: 21},
  {'label': 'Transit', 'value': 1327, make: 21},
  {'label': 'Windstar', 'value': 1397, make: 21},
  {'label': 'Allante', 'value': 290, make: 24},
  {'label': 'ATS', 'value': 1904, make: 24},
  {'label': 'CTS', 'value': 539, make: 24},
  {'label': 'CTS-V', 'value': 1884, make: 24},
  {'label': 'Deville', 'value': 566, make: 24},
  {'label': 'Eldorado', 'value': 615, make: 24},
  {'label': 'Escalade', 'value': 625, make: 24},
  {'label': 'Seville', 'value': 1187, make: 24},
  {'label': 'SRX', 'value': 1254, make: 24},
  {'label': 'STS', 'value': 1266, make: 24},
  {'label': 'XLR', 'value': 1431, make: 24},
  {'label': 'Avalanche', 'value': 316, make: 25},
  {'label': 'Aveo', 'value': 322, make: 25},
  {'label': 'Beretta', 'value': 348, make: 25},
  {'label': 'Blazer', 'value': 355, make: 25},
  {'label': 'Camaro', 'value': 414, make: 25},
  {'label': 'Captiva', 'value': 421, make: 25},
  {'label': 'Cavalier', 'value': 429, make: 25},
  {'label': 'Colorado', 'value': 498, make: 25},
  {'label': 'Cruze', 'value': 536, make: 25},
  {'label': 'Epica', 'value': 621, make: 25},
  {'label': 'HHR', 'value': 791, make: 25},
  {'label': 'Impala', 'value': 812, make: 25},
  {'label': 'Kalos', 'value': 848, make: 25},
  {'label': 'Lacetti', 'value': 864, make: 25},
  {'label': 'Malibu', 'value': 904, make: 25},
  {'label': 'Matiz', 'value': 913, make: 25},
  {'label': 'Nubira', 'value': 996, make: 25},
  {'label': 'Orlando', 'value': 1004, make: 25},
  {'label': 'Rezzo', 'value': 1097, make: 25},
  {'label': 'S-10', 'value': 1138, make: 25},
  {'label': 'Silverado', 'value': 1202, make: 25},
  {'label': 'Spark', 'value': 1243, make: 25},
  {'label': 'SSR', 'value': 1255, make: 25},
  {'label': 'Suburban', 'value': 1267, make: 25},
  {'label': 'Tahoe', 'value': 1288, make: 25},
  {'label': 'Trax', 'value': 1584, make: 25},
  {'label': 'Venture', 'value': 1374, make: 25},
  {'label': 'Volt', 'value': 1390, make: 25},
  {'label': 'Firebird', 'value': 664, make: 27},
  {'label': 'G6', 'value': 705, make: 27},
  {'label': 'Grand Am', 'value': 755, make: 27},
  {'label': 'Grand Prix', 'value': 763, make: 27},
  {'label': 'GTO', 'value': 778, make: 27},
  {'label': 'Montana', 'value': 962, make: 27},
  {'label': 'Solstice', 'value': 1236, make: 27},
  {'label': 'Sunfire', 'value': 1269, make: 27},
  {'label': 'Vibe', 'value': 1380, make: 27},
  {'label': 'Accord', 'value': 277, make: 28},
  {'label': 'Aerodeck', 'value': 282, make: 28},
  {'label': 'CB 1100', 'value': 1750, make: 28},
  {'label': 'CB 500 X', 'value': 1745, make: 28},
  {'label': 'Civic', 'value': 453, make: 28},
  {'label': 'Concerto', 'value': 505, make: 28},
  {'label': 'CR-V', 'value': 525, make: 28},
  {'label': 'CR-Z', 'value': 527, make: 28},
  {'label': 'CRF 1000', 'value': 1748, make: 28},
  {'label': 'CRF 250', 'value': 1753, make: 28},
  {'label': 'CRX', 'value': 537, make: 28},
  {'label': 'CTX 1300', 'value': 1752, make: 28},
  {'label': 'FR-V', 'value': 680, make: 28},
  {'label': 'HR-V', 'value': 797, make: 28},
  {'label': 'Insight', 'value': 817, make: 28},
  {'label': 'Integra', 'value': 819, make: 28},
  {'label': 'Jazz', 'value': 830, make: 28},
  {'label': 'Legend', 'value': 877, make: 28},
  {'label': 'Logo', 'value': 887, make: 28},
  {'label': 'NSX', 'value': 995, make: 28},
  {'label': 'NT 700', 'value': 1751, make: 28},
  {'label': 'Odyssey', 'value': 999, make: 28},
  {'label': 'Prelude', 'value': 1037, make: 28},
  {'label': 'Primera', 'value': 1042, make: 28},
  {'label': 'S2000', 'value': 1146, make: 28},
  {'label': 'Stream', 'value': 1263, make: 28},
  {'label': 'VFR 1200', 'value': 1716, make: 28},
  {'label': 'VFR 800', 'value': 1717, make: 28},
  {'label': 'VT 750', 'value': 1744, make: 28},
  {'label': 'XL 1000', 'value': 1954, make: 28},
  {'label': '110', 'value': 9, make: 29},
  {'label': '111', 'value': 10, make: 29},
  {'label': '112', 'value': 11, make: 29},
  {'label': '1200', 'value': 17, make: 29},
  {'label': '2110', 'value': 60, make: 29},
  {'label': '2111', 'value': 61, make: 29},
  {'label': '2112', 'value': 62, make: 29},
  {'label': 'Aleko', 'value': 285, make: 29},
  {'label': 'Forma', 'value': 676, make: 29},
  {'label': 'Granta', 'value': 1901, make: 29},
  {'label': 'Kalina', 'value': 847, make: 29},
  {'label': 'Niva', 'value': 991, make: 29},
  {'label': 'Nova', 'value': 993, make: 29},
  {'label': 'Priora', 'value': 1043, make: 29},
  {'label': 'Samara', 'value': 1158, make: 29},
  {'label': 'Taiga', 'value': 1710, make: 29},
  {'label': 'Urban', 'value': 1906, make: 29},
  {'label': 'Vesta', 'value': 1902, make: 29},
  {'label': '164', 'value': 35, make: 30},
  {'label': '33', 'value': 118, make: 30},
  {'label': '75', 'value': 214, make: 30},
  {'label': 'Dedra', 'value': 561, make: 30},
  {'label': 'Delta', 'value': 563, make: 30},
  {'label': 'Kappa', 'value': 850, make: 30},
  {'label': 'Lybra', 'value': 893, make: 30},
  {'label': 'MUSA', 'value': 975, make: 30},
  {'label': 'Phedra', 'value': 1025, make: 30},
  {'label': 'Prisma', 'value': 1044, make: 30},
  {'label': 'Scenic', 'value': 1168, make: 30},
  {'label': 'Thema', 'value': 1303, make: 30},
  {'label': 'Thesis', 'value': 1304, make: 30},
  {'label': 'Voyager', 'value': 1392, make: 30},
  {'label': 'Ypsilon', 'value': 1438, make: 30},
  {'label': 'Zeta', 'value': 1447, make: 30},
  {'label': 'Defender', 'value': 562, make: 31},
  {'label': 'Discovery', 'value': 572, make: 31},
  {'label': 'Discovery Sport', 'value': 2009, make: 31},
  {'label': 'Freelander', 'value': 683, make: 31},
  {'label': 'Range Rover', 'value': 1087, make: 31},
  {'label': 'Range Rover Evoque', 'value': 1085, make: 31},
  {'label': 'Range Rover Sport', 'value': 1086, make: 31},
  {'label': 'Range Rover Velar', 'value': 2008, make: 31},
  {'label': 'Daimler', 'value': 554, make: 33},
  {'label': 'E-Pace', 'value': 1987, make: 33},
  {'label': 'F-Pace', 'value': 1988, make: 33},
  {'label': 'F-Type', 'value': 1523, make: 33},
  {'label': 'S-Type', 'value': 1143, make: 33},
  {'label': 'X-Type', 'value': 1406, make: 33},
  {'label': 'XE', 'value': 1997, make: 33},
  {'label': 'XF', 'value': 1418, make: 33},
  {'label': 'XJ', 'value': 1421, make: 33},
  {'label': 'XJ12', 'value': 1422, make: 33},
  {'label': 'XJ6', 'value': 1424, make: 33},
  {'label': 'XJ8', 'value': 1425, make: 33},
  {'label': 'XJR', 'value': 1426, make: 33},
  {'label': 'XJS', 'value': 1427, make: 33},
  {'label': 'XJSC', 'value': 1428, make: 33},
  {'label': 'XK', 'value': 1429, make: 33},
  {'label': 'XKR', 'value': 1430, make: 33},
  {'label': '{786}', 'value': 786, make: 34},
  {'label': 'Accent', 'value': 276, make: 34},
  {'label': 'Atos', 'value': 314, make: 34},
  {'label': 'Coupe', 'value': 1757, make: 34},
  {'label': 'Elantra', 'value': 614, make: 34},
  {'label': 'Galloper', 'value': 709, make: 34},
  {'label': 'Genesis', 'value': 712, make: 34},
  {'label': 'Getz', 'value': 713, make: 34},
  {'label': 'Grandeur', 'value': 769, make: 34},
  {'label': 'H 100', 'value': 782, make: 34},
  {'label': 'H-1', 'value': 788, make: 34},
  {'label': 'H-1 Starex', 'value': 784, make: 34},
  {'label': 'H350', 'value': 2037, make: 34},
  {'label': 'i10', 'value': 801, make: 34},
  {'label': 'i20', 'value': 802, make: 34},
  {'label': 'i30', 'value': 803, make: 34},
  {'label': 'i30cw', 'value': 804, make: 34},
  {'label': 'i40', 'value': 805, make: 34},
  {'label': 'i40cw', 'value': 806, make: 34},
  {'label': 'IONIQ', 'value': 1793, make: 34},
  {'label': 'ix20', 'value': 825, make: 34},
  {'label': 'ix35', 'value': 826, make: 34},
  {'label': 'ix55', 'value': 827, make: 34},
  {'label': 'Kona', 'value': 1971, make: 34},
  {'label': 'Lantra', 'value': 870, make: 34},
  {'label': 'Matrix', 'value': 914, make: 34},
  {'label': 'Pony', 'value': 1033, make: 34},
  {'label': 'Santa Fe', 'value': 1160, make: 34},
  {'label': 'Santamo', 'value': 1161, make: 34},
  {'label': 'Sonata', 'value': 1237, make: 34},
  {'label': 'Terracan', 'value': 1299, make: 34},
  {'label': 'Trajet', 'value': 1324, make: 34},
  {'label': 'Tucson', 'value': 1338, make: 34},
  {'label': 'Veloster', 'value': 1370, make: 34},
  {'label': 'XG 350', 'value': 1420, make: 34},
  {'label': '111', 'value': 10, make: 35},
  {'label': '114', 'value': 12, make: 35},
  {'label': '200', 'value': 43, make: 35},
  {'label': '213', 'value': 63, make: 35},
  {'label': '214', 'value': 64, make: 35},
  {'label': '216', 'value': 65, make: 35},
  {'label': '25', 'value': 77, make: 35},
  {'label': '414', 'value': 144, make: 35},
  {'label': '416', 'value': 145, make: 35},
  {'label': '420', 'value': 147, make: 35},
  {'label': '45', 'value': 153, make: 35},
  {'label': '600', 'value': 188, make: 35},
  {'label': '618', 'value': 193, make: 35},
  {'label': '620', 'value': 195, make: 35},
  {'label': '623', 'value': 196, make: 35},
  {'label': '75', 'value': 214, make: 35},
  {'label': '820', 'value': 223, make: 35},
  {'label': '825', 'value': 224, make: 35},
  {'label': '827', 'value': 225, make: 35},
  {'label': 'City Rover', 'value': 452, make: 35},
  {'label': 'Defender', 'value': 562, make: 35},
  {'label': 'Discovery', 'value': 572, make: 35},
  {'label': 'Freelander', 'value': 683, make: 35},
  {'label': 'Metro', 'value': 927, make: 35},
  {'label': 'MGF', 'value': 937, make: 35},
  {'label': 'Mini', 'value': 942, make: 35},
  {'label': 'Montego', 'value': 963, make: 35},
  {'label': 'Streetwise', 'value': 1265, make: 35},
  {'label': '121', 'value': 18, make: 37},
  {'label': '2', 'value': 41, make: 37},
  {'label': '3', 'value': 90, make: 37},
  {'label': '323', 'value': 114, make: 37},
  {'label': '5', 'value': 160, make: 37},
  {'label': '6', 'value': 1464, make: 37},
  {'label': '62', 'value': 194, make: 37},
  {'label': '626', 'value': 197, make: 37},
  {'label': '929', 'value': 247, make: 37},
  {'label': 'BT-50', 'value': 369, make: 37},
  {'label': 'CX-3', 'value': 543, make: 37},
  {'label': 'CX-5', 'value': 1483, make: 37},
  {'label': 'CX-7', 'value': 545, make: 37},
  {'label': 'CX-9', 'value': 547, make: 37},
  {'label': 'Demio', 'value': 564, make: 37},
  {'label': 'E series', 'value': 603, make: 37},
  {'label': 'Econovan', 'value': 609, make: 37},
  {'label': 'MPV', 'value': 969, make: 37},
  {'label': 'MX-3', 'value': 978, make: 37},
  {'label': 'MX-5', 'value': 980, make: 37},
  {'label': 'MX-6', 'value': 982, make: 37},
  {'label': 'Premacy', 'value': 1038, make: 37},
  {'label': 'Q50', 'value': 1681, make: 37},
  {'label': 'RX-7', 'value': 1115, make: 37},
  {'label': 'RX-8', 'value': 1117, make: 37},
  {'label': 'Tribute', 'value': 1333, make: 37},
  {'label': 'Xedos', 'value': 1417, make: 37},
  {'label': '115', 'value': 13, make: 38},
  {'label': '170S', 'value': 1654, make: 38},
  {'label': '190', 'value': 38, make: 38},
  {'label': '200', 'value': 43, make: 38},
  {'label': '230', 'value': 71, make: 38},
  {'label': '240', 'value': 73, make: 38},
  {'label': '245', 'value': 75, make: 38},
  {'label': '250', 'value': 78, make: 38},
  {'label': '260', 'value': 80, make: 38},
  {'label': '280', 'value': 86, make: 38},
  {'label': '300', 'value': 93, make: 38},
  {'label': '350', 'value': 124, make: 38},
  {'label': 'A 140', 'value': 258, make: 38},
  {'label': 'A 150', 'value': 259, make: 38},
  {'label': 'A 160', 'value': 260, make: 38},
  {'label': 'A 170', 'value': 261, make: 38},
  {'label': 'A 180', 'value': 262, make: 38},
  {'label': 'A 190', 'value': 263, make: 38},
  {'label': 'A 200', 'value': 264, make: 38},
  {'label': 'A 210', 'value': 265, make: 38},
  {'label': 'A 220', 'value': 1646, make: 38},
  {'label': 'A 250', 'value': 1543, make: 38},
  {'label': 'A 45 AMG', 'value': 1800, make: 38},
  {'label': 'Almera Tino', 'value': 292, make: 38},
  {'label': 'AMG GT S', 'value': 1768, make: 38},
  {'label': 'B 150', 'value': 327, make: 38},
  {'label': 'B 160', 'value': 328, make: 38},
  {'label': 'B 170', 'value': 329, make: 38},
  {'label': 'B 180', 'value': 330, make: 38},
  {'label': 'B 200', 'value': 331, make: 38},
  {'label': 'B 220', 'value': 1899, make: 38},
  {'label': 'B 250', 'value': 1581, make: 38},
  {'label': 'C 160', 'value': 374, make: 38},
  {'label': 'C 180', 'value': 375, make: 38},
  {'label': 'C 200', 'value': 376, make: 38},
  {'label': 'C 220', 'value': 377, make: 38},
  {'label': 'C 230', 'value': 378, make: 38},
  {'label': 'C 240', 'value': 379, make: 38},
  {'label': 'C 250', 'value': 380, make: 38},
  {'label': 'C 270', 'value': 381, make: 38},
  {'label': 'C 280', 'value': 382, make: 38},
  {'label': 'C 300', 'value': 1545, make: 38},
  {'label': 'C 320', 'value': 384, make: 38},
  {'label': 'C 350', 'value': 385, make: 38},
  {'label': 'C 36 AMG', 'value': 386, make: 38},
  {'label': 'C 400', 'value': 1905, make: 38},
  {'label': 'C 43 AMG', 'value': 387, make: 38},
  {'label': 'C 55 AMG', 'value': 388, make: 38},
  {'label': 'C 63 AMG', 'value': 389, make: 38},
  {'label': 'C30', 'value': 401, make: 38},
  {'label': 'CE 220', 'value': 434, make: 38},
  {'label': 'CE 300', 'value': 435, make: 38},
  {'label': 'Citan', 'value': 1766, make: 38},
  {'label': 'CL 180', 'value': 456, make: 38},
  {'label': 'CL 200', 'value': 457, make: 38},
  {'label': 'CL 220', 'value': 458, make: 38},
  {'label': 'CL 230', 'value': 459, make: 38},
  {'label': 'CL 500', 'value': 462, make: 38},
  {'label': 'CL 600', 'value': 464, make: 38},
  {'label': 'CL 63 AMG', 'value': 465, make: 38},
  {'label': 'CL 65 AMG', 'value': 466, make: 38},
  {'label': 'CLA 180', 'value': 1546, make: 38},
  {'label': 'CLA 180 Shooting Brake', 'value': 1807, make: 38},
  {'label': 'CLA 200', 'value': 1812, make: 38},
  {'label': 'CLA 200 Shooting Brake', 'value': 1808, make: 38},
  {'label': 'CLA 220', 'value': 1813, make: 38},
  {'label': 'CLA 220 Shooting Brake', 'value': 1809, make: 38},
  {'label': 'CLA 250', 'value': 1814, make: 38},
  {'label': 'CLA 250 Shooting Brake', 'value': 1810, make: 38},
  {'label': 'CLA 45 AMG', 'value': 1815, make: 38},
  {'label': 'CLA 45 AMG Shooting Brake', 'value': 1811, make: 38},
  {'label': 'CLA Shooting Brake', 'value': 1563, make: 38},
  {'label': 'CLC 160', 'value': 468, make: 38},
  {'label': 'CLC 180', 'value': 469, make: 38},
  {'label': 'CLC 200', 'value': 470, make: 38},
  {'label': 'CLC 220', 'value': 471, make: 38},
  {'label': 'CLC 230', 'value': 472, make: 38},
  {'label': 'CLC 250', 'value': 473, make: 38},
  {'label': 'CLC 350', 'value': 474, make: 38},
  {'label': 'CLK 200', 'value': 477, make: 38},
  {'label': 'CLK 220', 'value': 478, make: 38},
  {'label': 'CLK 230', 'value': 479, make: 38},
  {'label': 'CLK 240', 'value': 480, make: 38},
  {'label': 'CLK 270', 'value': 481, make: 38},
  {'label': 'CLK 280', 'value': 482, make: 38},
  {'label': 'CLK 320', 'value': 483, make: 38},
  {'label': 'CLK 350', 'value': 484, make: 38},
  {'label': 'CLK 430', 'value': 485, make: 38},
  {'label': 'CLK 500', 'value': 486, make: 38},
  {'label': 'CLK 63 AMG', 'value': 488, make: 38},
  {'label': 'CLS 250', 'value': 489, make: 38},
  {'label': 'CLS 280', 'value': 490, make: 38},
  {'label': 'CLS 320', 'value': 492, make: 38},
  {'label': 'CLS 350', 'value': 493, make: 38},
  {'label': 'CLS 500', 'value': 494, make: 38},
  {'label': 'CLS 55 AMG', 'value': 495, make: 38},
  {'label': 'CLS 63 AMG', 'value': 496, make: 38},
  {'label': 'E 200', 'value': 582, make: 38},
  {'label': 'E 220', 'value': 583, make: 38},
  {'label': 'E 230', 'value': 584, make: 38},
  {'label': 'E 240', 'value': 585, make: 38},
  {'label': 'E 250', 'value': 586, make: 38},
  {'label': 'E 260', 'value': 587, make: 38},
  {'label': 'E 270', 'value': 588, make: 38},
  {'label': 'E 280', 'value': 589, make: 38},
  {'label': 'E 290', 'value': 590, make: 38},
  {'label': 'E 300', 'value': 591, make: 38},
  {'label': 'E 300', 'value': 1593, make: 38},
  {'label': 'E 320', 'value': 592, make: 38},
  {'label': 'E 350', 'value': 593, make: 38},
  {'label': 'E 400', 'value': 595, make: 38},
  {'label': 'E 420', 'value': 596, make: 38},
  {'label': 'E 430', 'value': 597, make: 38},
  {'label': 'E 500', 'value': 599, make: 38},
  {'label': 'E 60 AMG', 'value': 601, make: 38},
  {'label': 'E 63 AMG', 'value': 602, make: 38},
  {'label': 'G', 'value': 702, make: 38},
  {'label': 'G 230', 'value': 690, make: 38},
  {'label': 'G 240', 'value': 691, make: 38},
  {'label': 'G 250', 'value': 692, make: 38},
  {'label': 'G 270', 'value': 693, make: 38},
  {'label': 'G 280', 'value': 694, make: 38},
  {'label': 'G 290', 'value': 695, make: 38},
  {'label': 'G 300', 'value': 696, make: 38},
  {'label': 'G 320', 'value': 697, make: 38},
  {'label': 'G 350', 'value': 698, make: 38},
  {'label': 'G 400', 'value': 699, make: 38},
  {'label': 'G 500', 'value': 700, make: 38},
  {'label': 'GL 320', 'value': 718, make: 38},
  {'label': 'GL 350', 'value': 719, make: 38},
  {'label': 'GL 420', 'value': 720, make: 38},
  {'label': 'GL 450', 'value': 721, make: 38},
  {'label': 'GL 500', 'value': 722, make: 38},
  {'label': 'GL 55 AMG', 'value': 723, make: 38},
  {'label': 'GL 63 AMG', 'value': 724, make: 38},
  {'label': 'GLA 180', 'value': 1968, make: 38},
  {'label': 'GLA 200', 'value': 1666, make: 38},
  {'label': 'GLA 220', 'value': 67, make: 38},
  {'label': 'GLA 250', 'value': 1969, make: 38},
  {'label': 'GLA 45 AMG', 'value': 1970, make: 38},
  {'label': 'GLC 220', 'value': 2002, make: 38},
  {'label': 'GLC 250', 'value': 2003, make: 38},
  {'label': 'GLC 300', 'value': 2004, make: 38},
  {'label': 'GLC 350', 'value': 2005, make: 38},
  {'label': 'GLC 43 AMG', 'value': 2006, make: 38},
  {'label': 'GLC 63 AMG', 'value': 2007, make: 38},
  {'label': 'GLK 200', 'value': 725, make: 38},
  {'label': 'GLK 220', 'value': 726, make: 38},
  {'label': 'GLK 250', 'value': 727, make: 38},
  {'label': 'GLK 280', 'value': 728, make: 38},
  {'label': 'GLK 300', 'value': 729, make: 38},
  {'label': 'GLK 320', 'value': 730, make: 38},
  {'label': 'GLK 350', 'value': 731, make: 38},
  {'label': 'GLS 350', 'value': 1964, make: 38},
  {'label': 'GLS 400', 'value': 1965, make: 38},
  {'label': 'GLS 500', 'value': 1966, make: 38},
  {'label': 'GLS 63', 'value': 1963, make: 38},
  {'label': 'MB 100', 'value': 921, make: 38},
  {'label': 'ML 230', 'value': 946, make: 38},
  {'label': 'ML 250', 'value': 1547, make: 38},
  {'label': 'ML 270', 'value': 947, make: 38},
  {'label': 'ML 280', 'value': 948, make: 38},
  {'label': 'ML 300', 'value': 949, make: 38},
  {'label': 'ML 320', 'value': 950, make: 38},
  {'label': 'ML 350', 'value': 951, make: 38},
  {'label': 'ML 400', 'value': 952, make: 38},
  {'label': 'ML 420', 'value': 953, make: 38},
  {'label': 'ML 430', 'value': 954, make: 38},
  {'label': 'ML 450', 'value': 955, make: 38},
  {'label': 'ML 500', 'value': 956, make: 38},
  {'label': 'ML 63 AMG', 'value': 958, make: 38},
  {'label': 'Punto', 'value': 1052, make: 38},
  {'label': 'R 280', 'value': 1072, make: 38},
  {'label': 'R 300', 'value': 1074, make: 38},
  {'label': 'R 320', 'value': 1075, make: 38},
  {'label': 'R 350', 'value': 1076, make: 38},
  {'label': 'R 500', 'value': 1079, make: 38},
  {'label': 'R 63 AMG', 'value': 1081, make: 38},
  {'label': 'S 250', 'value': 1122, make: 38},
  {'label': 'S 260', 'value': 1123, make: 38},
  {'label': 'S 280', 'value': 1124, make: 38},
  {'label': 'S 300', 'value': 1125, make: 38},
  {'label': 'S 320', 'value': 1126, make: 38},
  {'label': 'S 350', 'value': 1127, make: 38},
  {'label': 'S 400', 'value': 1128, make: 38},
  {'label': 'S 420', 'value': 1129, make: 38},
  {'label': 'S 430', 'value': 1130, make: 38},
  {'label': 'S 450', 'value': 1131, make: 38},
  {'label': 'S 500', 'value': 1132, make: 38},
  {'label': 'S 55', 'value': 1133, make: 38},
  {'label': 'S 550', 'value': 1134, make: 38},
  {'label': 'S 600', 'value': 1135, make: 38},
  {'label': 'S 63 AMG', 'value': 1136, make: 38},
  {'label': 'S 65 AMG', 'value': 1137, make: 38},
  {'label': 'SL 280', 'value': 1208, make: 38},
  {'label': 'SL 300', 'value': 1209, make: 38},
  {'label': 'SL 320', 'value': 1210, make: 38},
  {'label': 'SL 350', 'value': 1211, make: 38},
  {'label': 'SL 380', 'value': 1212, make: 38},
  {'label': 'SL 420', 'value': 1213, make: 38},
  {'label': 'SL 450', 'value': 1214, make: 38},
  {'label': 'SL 500', 'value': 1215, make: 38},
  {'label': 'SL 600', 'value': 1219, make: 38},
  {'label': 'SL 63 AMG', 'value': 1220, make: 38},
  {'label': 'SL 65 AMG', 'value': 1221, make: 38},
  {'label': 'SL 70 AMG', 'value': 1222, make: 38},
  {'label': 'SLK 200', 'value': 1224, make: 38},
  {'label': 'SLK 230', 'value': 1225, make: 38},
  {'label': 'SLK 250', 'value': 1226, make: 38},
  {'label': 'SLK 280', 'value': 1227, make: 38},
  {'label': 'SLK 300', 'value': 1228, make: 38},
  {'label': 'SLK 32 AMG', 'value': 1229, make: 38},
  {'label': 'SLK 320', 'value': 1230, make: 38},
  {'label': 'SLK 350', 'value': 1231, make: 38},
  {'label': 'SLK 55 AMG', 'value': 1232, make: 38},
  {'label': 'SLR', 'value': 1233, make: 38},
  {'label': 'SLS AMG', 'value': 1234, make: 38},
  {'label': 'Sprinter', 'value': 1252, make: 38},
  {'label': 'Unimog', 'value': 1588, make: 38},
  {'label': 'V 200', 'value': 1350, make: 38},
  {'label': 'V 220', 'value': 1351, make: 38},
  {'label': 'V 230', 'value': 1352, make: 38},
  {'label': 'V 250', 'value': 1722, make: 38},
  {'label': 'V 280', 'value': 1353, make: 38},
  {'label': 'Vaneo', 'value': 1363, make: 38},
  {'label': 'Vario', 'value': 1367, make: 38},
  {'label': 'Viano', 'value': 1379, make: 38},
  {'label': 'Vito', 'value': 1386, make: 38},
  {'label': 'X 220', 'value': 2043, make: 38},
  {'label': 'X 250', 'value': 2042, make: 38},
  {'label': '{0}', 'value': 0, make: 39},
  {'label': '3000 GT', 'value': 94, make: 39},
  {'label': 'ASX', 'value': 313, make: 39},
  {'label': 'Carisma', 'value': 425, make: 39},
  {'label': 'Colt', 'value': 499, make: 39},
  {'label': 'Eclipse', 'value': 607, make: 39},
  {'label': 'Eclipse Cross', 'value': 2027, make: 39},
  {'label': 'Galant', 'value': 706, make: 39},
  {'label': 'Grandis', 'value': 770, make: 39},
  {'label': 'i-MiEV', 'value': 799, make: 39},
  {'label': 'L200', 'value': 861, make: 39},
  {'label': 'L300', 'value': 862, make: 39},
  {'label': 'Lancer', 'value': 867, make: 39},
  {'label': 'Outlander', 'value': 1005, make: 39},
  {'label': 'Pajero', 'value': 1009, make: 39},
  {'label': 'Pajero Pinin', 'value': 1008, make: 39},
  {'label': 'Santamo', 'value': 1161, make: 39},
  {'label': 'Sapporo', 'value': 1163, make: 39},
  {'label': 'Sigma', 'value': 1194, make: 39},
  {'label': 'Space Gear', 'value': 1240, make: 39},
  {'label': 'Space Runner', 'value': 1241, make: 39},
  {'label': 'Space Star', 'value': 1662, make: 39},
  {'label': 'Space Wagon', 'value': 1242, make: 39},
  {'label': 'Starion', 'value': 1256, make: 39},
  {'label': 'Tredia', 'value': 1329, make: 39},
  {'label': '200 SX', 'value': 42, make: 40},
  {'label': '300 ZX', 'value': 92, make: 40},
  {'label': '350', 'value': 124, make: 40},
  {'label': '350Z', 'value': 125, make: 40},
  {'label': '360', 'value': 127, make: 40},
  {'label': '370Z', 'value': 129, make: 40},
  {'label': 'Almera', 'value': 293, make: 40},
  {'label': 'Almera Tino', 'value': 292, make: 40},
  {'label': 'Black Edition', 'value': 1816, make: 40},
  {'label': 'Bluebird', 'value': 357, make: 40},
  {'label': 'Cabstar', 'value': 408, make: 40},
  {'label': 'Colt', 'value': 499, make: 40},
  {'label': 'Cube', 'value': 540, make: 40},
  {'label': 'Duster', 'value': 580, make: 40},
  {'label': 'e-NV200', 'value': 1632, make: 40},
  {'label': 'Evalia', 'value': 1634, make: 40},
  {'label': 'GT-R', 'value': 776, make: 40},
  {'label': 'Interstar', 'value': 820, make: 40},
  {'label': 'Juke', 'value': 836, make: 40},
  {'label': 'King Cab', 'value': 853, make: 40},
  {'label': 'Kubistar', 'value': 857, make: 40},
  {'label': 'Laurel', 'value': 872, make: 40},
  {'label': 'Leaf', 'value': 874, make: 40},
  {'label': 'Maverick', 'value': 915, make: 40},
  {'label': 'Maxima', 'value': 916, make: 40},
  {'label': 'Micra', 'value': 938, make: 40},
  {'label': 'Murano', 'value': 973, make: 40},
  {'label': 'Navara', 'value': 984, make: 40},
  {'label': 'Note', 'value': 992, make: 40},
  {'label': 'NP 300', 'value': 994, make: 40},
  {'label': 'NT400', 'value': 1665, make: 40},
  {'label': 'NV200', 'value': 997, make: 40},
  {'label': 'NV300', 'value': 1802, make: 40},
  {'label': 'NV400', 'value': 1629, make: 40},
  {'label': 'Pathfinder', 'value': 1020, make: 40},
  {'label': 'Patrol', 'value': 1022, make: 40},
  {'label': 'Pixo', 'value': 1028, make: 40},
  {'label': 'Prairie', 'value': 1034, make: 40},
  {'label': 'Primastar', 'value': 1041, make: 40},
  {'label': 'Primera', 'value': 1042, make: 40},
  {'label': 'Pulsar', 'value': 1620, make: 40},
  {'label': 'Qashqai', 'value': 1057, make: 40},
  {'label': 'Qashqai+2', 'value': 1058, make: 40},
  {'label': 'Quest', 'value': 1062, make: 40},
  {'label': 'Sentra', 'value': 1180, make: 40},
  {'label': 'Serena', 'value': 1183, make: 40},
  {'label': 'Silvia', 'value': 1203, make: 40},
  {'label': 'Skyline', 'value': 1207, make: 40},
  {'label': 'Sunny', 'value': 1270, make: 40},
  {'label': 'Terrano', 'value': 1300, make: 40},
  {'label': 'Tiida', 'value': 1308, make: 40},
  {'label': 'Trade', 'value': 1321, make: 40},
  {'label': 'Urvan', 'value': 1349, make: 40},
  {'label': 'Vanette', 'value': 1364, make: 40},
  {'label': 'X-Trail', 'value': 1404, make: 40},
  {'label': '{0}', 'value': 0, make: 41},
  {'label': 'Adam', 'value': 1612, make: 41},
  {'label': 'Agila', 'value': 284, make: 41},
  {'label': 'Ampera', 'value': 303, make: 41},
  {'label': 'Antara', 'value': 304, make: 41},
  {'label': 'Arena', 'value': 307, make: 41},
  {'label': 'Ascona', 'value': 309, make: 41},
  {'label': 'Astra', 'value': 311, make: 41},
  {'label': 'Calibra', 'value': 412, make: 41},
  {'label': 'Campo', 'value': 415, make: 41},
  {'label': 'Cascada', 'value': 1652, make: 41},
  {'label': 'Combo', 'value': 501, make: 41},
  {'label': 'Corsa', 'value': 516, make: 41},
  {'label': 'Crossland X', 'value': 1956, make: 41},
  {'label': 'Frontera', 'value': 684, make: 41},
  {'label': 'Grandland X', 'value': 1986, make: 41},
  {'label': 'GT', 'value': 1622, make: 41},
  {'label': 'Insignia', 'value': 818, make: 41},
  {'label': 'Kadett', 'value': 846, make: 41},
  {'label': 'Karl', 'value': 1723, make: 41},
  {'label': 'Manta', 'value': 905, make: 41},
  {'label': 'Meriva', 'value': 926, make: 41},
  {'label': 'Mokka', 'value': 1648, make: 41},
  {'label': 'Monterey', 'value': 964, make: 41},
  {'label': 'Monza', 'value': 966, make: 41},
  {'label': 'Movano', 'value': 967, make: 41},
  {'label': 'Omega', 'value': 1000, make: 41},
  {'label': 'Senator', 'value': 1179, make: 41},
  {'label': 'Signum', 'value': 1195, make: 41},
  {'label': 'Sintra', 'value': 1204, make: 41},
  {'label': 'Speedster', 'value': 1244, make: 41},
  {'label': 'Tigra', 'value': 1306, make: 41},
  {'label': 'Touran', 'value': 1316, make: 41},
  {'label': 'Trans Sport', 'value': 1326, make: 41},
  {'label': 'Vectra', 'value': 1368, make: 41},
  {'label': 'Vivaro', 'value': 1387, make: 41},
  {'label': 'Zafira', 'value': 1446, make: 41},
  {'label': 'Zafira Tourer', 'value': 1883, make: 41},
  {'label': '1007', 'value': 4, make: 42},
  {'label': '106', 'value': 7, make: 42},
  {'label': '107', 'value': 8, make: 42},
  {'label': '108', 'value': 1621, make: 42},
  {'label': '2008', 'value': 1471, make: 42},
  {'label': '205', 'value': 45, make: 42},
  {'label': '206', 'value': 46, make: 42},
  {'label': '206 +', 'value': 48, make: 42},
  {'label': '206 CC', 'value': 53, make: 42},
  {'label': '206 Plus', 'value': 49, make: 42},
  {'label': '206+', 'value': 47, make: 42},
  {'label': '207', 'value': 51, make: 42},
  {'label': '208', 'value': 52, make: 42},
  {'label': '3008', 'value': 95, make: 42},
  {'label': '301', 'value': 1560, make: 42},
  {'label': '304', 'value': 97, make: 42},
  {'label': '305', 'value': 98, make: 42},
  {'label': '306', 'value': 99, make: 42},
  {'label': '307', 'value': 100, make: 42},
  {'label': '307 CC', 'value': 103, make: 42},
  {'label': '308', 'value': 101, make: 42},
  {'label': '308 CC', 'value': 104, make: 42},
  {'label': '309', 'value': 108, make: 42},
  {'label': '4007', 'value': 137, make: 42},
  {'label': '4008', 'value': 138, make: 42},
  {'label': '405', 'value': 140, make: 42},
  {'label': '406', 'value': 141, make: 42},
  {'label': '407', 'value': 142, make: 42},
  {'label': '5008', 'value': 163, make: 42},
  {'label': '505', 'value': 165, make: 42},
  {'label': '508', 'value': 166, make: 42},
  {'label': '605', 'value': 191, make: 42},
  {'label': '607', 'value': 192, make: 42},
  {'label': '806', 'value': 221, make: 42},
  {'label': '807', 'value': 222, make: 42},
  {'label': 'Bipper', 'value': 353, make: 42},
  {'label': 'Bipper Tepee', 'value': 352, make: 42},
  {'label': 'Boxer', 'value': 362, make: 42},
  {'label': 'C5', 'value': 404, make: 42},
  {'label': 'Expert', 'value': 644, make: 42},
  {'label': 'Expert Tepee', 'value': 643, make: 42},
  {'label': 'I-ON', 'value': 1658, make: 42},
  {'label': 'iOn', 'value': 821, make: 42},
  {'label': 'J5', 'value': 828, make: 42},
  {'label': 'Partner', 'value': 1015, make: 42},
  {'label': 'Partner Tepee', 'value': 1014, make: 42},
  {'label': 'RCZ', 'value': 1092, make: 42},
  {'label': 'Traveller', 'value': 2020, make: 42},
  {'label': '356', 'value': 126, make: 43},
  {'label': '911', 'value': 242, make: 43},
  {'label': '918', 'value': 2028, make: 43},
  {'label': '924', 'value': 245, make: 43},
  {'label': '928', 'value': 246, make: 43},
  {'label': '930', 'value': 1872, make: 43},
  {'label': '944', 'value': 249, make: 43},
  {'label': '964', 'value': 1873, make: 43},
  {'label': '968', 'value': 256, make: 43},
  {'label': '991', 'value': 1874, make: 43},
  {'label': '993', 'value': 1875, make: 43},
  {'label': '993 Targa', 'value': 1642, make: 43},
  {'label': '996', 'value': 1876, make: 43},
  {'label': '997', 'value': 1877, make: 43},
  {'label': 'Boxster', 'value': 363, make: 43},
  {'label': 'Carrera GT', 'value': 427, make: 43},
  {'label': 'Cayenne', 'value': 430, make: 43},
  {'label': 'Cayman', 'value': 431, make: 43},
  {'label': 'Macan', 'value': 1703, make: 43},
  {'label': 'Panamera', 'value': 1011, make: 43},
  {'label': 'Targa', 'value': 1289, make: 43},
  {'label': 'Alaskan', 'value': 1975, make: 44},
  {'label': 'Avantime', 'value': 318, make: 44},
  {'label': 'Captur', 'value': 1647, make: 44},
  {'label': 'Clio', 'value': 476, make: 44},
  {'label': 'Duster', 'value': 580, make: 44},
  {'label': 'Espace', 'value': 628, make: 44},
  {'label': 'Fluence', 'value': 669, make: 44},
  {'label': 'Fuego', 'value': 686, make: 44},
  {'label': 'G', 'value': 702, make: 44},
  {'label': 'Grand Espace', 'value': 761, make: 44},
  {'label': 'Grand Modus', 'value': 762, make: 44},
  {'label': 'Grand Scenic', 'value': 764, make: 44},
  {'label': 'Kadjar', 'value': 1714, make: 44},
  {'label': 'Kangoo', 'value': 849, make: 44},
  {'label': 'KANGOO Rapid', 'value': 1090, make: 44},
  {'label': 'Koleos', 'value': 855, make: 44},
  {'label': 'Laguna', 'value': 866, make: 44},
  {'label': 'Latitude', 'value': 871, make: 44},
  {'label': 'Logan', 'value': 886, make: 44},
  {'label': 'Mascott', 'value': 909, make: 44},
  {'label': 'Master', 'value': 911, make: 44},
  {'label': 'Megane', 'value': 923, make: 44},
  {'label': 'Modus', 'value': 959, make: 44},
  {'label': 'Safrane', 'value': 1157, make: 44},
  {'label': 'Scenic', 'value': 1168, make: 44},
  {'label': 'Spider Sport', 'value': 2001, make: 44},
  {'label': 'Talisman', 'value': 1777, make: 44},
  {'label': 'Trafic', 'value': 1322, make: 44},
  {'label': 'Twingo', 'value': 1343, make: 44},
  {'label': 'Twizy', 'value': 1650, make: 44},
  {'label': 'Vel Satis', 'value': 1369, make: 44},
  {'label': 'Wind', 'value': 1396, make: 44},
  {'label': 'ZOE', 'value': 1649, make: 44},
  {'label': '9-3', 'value': 231, make: 45},
  {'label': '9-4X', 'value': 233, make: 45},
  {'label': '9-5', 'value': 235, make: 45},
  {'label': '9-7X', 'value': 237, make: 45},
  {'label': '900', 'value': 240, make: 45},
  {'label': '9000', 'value': 241, make: 45},
  {'label': '99', 'value': 257, make: 45},
  {'label': '105', 'value': 6, make: 46},
  {'label': 'Citigo', 'value': 1489, make: 46},
  {'label': 'Fabia', 'value': 654, make: 46},
  {'label': 'Favorit', 'value': 658, make: 46},
  {'label': 'Felicia', 'value': 659, make: 46},
  {'label': 'Forman', 'value': 677, make: 46},
  {'label': 'Karoq', 'value': 1900, make: 46},
  {'label': 'Kodiaq', 'value': 1779, make: 46},
  {'label': 'Octavia', 'value': 998, make: 46},
  {'label': 'Praktik', 'value': 1035, make: 46},
  {'label': 'Rapid', 'value': 1089, make: 46},
  {'label': 'Rapid Spaceback', 'value': 1903, make: 46},
  {'label': 'Roomster', 'value': 1105, make: 46},
  {'label': 'Scala', 'value': 2049, make: 46},
  {'label': 'Superb', 'value': 1275, make: 46},
  {'label': 'Yeti', 'value': 1437, make: 46},
  {'label': 'BRZ', 'value': 1494, make: 47},
  {'label': 'Forester', 'value': 674, make: 47},
  {'label': 'Impreza', 'value': 814, make: 47},
  {'label': 'Justy', 'value': 840, make: 47},
  {'label': 'Legacy', 'value': 875, make: 47},
  {'label': 'Levorg', 'value': 1713, make: 47},
  {'label': 'OUTBACK', 'value': 1063, make: 47},
  {'label': 'SVX', 'value': 1277, make: 47},
  {'label': 'Trezia', 'value': 1331, make: 47},
  {'label': 'Tribeca', 'value': 1332, make: 47},
  {'label': 'Vivio', 'value': 1388, make: 47},
  {'label': 'WRX STI', 'value': 2021, make: 47},
  {'label': 'XT', 'value': 1435, make: 47},
  {'label': 'XV', 'value': 1653, make: 47},
  {'label': '125', 'value': 21, make: 48},
  {'label': 'Alto', 'value': 299, make: 48},
  {'label': 'Baleno', 'value': 344, make: 48},
  {'label': 'Celerio', 'value': 1684, make: 48},
  {'label': 'Gladius 650', 'value': 1746, make: 48},
  {'label': 'Grand Vitara', 'value': 765, make: 48},
  {'label': 'GSF 1250', 'value': 1749, make: 48},
  {'label': 'GSX-R1000', 'value': 1894, make: 48},
  {'label': 'GSX-R600', 'value': 1893, make: 48},
  {'label': 'GSX-R750', 'value': 1892, make: 48},
  {'label': 'Ignis', 'value': 810, make: 48},
  {'label': 'Jimny', 'value': 832, make: 48},
  {'label': 'Kizashi', 'value': 854, make: 48},
  {'label': 'Liana', 'value': 880, make: 48},
  {'label': 'LTZ 400', 'value': 1996, make: 48},
  {'label': 'Sedici', 'value': 1177, make: 48},
  {'label': 'SJ Samurai', 'value': 1206, make: 48},
  {'label': 'Splash', 'value': 1247, make: 48},
  {'label': 'Super-Carry', 'value': 1272, make: 48},
  {'label': 'SV 650', 'value': 1747, make: 48},
  {'label': 'Swift', 'value': 1278, make: 48},
  {'label': 'SX4', 'value': 1279, make: 48},
  {'label': 'SX4 S-Cross', 'value': 1985, make: 48},
  {'label': 'Vitara', 'value': 1385, make: 48},
  {'label': 'VS 1400', 'value': 1755, make: 48},
  {'label': 'Auris', 'value': 315, make: 50},
  {'label': 'Avensis', 'value': 321, make: 50},
  {'label': 'Avensis Verso', 'value': 320, make: 50},
  {'label': 'Aygo', 'value': 324, make: 50},
  {'label': 'C-HR', 'value': 1898, make: 50},
  {'label': 'Camry', 'value': 416, make: 50},
  {'label': 'Carina', 'value': 424, make: 50},
  {'label': 'Celica', 'value': 440, make: 50},
  {'label': 'Corolla', 'value': 514, make: 50},
  {'label': 'Corolla Verso', 'value': 513, make: 50},
  {'label': 'Dyna', 'value': 581, make: 50},
  {'label': 'GT', 'value': 1622, make: 50},
  {'label': 'GT86', 'value': 1776, make: 50},
  {'label': 'Hiace', 'value': 792, make: 50},
  {'label': 'Hilux', 'value': 795, make: 50},
  {'label': 'IQ', 'value': 822, make: 50},
  {'label': 'Land Cruiser', 'value': 868, make: 50},
  {'label': 'LS', 'value': 888, make: 50},
  {'label': 'MR 2', 'value': 970, make: 50},
  {'label': 'Paseo', 'value': 1016, make: 50},
  {'label': 'Picnic', 'value': 1027, make: 50},
  {'label': 'Previa', 'value': 1039, make: 50},
  {'label': 'Prius', 'value': 1045, make: 50},
  {'label': 'Proace', 'value': 2038, make: 50},
  {'label': 'Proace Verso', 'value': 2040, make: 50},
  {'label': 'RAV 4', 'value': 1091, make: 50},
  {'label': 'SC 400', 'value': 1166, make: 50},
  {'label': 'SC 430', 'value': 1167, make: 50},
  {'label': 'Starlet', 'value': 1257, make: 50},
  {'label': 'Supra', 'value': 1276, make: 50},
  {'label': 'Tercel', 'value': 1296, make: 50},
  {'label': 'Urban Cruiser', 'value': 1347, make: 50},
  {'label': 'Verso', 'value': 1376, make: 50},
  {'label': 'Verso-S', 'value': 1377, make: 50},
  {'label': 'Yaris', 'value': 1436, make: 50},
  {'label': '{0}', 'value': 0, make: 51},
  {'label': '240', 'value': 73, make: 51},
  {'label': '360', 'value': 127, make: 51},
  {'label': '440', 'value': 152, make: 51},
  {'label': '460', 'value': 157, make: 51},
  {'label': '480', 'value': 158, make: 51},
  {'label': '740', 'value': 211, make: 51},
  {'label': '760', 'value': 216, make: 51},
  {'label': '850', 'value': 227, make: 51},
  {'label': '940', 'value': 248, make: 51},
  {'label': '960', 'value': 253, make: 51},
  {'label': 'C30', 'value': 401, make: 51},
  {'label': 'C70', 'value': 406, make: 51},
  {'label': 'S40', 'value': 1149, make: 51},
  {'label': 'S60', 'value': 1152, make: 51},
  {'label': 'S70', 'value': 1153, make: 51},
  {'label': 'S80', 'value': 1155, make: 51},
  {'label': 'S90', 'value': 1156, make: 51},
  {'label': 'V 50', 'value': 1493, make: 51},
  {'label': 'V40', 'value': 1355, make: 51},
  {'label': 'V40 Cross Country', 'value': 2012, make: 51},
  {'label': 'V50', 'value': 1356, make: 51},
  {'label': 'V60', 'value': 1357, make: 51},
  {'label': 'V70', 'value': 1358, make: 51},
  {'label': 'V90', 'value': 1361, make: 51},
  {'label': 'XC 60', 'value': 1414, make: 51},
  {'label': 'XC 70', 'value': 1415, make: 51},
  {'label': 'XC 90', 'value': 1416, make: 51},
  {'label': '', 'value': 291, make: 52},
  {'label': '', 'value': 1017, make: 52},
  {'label': '{0}', 'value': 0, make: 52},
  {'label': '{1281}', 'value': 1281, make: 52},
  {'label': '181', 'value': 37, make: 52},
  {'label': 'Amarok', 'value': 1605, make: 52},
  {'label': 'Amarok DoubleCab', 'value': 1602, make: 52},
  {'label': 'Amarok SingleCab', 'value': 1603, make: 52},
  {'label': 'Arteon', 'value': 1885, make: 52},
  {'label': 'Beetle', 'value': 346, make: 52},
  {'label': 'Bora', 'value': 360, make: 52},
  {'label': 'Caddy', 'value': 409, make: 52},
  {'label': 'CC', 'value': 432, make: 52},
  {'label': 'Corrado', 'value': 515, make: 52},
  {'label': 'Crafter', 'value': 529, make: 52},
  {'label': 'e-up!', 'value': 1668, make: 52},
  {'label': 'Eos', 'value': 620, make: 52},
  {'label': 'Fox', 'value': 679, make: 52},
  {'label': 'Golf', 'value': 734, make: 52},
  {'label': 'Golf Plus', 'value': 732, make: 52},
  {'label': 'Golf Sportsvan', 'value': 1618, make: 52},
  {'label': 'Jetta', 'value': 831, make: 52},
  {'label': 'Käfer', 'value': 860, make: 52},
  {'label': 'Karmann Ghia', 'value': 852, make: 52},
  {'label': 'LT', 'value': 889, make: 52},
  {'label': 'Lupo', 'value': 891, make: 52},
  {'label': 'New Beetle', 'value': 1641, make: 52},
  {'label': 'Passat', 'value': 1019, make: 52},
  {'label': 'Passat Alltrack', 'value': 1891, make: 52},
  {'label': 'Passat CC', 'value': 1018, make: 52},
  {'label': 'Passat Variant', 'value': 1890, make: 52},
  {'label': 'Phaeton', 'value': 1023, make: 52},
  {'label': 'Polo', 'value': 1032, make: 52},
  {'label': 'Scirocco', 'value': 1169, make: 52},
  {'label': 'Sharan', 'value': 1189, make: 52},
  {'label': 'T-Cross', 'value': 2033, make: 52},
  {'label': 'T-Roc', 'value': 1955, make: 52},
  {'label': 'T1 Kasten', 'value': 1786, make: 52},
  {'label': 'T1 Kombi', 'value': 1785, make: 52},
  {'label': 'T2 Kasten', 'value': 1739, make: 52},
  {'label': 'T2 Kombi', 'value': 1740, make: 52},
  {'label': 'T3', 'value': 1280, make: 52},
  {'label': 'T4 Kasten', 'value': 2046, make: 52},
  {'label': 'T4 Kombi', 'value': 2045, make: 52},
  {'label': 'T4 Multivan', 'value': 2044, make: 52},
  {'label': 'T5 California', 'value': 1601, make: 52},
  {'label': 'T5 Caravelle', 'value': 1282, make: 52},
  {'label': 'T5 Kasten', 'value': 1544, make: 52},
  {'label': 'T5 Kombi', 'value': 1614, make: 52},
  {'label': 'T5 Multivan', 'value': 1283, make: 52},
  {'label': 'T5 Pritsche', 'value': 1675, make: 52},
  {'label': 'T5 Shuttle', 'value': 1604, make: 52},
  {'label': 'T6 California', 'value': 1688, make: 52},
  {'label': 'T6 Caravelle', 'value': 1685, make: 52},
  {'label': 'T6 Kasten', 'value': 1687, make: 52},
  {'label': 'T6 Kombi', 'value': 1690, make: 52},
  {'label': 'T6 Multivan', 'value': 1686, make: 52},
  {'label': 'T6 Pritsche', 'value': 1691, make: 52},
  {'label': 'Taro', 'value': 1290, make: 52},
  {'label': 'Tiguan', 'value': 1307, make: 52},
  {'label': 'Tiguan Allspace', 'value': 1976, make: 52},
  {'label': 'Touareg', 'value': 1312, make: 52},
  {'label': 'Touran', 'value': 1316, make: 52},
  {'label': 'up!', 'value': 1600, make: 52},
  {'label': 'Vento', 'value': 1372, make: 52},
  {'label': 'XL1', 'value': 1694, make: 52},
  {'label': 'Grand Prix', 'value': 763, make: 53},
  {'label': 'Legend', 'value': 877, make: 53},
  {'label': 'Metro', 'value': 927, make: 53},
  {'label': 'Mini', 'value': 942, make: 53},
  {'label': 'Montego', 'value': 963, make: 53},
  {'label': 'D-Max', 'value': 551, make: 54},
  {'label': 'Gemini', 'value': 711, make: 54},
  {'label': 'Midi', 'value': 940, make: 54},
  {'label': 'Monterey', 'value': 964, make: 54},
  {'label': 'Trooper', 'value': 1334, make: 54},
  {'label': 'CT 200h', 'value': 538, make: 61},
  {'label': 'GS 200t', 'value': 1984, make: 61},
  {'label': 'GS 250', 'value': 1980, make: 61},
  {'label': 'GS 300', 'value': 1982, make: 61},
  {'label': 'GS 350', 'value': 773, make: 61},
  {'label': 'GS 430', 'value': 1981, make: 61},
  {'label': 'GS 450', 'value': 1977, make: 61},
  {'label': 'GS 460', 'value': 1983, make: 61},
  {'label': 'GS F', 'value': 1978, make: 61},
  {'label': 'GX 470', 'value': 781, make: 61},
  {'label': 'IS', 'value': 824, make: 61},
  {'label': 'IS 350', 'value': 823, make: 61},
  {'label': 'LS', 'value': 888, make: 61},
  {'label': 'RX 450', 'value': 2050, make: 61},
  {'label': 'SC 400', 'value': 1166, make: 61},
  {'label': 'SC 430', 'value': 1167, make: 61},
  {'label': 'Tribeca', 'value': 1332, make: 61},
  {'label': 'Crossblade', 'value': 532, make: 94},
  {'label': 'ForFour', 'value': 675, make: 94},
  {'label': 'ForTwo', 'value': 678, make: 94},
  {'label': 'Roadster', 'value': 1101, make: 94},
  {'label': 'C1', 'value': 396, make: 96},
  {'label': 'C2', 'value': 398, make: 96},
  {'label': 'C3', 'value': 400, make: 96},
  {'label': 'C5', 'value': 404, make: 96},
  {'label': 'C6', 'value': 405, make: 96},
  {'label': 'ZR 1', 'value': 1448, make: 96},
  {'label': 'Besta', 'value': 350, make: 101},
  {'label': 'Carens', 'value': 422, make: 101},
  {'label': 'Carnival', 'value': 426, make: 101},
  {'label': 'cee\'d', 'value': 438, make: 101},
  {'label': 'cee\'d Sportswagon', 'value': 436, make: 101},
  {'label': 'Cerato', 'value': 441, make: 101},
  {'label': 'Clarus', 'value': 467, make: 101},
  {'label': 'i30', 'value': 803, make: 101},
  {'label': 'Ibiza', 'value': 808, make: 101},
  {'label': 'Joice', 'value': 834, make: 101},
  {'label': 'Magentis', 'value': 901, make: 101},
  {'label': 'Mentor', 'value': 924, make: 101},
  {'label': 'Niro', 'value': 1774, make: 101},
  {'label': 'Opirus', 'value': 1002, make: 101},
  {'label': 'Optima', 'value': 1558, make: 101},
  {'label': 'Picanto', 'value': 1026, make: 101},
  {'label': 'Pregio', 'value': 1036, make: 101},
  {'label': 'Pride', 'value': 1040, make: 101},
  {'label': 'pro_cee\'d', 'value': 1046, make: 101},
  {'label': 'Rio', 'value': 1098, make: 101},
  {'label': 'Roadster', 'value': 1101, make: 101},
  {'label': 'Sephia', 'value': 1181, make: 101},
  {'label': 'Shuma', 'value': 1190, make: 101},
  {'label': 'Sorento', 'value': 1238, make: 101},
  {'label': 'Soul', 'value': 1239, make: 101},
  {'label': 'Sportage', 'value': 1248, make: 101},
  {'label': 'Stinger', 'value': 1958, make: 101},
  {'label': 'Stonic', 'value': 1957, make: 101},
  {'label': 'TF', 'value': 1302, make: 101},
  {'label': 'Venga', 'value': 1371, make: 101},
  {'label': 'Rocsta', 'value': 1103, make: 102},
  {'label': 'Espero', 'value': 630, make: 106},
  {'label': 'Evanda', 'value': 633, make: 106},
  {'label': 'Kalos', 'value': 848, make: 106},
  {'label': 'Korando', 'value': 856, make: 106},
  {'label': 'Lacetti', 'value': 864, make: 106},
  {'label': 'Lanos', 'value': 869, make: 106},
  {'label': 'Leganza', 'value': 876, make: 106},
  {'label': 'Matiz', 'value': 913, make: 106},
  {'label': 'Musso', 'value': 976, make: 106},
  {'label': 'Nexia', 'value': 989, make: 106},
  {'label': 'Nubira', 'value': 996, make: 106},
  {'label': 'Rezzo', 'value': 1097, make: 106},
  {'label': 'Tacuma', 'value': 1287, make: 106},
  {'label': '315', 'value': 109, make: 107},
  {'label': '316', 'value': 110, make: 107},
  {'label': '318', 'value': 111, make: 107},
  {'label': '414', 'value': 144, make: 107},
  {'label': '416', 'value': 145, make: 107},
  {'label': '418', 'value': 146, make: 107},
  {'label': '420', 'value': 147, make: 107},
  {'label': 'Arena', 'value': 307, make: 107},
  {'label': 'Elise', 'value': 617, make: 107},
  {'label': 'Exige', 'value': 641, make: 107},
  {'label': 'DAILY', 'value': 1765, make: 108},
  {'label': 'EcoDaily', 'value': 1783, make: 108},
  {'label': 'Massif', 'value': 910, make: 108},
  {'label': 'Actyon', 'value': 280, make: 109},
  {'label': 'Korando', 'value': 856, make: 109},
  {'label': 'Kyron', 'value': 859, make: 109},
  {'label': 'Musso', 'value': 976, make: 109},
  {'label': 'REXTON', 'value': 1096, make: 109},
  {'label': 'Rodius', 'value': 1104, make: 109},
  {'label': 'Tivoli', 'value': 1794, make: 109},
  {'label': 'Cooper', 'value': 508, make: 113},
  {'label': 'Cooper Cabrio', 'value': 1787, make: 113},
  {'label': 'Cooper Clubman', 'value': 1989, make: 113},
  {'label': 'Cooper Countryman', 'value': 522, make: 113},
  {'label': 'Cooper D Cabrio', 'value': 1788, make: 113},
  {'label': 'Cooper D Clubman', 'value': 1991, make: 113},
  {'label': 'Cooper D Countryman', 'value': 1696, make: 113},
  {'label': 'Cooper Paceman', 'value': 1582, make: 113},
  {'label': 'Cooper Roadster', 'value': 2016, make: 113},
  {'label': 'Cooper S', 'value': 507, make: 113},
  {'label': 'Cooper S Cabrio', 'value': 1771, make: 113},
  {'label': 'Cooper S Clubman', 'value': 1990, make: 113},
  {'label': 'Cooper S Countryman', 'value': 1697, make: 113},
  {'label': 'Cooper S Roadster', 'value': 1100, make: 113},
  {'label': 'Cooper SD Cabrio', 'value': 1789, make: 113},
  {'label': 'Cooper SD Clubman', 'value': 497, make: 113},
  {'label': 'Cooper SD Countryman', 'value': 1698, make: 113},
  {'label': 'Cooper SD Paceman', 'value': 1967, make: 113},
  {'label': 'Cooper SD Roadster', 'value': 2015, make: 113},
  {'label': 'John Cooper Works', 'value': 1535, make: 113},
  {'label': 'John Cooper Works Cabrio', 'value': 1790, make: 113},
  {'label': 'John Cooper Works Clubman', 'value': 1995, make: 113},
  {'label': 'John Cooper Works Countryman', 'value': 1699, make: 113},
  {'label': 'ONE', 'value': 1001, make: 113},
  {'label': 'One Cabrio', 'value': 1791, make: 113},
  {'label': 'One Clubman', 'value': 1994, make: 113},
  {'label': 'One Countryman', 'value': 1700, make: 113},
  {'label': 'One D Clubman', 'value': 1992, make: 113},
  {'label': 'One D Countryman', 'value': 1701, make: 113},
  {'label': 'Roadster', 'value': 1101, make: 113},
  {'label': 'Dokker', 'value': 1585, make: 114},
  {'label': 'Duster', 'value': 580, make: 114},
  {'label': 'Lodgy', 'value': 1587, make: 114},
  {'label': 'Logan', 'value': 886, make: 114},
  {'label': 'Sandero', 'value': 1159, make: 114},
  {'label': 'B3', 'value': 337, make: 901},
  {'label': 'D3', 'value': 553, make: 901},
  {'label': 'AR1', 'value': 306, make: 902},
  {'label': 'DB', 'value': 557, make: 902},
  {'label': 'DB7', 'value': 558, make: 902},
  {'label': 'DB9', 'value': 559, make: 902},
  {'label': 'DBS', 'value': 560, make: 902},
  {'label': 'Lagonda', 'value': 865, make: 902},
  {'label': 'V8 Vantage', 'value': 1359, make: 902},
  {'label': 'Virage', 'value': 1382, make: 902},
  {'label': 'Volante', 'value': 1389, make: 902},
  {'label': 'Azure', 'value': 326, make: 904},
  {'label': 'Brooklands', 'value': 368, make: 904},
  {'label': 'Continental', 'value': 506, make: 904},
  {'label': 'Eight', 'value': 611, make: 904},
  {'label': 'Mulsanne', 'value': 971, make: 904},
  {'label': 'Silver Shadow', 'value': 1199, make: 904},
  {'label': 'Turbo R', 'value': 1340, make: 904},
  {'label': 'Turbo RT', 'value': 1341, make: 904},
  {'label': 'Turbo S', 'value': 1342, make: 904},
  {'label': 'EB 110', 'value': 606, make: 905},
  {'label': 'Veyron', 'value': 1378, make: 905},
  {'label': 'Caliber', 'value': 411, make: 908},
  {'label': 'Charger', 'value': 444, make: 908},
  {'label': 'Dakota', 'value': 555, make: 908},
  {'label': 'Durango', 'value': 579, make: 908},
  {'label': 'Journey', 'value': 835, make: 908},
  {'label': 'Magnum', 'value': 902, make: 908},
  {'label': 'Neon', 'value': 986, make: 908},
  {'label': 'Nitro', 'value': 990, make: 908},
  {'label': 'RAM', 'value': 1084, make: 908},
  {'label': 'Stealth', 'value': 1258, make: 908},
  {'label': 'Viper', 'value': 1381, make: 908},
  {'label': '206', 'value': 46, make: 909},
  {'label': '208', 'value': 52, make: 909},
  {'label': '246', 'value': 76, make: 909},
  {'label': '250', 'value': 78, make: 909},
  {'label': '275', 'value': 84, make: 909},
  {'label': '288', 'value': 87, make: 909},
  {'label': '308', 'value': 101, make: 909},
  {'label': '328', 'value': 117, make: 909},
  {'label': '330', 'value': 119, make: 909},
  {'label': '348', 'value': 123, make: 909},
  {'label': '360', 'value': 127, make: 909},
  {'label': '365', 'value': 128, make: 909},
  {'label': '400', 'value': 136, make: 909},
  {'label': '412', 'value': 143, make: 909},
  {'label': '456', 'value': 155, make: 909},
  {'label': '458', 'value': 156, make: 909},
  {'label': '550', 'value': 180, make: 909},
  {'label': '599 GTB', 'value': 184, make: 909},
  {'label': 'Daytona', 'value': 556, make: 909},
  {'label': 'Dino GT4', 'value': 569, make: 909},
  {'label': 'Enzo Ferrari', 'value': 619, make: 909},
  {'label': 'F355', 'value': 650, make: 909},
  {'label': 'F40', 'value': 651, make: 909},
  {'label': 'F430', 'value': 652, make: 909},
  {'label': 'F50', 'value': 653, make: 909},
  {'label': 'Mondial', 'value': 961, make: 909},
  {'label': 'Testarossa', 'value': 1301, make: 909},
  {'label': 'H1', 'value': 2022, make: 911},
  {'label': 'H2', 'value': 789, make: 911},
  {'label': 'H3', 'value': 790, make: 911},
  {'label': 'EX30', 'value': 1553, make: 912},
  {'label': 'EX35', 'value': 636, make: 912},
  {'label': 'EX37', 'value': 637, make: 912},
  {'label': 'FX', 'value': 689, make: 912},
  {'label': 'FX30D', 'value': 1594, make: 912},
  {'label': 'FX50', 'value': 1775, make: 912},
  {'label': 'G35', 'value': 703, make: 912},
  {'label': 'G37', 'value': 704, make: 912},
  {'label': 'GT', 'value': 1622, make: 912},
  {'label': 'i30', 'value': 803, make: 912},
  {'label': 'M30', 'value': 896, make: 912},
  {'label': 'M30d', 'value': 1640, make: 912},
  {'label': 'M35h', 'value': 897, make: 912},
  {'label': 'M37', 'value': 898, make: 912},
  {'label': 'Q30', 'value': 1743, make: 912},
  {'label': 'Q45', 'value': 1054, make: 912},
  {'label': 'Q50', 'value': 1681, make: 912},
  {'label': 'Q60', 'value': 1887, make: 912},
  {'label': 'Q70', 'value': 1679, make: 912},
  {'label': 'QX30', 'value': 1769, make: 912},
  {'label': 'QX50', 'value': 1682, make: 912},
  {'label': 'QX56', 'value': 1064, make: 912},
  {'label': 'QX70', 'value': 1680, make: 912},
  {'label': 'Cherokee', 'value': 446, make: 914},
  {'label': 'Commander', 'value': 502, make: 914},
  {'label': 'Compass', 'value': 504, make: 914},
  {'label': 'Grand Cherokee', 'value': 760, make: 914},
  {'label': 'Patriot', 'value': 1021, make: 914},
  {'label': 'Renegade', 'value': 1095, make: 914},
  {'label': 'Wrangler', 'value': 1398, make: 914},
  {'label': 'Countach', 'value': 521, make: 915},
  {'label': 'Diablo', 'value': 567, make: 915},
  {'label': 'Espada', 'value': 629, make: 915},
  {'label': 'Gallardo', 'value': 708, make: 915},
  {'label': 'Huracan', 'value': 1702, make: 915},
  {'label': 'Jalpa', 'value': 829, make: 915},
  {'label': 'LM', 'value': 885, make: 915},
  {'label': 'Miura', 'value': 944, make: 915},
  {'label': 'Murcielago', 'value': 974, make: 915},
  {'label': 'Nova', 'value': 993, make: 916},
  {'label': 'Optima', 'value': 1558, make: 916},
  {'label': 'Continental', 'value': 506, make: 917},
  {'label': 'LS', 'value': 888, make: 917},
  {'label': 'V8', 'value': 1360, make: 917},
  {'label': '340 R', 'value': 121, make: 918},
  {'label': 'Cortina', 'value': 518, make: 918},
  {'label': 'Elan', 'value': 613, make: 918},
  {'label': 'Elise', 'value': 617, make: 918},
  {'label': 'Elite', 'value': 618, make: 918},
  {'label': 'Esprit', 'value': 631, make: 918},
  {'label': 'Evora', 'value': 635, make: 918},
  {'label': 'Exige', 'value': 641, make: 918},
  {'label': 'Omega', 'value': 1000, make: 918},
  {'label': 'Super Seven', 'value': 1271, make: 918},
  {'label': 'V8', 'value': 1360, make: 918},
  {'label': 'CJ', 'value': 454, make: 919},
  {'label': '3200', 'value': 113, make: 920},
  {'label': '4200', 'value': 148, make: 920},
  {'label': '422', 'value': 149, make: 920},
  {'label': '424', 'value': 150, make: 920},
  {'label': '430', 'value': 151, make: 920},
  {'label': 'Biturbo', 'value': 354, make: 920},
  {'label': 'Bora', 'value': 360, make: 920},
  {'label': 'Ghibli', 'value': 714, make: 920},
  {'label': 'GranCabrio', 'value': 754, make: 920},
  {'label': 'Granturismo', 'value': 772, make: 920},
  {'label': 'Indy', 'value': 816, make: 920},
  {'label': 'Karif', 'value': 851, make: 920},
  {'label': 'Levante', 'value': 2011, make: 920},
  {'label': 'Quattroporte', 'value': 1060, make: 920},
  {'label': 'Shamal', 'value': 1188, make: 920},
  {'label': 'Spyder', 'value': 1253, make: 920},
  {'label': '57', 'value': 182, make: 921},
  {'label': '62', 'value': 194, make: 921},
  {'label': '4/4', 'value': 135, make: 922},
  {'label': 'Aero 8', 'value': 281, make: 922},
  {'label': 'Plus 4', 'value': 1029, make: 922},
  {'label': 'Plus 8', 'value': 1030, make: 922},
  {'label': 'Roadster', 'value': 1101, make: 922},
  {'label': '850', 'value': 227, make: 924},
  {'label': 'Ape', 'value': 1705, make: 924},
  {'label': 'MP3', 'value': 1961, make: 924},
  {'label': 'Corniche', 'value': 512, make: 926},
  {'label': 'Flying Spur', 'value': 670, make: 926},
  {'label': 'Ghost', 'value': 715, make: 926},
  {'label': 'Phantom', 'value': 1024, make: 926},
  {'label': 'Silver Dawn', 'value': 1197, make: 926},
  {'label': 'Silver Seraph', 'value': 1198, make: 926},
  {'label': 'Silver Shadow', 'value': 1199, make: 926},
  {'label': 'Silver Spirit', 'value': 1200, make: 926},
  {'label': 'Silver Spur', 'value': 1201, make: 926},
  {'label': 'Combo', 'value': 501, make: 931},
  {'label': 'MF 3', 'value': 930, make: 933},
  {'label': 'MF 4', 'value': 933, make: 933},
  {'label': 'Roadster', 'value': 1101, make: 933},
  {'label': 'Matrix', 'value': 914, make: 1006},
  {'label': 'Twin', 'value': 1858, make: 1006},
  {'label': 'twin 640', 'value': 1860, make: 1006},
  {'label': 'Barbados', 'value': 1869, make: 1042},
  {'label': 'Curacao', 'value': 1878, make: 1042},
  {'label': 'Guadeloupe', 'value': 1866, make: 1042},
  {'label': 'Hawaii', 'value': 1879, make: 1042},
  {'label': 'Jamaika', 'value': 1889, make: 1042},
  {'label': 'Macau', 'value': 1865, make: 1042},
  {'label': 'Martinique', 'value': 1867, make: 1042},
  {'label': 'Mauritius', 'value': 1868, make: 1042},
  {'label': 'P69', 'value': 1864, make: 1042},
  {'label': 'Lightning XB12S', 'value': 1959, make: 1072},
  {'label': 'Lightning XB9S', 'value': 1960, make: 1072},
  {'label': 'Malibu', 'value': 904, make: 1087},
  {'label': 'Mondial', 'value': 961, make: 1087},
  {'label': '4230', 'value': 1804, make: 1088},
  {'label': '300 M', 'value': 91, make: 1098},
  {'label': '300C', 'value': 96, make: 1098},
  {'label': 'Crossfire', 'value': 533, make: 1098},
  {'label': 'Daytona', 'value': 556, make: 1098},
  {'label': 'ES', 'value': 624, make: 1098},
  {'label': 'Grand Voyager', 'value': 766, make: 1098},
  {'label': 'GS', 'value': 774, make: 1098},
  {'label': 'GTS', 'value': 779, make: 1098},
  {'label': 'Le Baron', 'value': 873, make: 1098},
  {'label': 'Neon', 'value': 986, make: 1098},
  {'label': 'New Yorker', 'value': 988, make: 1098},
  {'label': 'Patriot', 'value': 1021, make: 1098},
  {'label': 'PT Cruiser', 'value': 1049, make: 1098},
  {'label': 'Saratoga', 'value': 1164, make: 1098},
  {'label': 'Sebring', 'value': 1176, make: 1098},
  {'label': 'Stratus', 'value': 1262, make: 1098},
  {'label': 'Viper', 'value': 1381, make: 1098},
  {'label': 'Vision', 'value': 1384, make: 1098},
  {'label': 'Voyager', 'value': 1392, make: 1098},
  {'label': 'Summer Edition 08', 'value': 1859, make: 1118},
  {'label': 'Integra', 'value': 819, make: 1146},
  {'label': 'Adly ATV', 'value': 1895, make: 1219},
  {'label': '{0}', 'value': 0, make: 1224},
  {'label': '{0}', 'value': 0, make: 1240},
  {'label': 'Colorado', 'value': 498, make: 1271},
  {'label': '{0}', 'value': 0, make: 1283},
  {'label': '{0}', 'value': 0, make: 1296},
  {'label': 'MXU 50', 'value': 1896, make: 1299},
  {'label': '{0}', 'value': 0, make: 1325},
  {'label': 'Libero', 'value': 881, make: 1335},
  {'label': '{0}', 'value': 0, make: 1368},
  {'label': '{0}', 'value': 0, make: 1379},
  {'label': '2 WIN', 'value': 1861, make: 1412},
  {'label': '{0}', 'value': 0, make: 1423},
  {'label': 'BLH 150V', 'value': 2013, make: 1445},
  {'label': '{0}', 'value': 0, make: 1459},
  {'label': 'Flexo SP', 'value': 1870, make: 1486},
  {'label': 'Lido 42 DF', 'value': 1882, make: 1486},
  {'label': 'Lido S 35', 'value': 1881, make: 1486},
  {'label': 'Lido S 42SL', 'value': 1871, make: 1486},
  {'label': 'T 59', 'value': 1880, make: 1487},
  {'label': 'Cosmos', 'value': 519, make: 1541},
  {'label': '{0}', 'value': 0, make: 1543},
  {'label': 'FZ8', 'value': 1801, make: 1557},
  {'label': 'FZS 600', 'value': 2014, make: 1557},
  {'label': 'XJ', 'value': 1421, make: 1557},
  {'label': '124 Spider', 'value': 1784, make: 1566},
  {'label': '500C', 'value': 1466, make: 1566},
  {'label': '595', 'value': 1756, make: 1566},
  {'label': '595C', 'value': 1972, make: 1566},
  {'label': '695', 'value': 1973, make: 1566},
  {'label': '695C', 'value': 1974, make: 1566},
  {'label': 'Punto Evo', 'value': 1051, make: 1566},
  {'label': 'CAPE TOWN', 'value': 1695, make: 1567},
  {'label': 'Kart', 'value': 1754, make: 1568},
  {'label': 'Quadix', 'value': 1758, make: 1568},
  {'label': 'DS 7 Crossback', 'value': 2010, make: 1569},
  {'label': 'DS3', 'value': 575, make: 1569},
  {'label': 'DS4', 'value': 576, make: 1569},
  {'label': 'DS5', 'value': 577, make: 1569},
  {'label': 'Seven', 'value': 1795, make: 1570},
  {'label': 'Genesis', 'value': 712, make: 1571},
]

export const bodiesString = '[\n' +
  '  {"label": "Abrollanhänger", "value": 57},\n' +
  '  {"label": "Abrollkipper", "value": 20},\n' +
  '  {"label": "Abschleppwagen", "value": 21},\n' +
  '  {"label": "Absetzkipper", "value": 22},\n' +
  '  {"label": "Alkoven", "value": 43},\n' +
  '  {"label": "anderer", "value": 999},\n' +
  '  {"label": "Autokran", "value": 23},\n' +
  '  {"label": "Autotransporter", "value": 24},\n' +
  '  {"label": "Bauwagen", "value": 59},\n' +
  '  {"label": "Betonmischer", "value": 50},\n' +
  '  {"label": "Betonpumpe", "value": 51},\n' +
  '  {"label": "Bootsanhänger", "value": 60},\n' +
  '  {"label": "Bus", "value": 11},\n' +
  '  {"label": "Cabrio", "value": 4},\n' +
  '  {"label": "Chopper/Cruiser", "value": 76},\n' +
  '  {"label": "Dirt Bike", "value": 77},\n' +
  '  {"label": "Doppeldecker", "value": 70},\n' +
  '  {"label": "Dreiseitenkipper", "value": 25},\n' +
  '  {"label": "Enduro/Reiseenduro", "value": 78},\n' +
  '  {"label": "Fahrgestell", "value": 26},\n' +
  '  {"label": "Gefahrgut", "value": 95},\n' +
  '  {"label": "Geländewagen", "value": 9},\n' +
  '  {"label": "Geldtransporter", "value": 27},\n' +
  '  {"label": "Gelenkbus", "value": 71},\n' +
  '  {"label": "Gespann/Seitenwagen", "value": 79},\n' +
  '  {"label": "Getränketransporter", "value": 28},\n' +
  '  {"label": "Getreidekipper", "value": 52},\n' +
  '  {"label": "Glastransporter", "value": 29},\n' +
  '  {"label": "Hardtop", "value": 7},\n' +
  '  {"label": "Holztransporter", "value": 53},\n' +
  '  {"label": "Hubarbeitsbühne", "value": 30},\n' +
  '  {"label": "Integrierter", "value": 44},\n' +
  '  {"label": "Jumbo-LKW", "value": 54},\n' +
  '  {"label": "Kastenwagen", "value": 15},\n' +
  '  {"label": "Kastenwagen hoch", "value": 16},\n' +
  '  {"label": "Kastenwagen hoch & lang", "value": 18},\n' +
  '  {"label": "Kastenwagen lang", "value": 17},\n' +
  '  {"label": "Kehrmaschine", "value": 31},\n' +
  '  {"label": "Kipper", "value": 32},\n' +
  '  {"label": "Klein-/Leichtkraftrad", "value": 80},\n' +
  '  {"label": "Kleinbus", "value": 72},\n' +
  '  {"label": "Kleinwagen", "value": 14},\n' +
  '  {"label": "Koffer", "value": 8},\n' +
  '  {"label": "Kombi", "value": 3},\n' +
  '  {"label": "Kombi, Kleinbus bis 9 Sitze", "value": 33},\n' +
  '  {"label": "Kühlkastenwagen", "value": 34},\n' +
  '  {"label": "Kühlkoffer", "value": 35},\n' +
  '  {"label": "Langmaterialtransporter", "value": 61},\n' +
  '  {"label": "Lebensmitteltankwagen", "value": 55},\n' +
  '  {"label": "Limousine", "value": 1},\n' +
  '  {"label": "Linienbus", "value": 73},\n' +
  '  {"label": "Möbellift", "value": 62},\n' +
  '  {"label": "Mobilheim", "value": 45},\n' +
  '  {"label": "Mofa/Mokick/Moped", "value": 81},\n' +
  '  {"label": "Motorrad", "value": 82},\n' +
  '  {"label": "Motorradanhänger", "value": 63},\n' +
  '  {"label": "Muldenkipper", "value": 56},\n' +
  '  {"label": "Müllwagen", "value": 36},\n' +
  '  {"label": "Naked Bike", "value": 83},\n' +
  '  {"label": "PKW-Anhänger", "value": 64},\n' +
  '  {"label": "Plattform", "value": 65},\n' +
  '  {"label": "Pocket Bike", "value": 84},\n' +
  '  {"label": "Pritsche + Plane", "value": 99},\n' +
  '  {"label": "Pritsche unter 7,5t", "value": 19},\n' +
  '  {"label": "Quad", "value": 85},\n' +
  '  {"label": "Rallye/Cross", "value": 86},\n' +
  '  {"label": "Reisebus", "value": 74},\n' +
  '  {"label": "Rennsport", "value": 87},\n' +
  '  {"label": "Rettungswagen", "value": 37},\n' +
  '  {"label": "Roadster", "value": 2},\n' +
  '  {"label": "Roller/Scooter", "value": 88},\n' +
  '  {"label": "Saug- und Druckwagen", "value": 38},\n' +
  '  {"label": "Schwerlast", "value": 96},\n' +
  '  {"label": "Silo", "value": 66},\n' +
  '  {"label": "Softtop", "value": 6},\n' +
  '  {"label": "Sportler/Supersportler", "value": 89},\n' +
  '  {"label": "Sporttourer", "value": 90},\n' +
  '  {"label": "Sportwagen/Coupé", "value": 5},\n' +
  '  {"label": "Standard-SZM", "value": 97},\n' +
  '  {"label": "Streetfighter", "value": 91},\n' +
  '  {"label": "Super-Moto", "value": 92},\n' +
  '  {"label": "Tankaufbau", "value": 39},\n' +
  '  {"label": "Teilintegrierter", "value": 46},\n' +
  '  {"label": "Tiefkühl", "value": 58},\n' +
  '  {"label": "Tieflader", "value": 67},\n' +
  '  {"label": "Tier-/Pferdetransport", "value": 40},\n' +
  '  {"label": "Tourer", "value": 93},\n' +
  '  {"label": "Trike", "value": 94},\n' +
  '  {"label": "Überlandbus", "value": 75},\n' +
  '  {"label": "Van", "value": 13},\n' +
  '  {"label": "Verkaufsaufbau", "value": 41},\n' +
  '  {"label": "Volumen-SZM", "value": 98},\n' +
  '  {"label": "Walkingfloor", "value": 68},\n' +
  '  {"label": "Wechselfahrgestell", "value": 42},\n' +
  '  {"label": "Wechselpritsche", "value": 69},\n' +
  '  {"label": "Wohnkabine", "value": 47},\n' +
  '  {"label": "Wohnmobil", "value": 100},\n' +
  '  {"label": "Wohnmobil Pickup", "value": 48},\n' +
  '  {"label": "Wohnwagen", "value": 49}\n' +
  ']'

export const fuelString = '[\n' +
  '  {"label": "Autogas", "value": 10},\n' +
  '  {"label": "Diesel", "value": 4},\n' +
  '  {"label": "E10", "value": 9},\n' +
  '  {"label": "Elektro", "value": 5},\n' +
  '  {"label": "Erdgas", "value": 11},\n' +
  '  {"label": "Ethanol", "value": 12},\n' +
  '  {"label": "Gas", "value": 6},\n' +
  '  {"label": "Gas-Benzin", "value": 15},\n' +
  '  {"label": "Gas-Diesel", "value": 16},\n' +
  '  {"label": "Hybrid", "value": 7},\n' +
  '  {"label": "Hybrid-Benzin", "value": 13},\n' +
  '  {"label": "Hybrid-Diesel", "value": 14},\n' +
  '  {"label": "Super", "value": 3},\n' +
  '  {"label": "Wasserstoff", "value": 8}\n' +
  ']'

export const colorString = '[\n' +
  '  {"label": "beige", "value": 1},\n' +
  '  {"label": "blau", "value": 2},\n' +
  '  {"label": "braun", "value": 3},\n' +
  '  {"label": "gelb", "value": 4},\n' +
  '  {"label": "gold", "value": 5},\n' +
  '  {"label": "grau", "value": 6},\n' +
  '  {"label": "grün", "value": 7},\n' +
  '  {"label": "orange", "value": 8},\n' +
  '  {"label": "rot", "value": 9},\n' +
  '  {"label": "schwarz", "value": 10},\n' +
  '  {"label": "silber", "value": 11},\n' +
  '  {"label": "violett", "value": 12},\n' +
  '  {"label": "weiß", "value": 13}\n' +
  ']'

export const seatCoverString = '[\n' +
  '  {"label": "Alcantara", "value": 5},\n' +
  '  {"label": "andere", "value": 7},\n' +
  '  {"label": "Leder", "value": 1},\n' +
  '  {"label": "Stoff", "value": 3},\n' +
  '  {"label": "Teilleder", "value": 2},\n' +
  '  {"label": "Velour", "value": 4},\n' +
  '  {"label": "Vollleder", "value": 6}\n' +
  ']'

export const transmissionString = '[\n' +
  '  {"label": "Automatik", "value": 2},\n' +
  '  {"label": "Halbautomatik", "value": 3},\n' +
  '  {"label": "Schaltgetriebe", "value": 1}\n' +
  ']'

export const equipmentFlags0 = ' [\n' +
  '  {"label": "Airbag", "value": 1},\n' +
  '  {"label": "ABS", "value": 2},\n' +
  '  {"label": "Antriebsschlupfregelung", "value": 4},\n' +
  '  {"label": "Alarmanlage", "value": 8},\n' +
  '  {"label": "-", "value": 16},\n' +
  '  {"label": "Dynamiklenkung", "value": 32},\n' +
  '  {"label": "Stabilitätskontrolle", "value": 64},\n' +
  '  {"label": "Servolenkung", "value": 128},\n' +
  '  {"label": "Servotronic", "value": 256},\n' +
  '  {"label": "Wegfahrsperre", "value": 512},\n' +
  '  {"label": "Zentralverriegelung", "value": 1024},\n' +
  '  {"label": "automatische Distanzregelung", "value": 2048},\n' +
  '  {"label": "Berganfahrassistent", "value": 4096},\n' +
  '  {"label": "elektrische Seitenspiegel", "value": 8192},\n' +
  '  {"label": "Fernlichtassistent", "value": 16384},\n' +
  '  {"label": "Geschwindigkeitsregelanlage", "value": 32768},\n' +
  '  {"label": "Parklenkassistent", "value": 65536},\n' +
  '  {"label": "Spurhalteassistent", "value": 131072},\n' +
  '  {"label": "Spurwechselassistent", "value": 262144},\n' +
  '  {"label": "Keramikbremse", "value": 524288},\n' +
  '  {"label": "Luftfederung", "value": 1048576},\n' +
  '  {"label": "Niveauregulierung", "value": 2097152},\n' +
  '  {"label": "Sportfahrwerk", "value": 4194304},\n' +
  '  {"label": "Partikelfilter", "value": 8388608},\n' +
  '  {"label": "Head - up - Display", "value": 16777216},\n' +
  '  {"label": "Komfortschlüssel", "value": 33554432},\n' +
  '  {"label": "Nachtsichtgerät", "value": 67108864},\n' +
  '  {"label": "für Pflanzenöl geeignet", "value": 134217728},\n' +
  '  {"label": "Start / Stop - Automatik", "value": 268435456},\n' +
  '  {"label": "Reifendruckkontrolle", "value": 536870912},\n' +
  '  {"label": "behindertengerecht", "value": 1073741824},\n' +
  ']'

export const equipmentFlags1 = '[\n' +
  '  {"label": "Kurvenlicht", "value": 1},\n' +
  '  {"label": "LED - Frontscheinwerfer", "value": 2},\n' +
  '  {"label": "LED - Heckleuchten", "value": 4},\n' +
  '  {"label": "Nebelscheinwerfer", "value": 8},\n' +
  '  {"label": "Xenonscheinwerfer", "value": 16},\n' +
  '  {"label": "Panoramadach", "value": 32},\n' +
  '  {"label": "Schiebedach", "value": 64},\n' +
  '  {"label": "Leichtmetallfelgen", "value": 128},\n' +
  '  {"label": "Winterräder", "value": 256},\n' +
  '  {"label": "Anhängerkupplung fest", "value": 512},\n' +
  '  {"label": "Dachreling", "value": 1024},\n' +
  '  {"label": "elektrische Gepäckraumklappe", "value": 2048},\n' +
  '  {"label": "Windschott", "value": 4096},\n' +
  '  {"label": "Ski - /Durchladesack", "value": 8192},\n' +
  '  {"label": "variabler Ladeboden", "value": 16384},\n' +
  '  {"label": "Scheinwerferreinigungsanlage", "value": 32768},\n' +
  '  {"label": "Lichtsensor", "value": 65536},\n' +
  '  {"label": "Einparkhilfe", "value": 131072},\n' +
  '  {"label": "Einparkhilfe vorn", "value": 262144},\n' +
  '  {"label": "Einparkhilfe hinten", "value": 524288},\n' +
  '  {"label": "Einparkhilfekamera", "value": 1048576},\n' +
  '  {"label": "Beifahrerairbag", "value": 2097152},\n' +
  '  {"label": "Seitenairbag", "value": 4194304},\n' +
  '  {"label": "Kopfairbag", "value": 8388608},\n' +
  '  {"label": "Sportpaket", "value": 16777216},\n' +
  '  {"label": "Tagesfahrlicht", "value": 33554432},\n' +
  '  {"label": "Metallic - Lackierung", "value": 67108864},\n' +
  '  {"label": "Chiptuning", "value": 134217728},\n' +
  '  {"label": "Sitzbelüftung", "value": 268435456},\n' +
  '  {"label": "Bremsassistent", "value": 536870912},\n' +
  '  {"label": "Nackenheizung", "value": 1073741824},\n' +
  ']'

export const equipmentFlags2 = '[\n' +
  '  {"label": "Bordcomputer", "value": 1},\n' +
  '  {"label": "CD - Player", "value": 2},\n' +
  '  {"label": "CD - Wechsler", "value": 4},\n' +
  '  {"label": "Multifunktionslenkrad", "value": 8},\n' +
  '  {"label": "Navigationssystem", "value": 16},\n' +
  '  {"label": "Radio", "value": 32},\n' +
  '  {"label": "Sprachsteuerung", "value": 64},\n' +
  '  {"label": "Telefon", "value": 128},\n' +
  '  {"label": "Handyvorbereitung", "value": 256},\n' +
  '  {"label": "TV - Empfang", "value": 512},\n' +
  '  {"label": "Klimaanlage", "value": 1024},\n' +
  '  {"label": "Klimaautomatik", "value": 2048},\n' +
  '  {"label": "Scheiben abgedunkelt", "value": 4096},\n' +
  '  {"label": "Sonnenschutzrollo", "value": 8192},\n' +
  '  {"label": "Standheizung", "value": 16384},\n' +
  '  {"label": "dritte Sitzreihe", "value": 32768},\n' +
  '  {"label": "elektrische Sitze", "value": 65536},\n' +
  '  {"label": "Kindersitz integriert", "value": 131072},\n' +
  '  {"label": "Kindersitzbefestigung ISOFIX", "value": 262144},\n' +
  '  {"label": "Lendenwirbelstütze", "value": 524288},\n' +
  '  {"label": "Rücksitzbank umlegbar", "value": 1048576},\n' +
  '  {"label": "Sitzheizung", "value": 2097152},\n' +
  '  {"label": "Sportsitze", "value": 4194304},\n' +
  '  {"label": "elektrische Fensterheber", "value": 8388608},\n' +
  '  {"label": "Mittelarmlehne", "value": 16777216},\n' +
  '  {"label": "Multifunktionsanzeige", "value": 33554432},\n' +
  '  {"label": "Schnittstelle für externe Medien", "value": 67108864},\n' +
  '  {"label": "Bluetooth", "value": 134217728},\n' +
  '  {"label": "MP3", "value": 268435456},\n' +
  '  {"label": "Freisprecheinrichtung", "value": 536870912},\n' +
  '  {"label": "Regensensor", "value": 1073741824}\n' +
  ']'

export const equipmentFlags3 = '[\n' +
  '  {"label": "WC", "value": 1},\n' +
  '  {"label": "Etagenbett", "value": 2},\n' +
  '  {"label": "Festbett", "value": 4},\n' +
  '  {"label": "Heckgarage", "value": 8},\n' +
  '  {"label": "Markise", "value": 16},\n' +
  '  {"label": "separate Dusche", "value": 32},\n' +
  '  {"label": "Solaranlage", "value": 64},\n' +
  '  {"label": "Mittelsitzgruppe", "value": 128},\n' +
  '  {"label": "Rundsitzgruppe", "value": 256},\n' +
  '  {"label": "Seitensitzgruppe", "value": 512},\n' +
  '  {"label": "Katalysator", "value": 1024},\n' +
  '  {"label": "Kickstarter", "value": 2048},\n' +
  '  {"label": "E - Starter", "value": 4096},\n' +
  '  {"label": "Koffer", "value": 8192},\n' +
  '  {"label": "Sturzbügel", "value": 16384},\n' +
  '  {"label": "Scheibe", "value": 32768},\n' +
  '  {"label": "Standklima", "value": 65536},\n' +
  '  {"label": "S & S - Bereifung", "value": 131072},\n' +
  '  {"label": "kurzer Radstand", "value": 262144},\n' +
  '  {"label": "mittlerer Radstand", "value": 524288},\n' +
  '  {"label": "langer Radstand", "value": 1048576},\n' +
  '  {"label": "superlang", "value": 2097152},\n' +
  '  {"label": "mit Überhang", "value": 4194304},\n' +
  '  {"label": "Flachdach", "value": 8388608},\n' +
  '  {"label": "Mittelhochdach", "value": 16777216},\n' +
  '  {"label": "Hochdach", "value": 33554432},\n' +
  '  {"label": "Superhochdach", "value": 67108864},\n' +
  '  {"label": "Heckflügeltüren", "value": 134217728},\n' +
  '  {"label": "Heckklappe", "value": 268435456},\n' +
  '  {"label": "beheizbares Lenkrad", "value": 536870912},\n' +
  '  {"label": "Plugin - Hybrid", "value": 1073741824}\n' +
  ']'

export const equipmentFlags4 = '[\n' +
  '  {"label": "Kabine", "value": 1},\n' +
  '  {"label": "Schutzdach", "value": 2},\n' +
  '  {"label": "Vollverkleidung", "value": 4},\n' +
  '  {"label": "Kran", "value": 8},\n' +
  '  {"label": "Retarder / Intarder", "value": 16},\n' +
  '  {"label": "Schlafplatz", "value": 32},\n' +
  '  {"label": "Ladebordwand", "value": 64},\n' +
  '  {"label": "Schiebetür", "value": 128},\n' +
  '  {"label": "Trennwand", "value": 256},\n' +
  '  {"label": "EBS", "value": 512},\n' +
  '  {"label": "Kompressor", "value": 1024},\n' +
  '  {"label": "-", "value": 2048},\n' +
  '  {"label": "Scheibenbremse", "value": 4096},\n' +
  '  {"label": "Fronthydraulik", "value": 8192},\n' +
  '  {"label": "BSS", "value": 16384},\n' +
  '  {"label": "Schnellwechsel", "value": 32768},\n' +
  '  {"label": "ZSA", "value": 65536},\n' +
  '  {"label": "Küche", "value": 131072},\n' +
  '  {"label": "Kühlbox", "value": 262144},\n' +
  '  {"label": "Schlafsitze", "value": 524288},\n' +
  '  {"label": "Frontheber", "value": 1048576},\n' +
  '  {"label": "Müdigkeitswarnsystem", "value": 2097152},\n' +
  '  {"label": "Massagesitze", "value": 4194304},\n' +
  '  {"label": "Notrufsystem", "value": 8388608},\n' +
  '  {"label": "Touchscreen", "value": 16777216},\n' +
  '  {"label": "Totwinkel - Assistent", "value": 33554432},\n' +
  '  {"label": "Holzlenkrad", "value": 67108864},\n' +
  '  {"label": "beheizbare Frontscheibe", "value": 134217728},\n' +
  '  {"label": "Digitalradio", "value": 268435456},\n' +
  '  {"label": "Lederlenkrad", "value": 536870912},\n' +
  '  {"label": "Schaltwippen", "value": 1073741824},\n' +
  ']'

export const equipmentFlags5 = '[\n' +
  '  {"label": "Soundsystem", "value": 1},\n' +
  '  {"label": "USB", "value": 2},\n' +
  '  {"label": "Verkehrszeichenerkennung", "value": 4},\n' +
  '  {"label": "Ampelassistent", "value": 8},\n' +
  '  {"label": "Bergabfahrhilfe", "value": 16},\n' +
  '  {"label": "Beschleunigungsassistent", "value": 32},\n' +
  '  {"label": "Car2Car Communication", "value": 64},\n' +
  '  {"label": "Elektronische Dämpferregelung", "value": 128},\n' +
  '  {"label": "Fahrzeug - Umgebungs - Kommunikation", "value": 256},\n' +
  '  {"label": "Kollisionswarn - und Schutzsystem", "value": 512},\n' +
  '  {"label": "Wankneigungskontrolle", "value": 1024},\n' +
  '  {"label": "Abbiegelicht", "value": 2048},\n' +
  '  {"label": "Nothaltesystem", "value": 4096},\n' +
  '  {"label": "Geschwindigkeitsassistenz", "value": 8192},\n' +
  '  {"label": "Trailer - Assist", "value": 16384},\n' +
  '  {"label": "Geschwindigkeitsbegrenzer", "value": 32768},\n' +
  '  {"label": "Notbremsassistent", "value": 65536},\n' +
  '  {"label": "Keyless Entry", "value": 131072},\n' +
  '  {"label": "Anhängerkupplung schwenkbar", "value": 262144},\n' +
  '  {"label": "Anhängerkupplung abnehmbar", "value": 524288},\n' +
  '  {"label": "Klimaautomatik 3 Zonen", "value": 1048576},\n' +
  '  {"label": "Klimaautomatik 4 Zonen", "value": 2097152},\n' +
  '  {"label": "Schiebetür links", "value": 4194304},\n' +
  '  {"label": "Schiebetür beidseitig", "value": 8388608},\n' +
  '  {"label": "LED - Tagfahrlicht", "value": 16777216},\n' +
  '  {"label": "Sitzheizung hinten", "value": 33554432},\n' +
  '  {"label": "Sommerreifen", "value": 67108864},\n' +
  '  {"label": "Stahlfelgen", "value": 134217728},\n' +
  '  {"label": "Bi - Xenonscheinwerfer", "value": 268435456},\n' +
  '  {"label": "Laserscheinwerfer", "value": 536870912},\n' +
  '  {"label": "Blendfreies Fernlicht", "value": 1073741824},\n' +
  ']'

export const equipmentFlags6 = '[\n' +
  '  {"label": "Adaptives Kurvenlicht", "value": 1},\n' +
  '  {"label": "Reserverad", "value": 2},\n' +
  '  {"label": "Notrad", "value": 4},\n' +
  '  {"label": "Pannenkit", "value": 8},\n' +
  '  {"label": "Winterpaket", "value": 16},\n' +
  '  {"label": "Raucherpaket", "value": 32},\n' +
  '  {"label": "elektr.Sitzeinstellung hinten", "value": 64},\n' +
  '  {"label": "Ambiente - Licht", "value": 128},\n' +
  '  {"label": "W - LAN / Wifi", "value": 256},\n' +
  '  {"label": "CarPlay", "value": 512},\n' +
  '  {"label": "Android Auto", "value": 1024},\n' +
  '  {"label": "Volldigitales Kombiinstrument", "value": 2048},\n' +
  '  {"label": "Isofix - Beifahrersitz", "value": 4096},\n' +
  '  {"label": "Induktionsladen für Smartphones", "value": 8192},\n' +
  '  {"label": "Musikstreaming integriert", "value": 16384},\n' +
  '  {"label": "Innenspiegel automatisch abblendend", "value": 32768},\n' +
  '  {"label": "umklappbarer Beifahrersitz", "value": 65536},\n' +
  '  {"label": "360°-Kamera", "value": 131072},\n' +
  '  {"label": "Klimaautomatik 2 Zonen", "value": 262144},\n' +
  '  {"label": "Gepäckraumabtrennung", "value": 524288},\n' +
  '  {"label": "Abstandswarner", "value": 1048576},\n' +
  '  {"label": "Anhängerkupplung Vorbereitung", "value": 2097152},\n' +
  '  {"label": "Allwetterreifen", "value": 4194304},\n' +
  '  {"label": "Navigation Vorbereitung", "value": 8388608},\n' +
  '  {"label": "Leihbatterie", "value": 16777216},\n' +
  ']'

export const manufacturerCodes = [
  '1C',
  '1F',
  '1G',
  '1GC',
  '1GM',
  '1H',
  '1J',
  '1L',
  '1M',
  '1N',
  '1VW',
  '1YV',
  '2F',
  '2G',
  '2G1',
  '2G1',
  '2HM',
  '2M',
  '3F',
  '3G',
  '3H',
  '3VW',
  '4F',
  '4M',
  '4S',
  '4US',
  '5L',
  '5YJ',
  '6F',
  '6H',
  '6MM',
  '6T1',
  '9BW',
  'JF',
  'JH',
  'JMB',
  'JMZ',
  'JN',
  'JS',
  'JT',
  'KL',
  'KMH',
  'KN',
  'KNE',
  'KPT',
  'LFV',
  'SAJ',
  'SAL',
  'SAR',
  'SCC',
  'TMA',
  'TMB',
  'TRU',
  'U5Y',
  'U6Z',
  'UU',
  'VF1',
  'VF3',
  'VF7',
  'VFA',
  'VNK',
  'VSK',
  'VSS',
  'WAP',
  'WAU',
  'WBA',
  'WBS',
  'WBY',
  'WDB',
  'WDC',
  'WF0',
  'WJR',
  'W0L',
  'WME',
  'WMW',
  'WP0',
  'WP1',
  'W0SV',
  'WUA',
  'WV1',
  'WV2',
  'WVG',
  'WVW',
  'YK1',
  'YS3',
  'YV1',
  'ZAM',
  'ZAR',
  'ZCF',
  'ZDF',
  'ZFA',
  'ZFF',
  'ZHW',
  'ZLA',
] as Array<string>

export const makesValuesByLabels = () => {
  const labels = [
    'ALPINA',
    'Asia Motors',
    'Aston Martin',
    'Audi',
    'Austin',
    'Austin Healey',
    'Bentley',
    'BMW',
    'Borgward',
    'Cadillac',
    'Chevrolet',
    'Citroen',
    'Cobra',
    'Corvette',
    'Cupra',
    'Dacia',
    'Daewoo',
    'Daihatsu',
    'Dodge',
    'DS Automobile',
    'Ferrari',
    'Fiat',
    'Ford',
    'Honda',
    'Hummer',
    'Hyundai',
    'Infiniti',
    'Isuzu',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lada',
    'Lamborghini',
    'Lancia',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Lotus',
    'Maserati',
    'Maybach',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'MG',
    'Mini (BMW)',
    'Mitsubishi',
    'Morgan',
    'Nissan',
    'Opel',
    'Peugeot',
    'Pontiac',
    'Porsche',
    'Renault',
    'Rolls-Royce',
    'Rover',
    'Saab',
    'Seat',
    'Skoda',
    'Smart',
    'Ssangyong',
    'Subaru',
    'Suzuki',
    'Tata',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]

  const makes = JSON.parse(makesString)

  return makes.filter((make: Make) => labels.includes(make.label)).map((make: Make) => make.value)
}
