import React, {ReactNode} from 'react'
import {classNames} from 'utils/class-names'

type Props = {
  variant: Variant
  link: string
  children: ReactNode
  target: string
}

enum Variant {
  BLACK,
  WHITE
}

const VARIANTS: Record<Variant, string> = {
  [Variant.BLACK]: 'text-gray-900 focus:shadow-outline-gray focus:bg-blue-100 focus:text-blue-900 hover:bg-blue-100 hover:text-blue-900',
  [Variant.WHITE]: 'text-white focus:shadow-outline-white focus:bg-blue-100 focus:text-blue-900 hover:bg-blue-100 hover:text-blue-900',
}

export function Link({children, link, variant, target}: Props) {
  return (
    <a target={target} rel="noopener noreferrer" className={classNames(
      'underline rounded focus:outline-none px-1 -mx-1',
      VARIANTS[variant]
    )} href={link}>{children}</a>
  )
}


Link.defaultProps = {
  variant: Variant.BLACK,
  target: '_blank'
}

Link.variant = Variant


export default Link