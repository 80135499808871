import {history, LocationMatch} from 'components/Router'
import {createUrl, hostname, track} from 'hooks'
import React from 'react'
import PrimaryButton from "../../components/PrimaryButton"


type ImprintProps = {
  match: LocationMatch
}

const Imprint = (props: ImprintProps) => (
  <div>
    <div className={'my-6 md:-ml-4'}>
      <PrimaryButton event={'back-button'} title={'Zurück'} active={true} onClick={() => {
        track('button-back-to-homepage-clicked')
        history.go(-1)
      }} buttonId={'imprint-back-button'}/>
    </div>

    <div className="flex flex-col mb-2 container md:mt-6 lg:mt-10 bg-gray-100 rounded shadow px-10 py-5">
      <img src="/images/Logo.png" className="w-1/4 min-w-150 cursor-pointer" alt={'logo'} onClick={() => {
        track('logo-clicked')
        history.push(createUrl('', props.match))
      }}/>

      <span>In der Spöck 4</span>
      <span>77656 Offenburg</span>
      <span>Vorstand: Frank Ritter</span>
      <span>Sitz: Offenburg</span>
      <span>HRB: 711258</span>
      <span>Registergericht Freiburg i. Br.</span>
      <span>USt-IdNr.: DE294545819</span>
      <span>Telefon: +49 (0)781 611 204</span>
      <span>Fax: +49 (0)781 611 115</span>
      <span>E-Mail: kontakt@autohausen.de</span>

      <span className={'font-bold text-3xl mt-8'}>Rechtliche Hinweise:</span>
      <span className={'font-semibold text-2xl'}>1. Allgemeines</span>
      <span>Die AUTOHAUSEN® AG, In der Spöck 4, 77656 Offenburg, ermöglicht den Nutzern, die ihr Gebrauchtfahrzeug verkaufen oder in Zahlung geben wollen, auf der Internetseite {hostname()} die kostenlose Ermittlung des voraussichtlichen Händlerankaufspreises und die Übermittlung ihrer Anfrage einschließlich der Fahrzeugdaten und ihrer Kontaktdaten an den von ihnen ausgewählten Händler.</span>
      <span>Die Tätigkeit der AUTOHAUSEN® AG beschränkt sich auf das Ermitteln voraussichtlicher Händlerankaufspreise und die Übermittlung von Anfragen. Die AUTOHAUSEN AG ist nicht Ankäufer der Fahrzeuge, Vermittler, Vertragspartei oder Vertreter einer Vertragspartei bei Gebrauchtwagenankäufen oder -inzahlungnahmen. Der Vertragsschluss mit und die Vertragserfüllung zwischen Nutzern und Händlern erfolgen ohne Beteiligung von AUTOHAUSEN.</span>
      <span>Bitte beachten Sie, dass Händlereinkaufspreise nicht den bei privaten Autoverkäufen üblichen Preisen entsprechen!</span>

      <span className={'font-semibold text-2xl mt-8'}>2. Funktionsweise, Übermittlung von Daten</span>
      <span>Nutzer können die Daten ihres Fahrzeugs und ihre Kontaktdaten eingeben sowie einen Händler auswählen.</span>
      <span>Auf Grundlage der Fahrzeugdaten wird der voraussichtliche Händlereinkaufspreis ermittelt. Hierfür werden ggf. Daten des Fahrzeugs an Dritte, z.B. Datenbankbetreiber und Bewertungsdienste, weitergegeben. Personenbezogene Daten werden in diesem Fall nicht übermittelt.</span>
      <span>Die von den Nutzern eingegebenen Kontaktdaten dienen ausschließlich der Kontaktaufnahme durch den jeweils ausgewählten Händler und werden ihm nur hierfür übermittelt. Für die Verarbeitung durch den ausgewählten Händler gilt dessen jeweilige Datenschutzerklärung.</span>

      <span className={'font-semibold text-2xl mt-8'}>3. Preisermittlung</span>
      <span>Die ermittelten Händlerankaufspreise werden anhand der vom Nutzer eingegebenen Fahrzeugdaten sowie den auf dieser Grundlage automatisiert ermittelt.</span>
      <span>Die automatisiert ermittelten vorläufigen Händlerankaufspreise basieren auf den für Durchschnittsfahrzeugen üblichen Preisen und entsprechen nicht immer dem tatsächlichen Händlerankaufspreisen für das konkret vom Nutzer angebotene Fahrzeug, da preisrelevante Faktoren wie der genaue Fahrzeugzustand, etwaige Beschädigungen oder besondere Ausstattungsmerkmale bei der automatisierten Ermittlung des vorläufigen Händlerankaufspreises nicht berücksichtigt werden können.</span>

      <span>Bitte beachten Sie, dass Händlerankaufspreise nicht den bei privaten Autoverkäufen üblichen Preisen entsprechen!</span>
    </div>
  </div>
)


export default Imprint
