import React, {useState} from 'react'
import {classNames} from 'utils/class-names'

type Props = {
  onClick: CallableFunction
}

export function Toggle({onClick}: Props) {
  const [isOn, setOn] = useState(false)

  return (
    <button type="button" aria-pressed="false" onClick={() => {
      setOn(!isOn)
      onClick()
    }} className={classNames(
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
      isOn ? 'bg-skin-button-muted' : 'bg-gray-300'
    )}>
      <span className="sr-only">Use setting</span>
      <span className={classNames(
        'relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        isOn ? 'translate-x-5' : 'translate-x-0'
      )}>
        <span className={classNames(
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
          isOn ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'
        )} aria-hidden="true">
          <svg className="h-3 w-3 text-gray-500" fill="none" viewBox="0 0 12 12">
            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <span className={classNames(
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
          isOn ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'
        )} aria-hidden="true">
          <svg className="h-3 w-3 text-skin-button-muted" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
          </svg>
        </span>
      </span>
    </button>
  )
}

export default Toggle