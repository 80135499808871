import {FormGroup, TextField} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Header from 'components/Header'
import PrimaryButtonLink from 'components/PrimaryButtonLink'
import {LocationMatch} from 'components/Router'
import TertiaryButtonLink from 'components/TertiaryButtonLink'
import {Dealer} from 'domain-models'
import {hostname, isAudiSite, isSkodaSite, track} from 'hooks'
import _ from 'lodash'
import React from 'react'
import {getStoredAppObject, getStoredAppString, getStoredLeadString, getStoredVehicleObject, storeLeadItem, storeVehicleItem} from 'redux/actions'
import InputMasked from "components/InputMasked"
import Input from '@material-ui/core/Input'
import Tooltip from "../components/Tooltip"
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import SelectDealer from "./SelectDealer"

type ContactProps = {
  match: LocationMatch
}
type ContactState = {
  salutation: string
  firstName: string
  lastName: string
  email: string
  phone: string
  intention: string
  privacyAccepted: boolean
  dealer: Dealer | null
  defaultDealers: Array<Dealer>
  dealerId: number | null
  isLoading: boolean
  notice: string
}

class Contact extends React.Component<ContactProps, ContactState> {
  emailValid = _.debounce(() => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)
  }, 500)

  constructor(props: ContactProps) {
    super(props)

    let dealer = getStoredVehicleObject('dealer') as Dealer | null

    const defaultDealers = getStoredAppObject('defaultDealers', []) as Array<Dealer>

    let newDealer = false
    if (!dealer || !dealer.DealerId) {
      newDealer = true
      dealer = _.find(defaultDealers, {IsDefault: true}) || null
    }

    if (!dealer || !dealer.DealerId) {
      newDealer = true
      dealer = _.first(defaultDealers) as Dealer
    }

    if (dealer && newDealer) {
      storeVehicleItem('dealer', dealer)
    }

    this.state = {
      isLoading: true,
      salutation: getStoredLeadString('salutation') || '-',
      firstName: getStoredLeadString('firstName') || '',
      lastName: getStoredLeadString('lastName') || '',
      email: getStoredLeadString('email') || '',
      phone: getStoredLeadString('phone') || '',
      intention: getStoredLeadString('intention', 'no-intention'),
      privacyAccepted: false,
      dealer: dealer,
      dealerId: dealer ? dealer.DealerId : null,
      defaultDealers: defaultDealers || [],
      notice: getStoredLeadString('dealerNotice', '')
    }
  }

  componentDidMount() {
    this.setState({isLoading: false})
  }

  changeIntention = (intention: string) => {
    storeLeadItem('intention', intention)
    this.setState({intention})
  }

  changeSalutation = (salutation: string) => {
    storeLeadItem('salutation', salutation)
    this.setState({salutation})
  }

  changeFirstName = (firstName: string) => {
    storeLeadItem('firstName', firstName)
    this.setState({firstName})
  }

  changeLastName = (lastName: string) => {
    storeLeadItem('lastName', lastName)
    this.setState({lastName})
  }

  changeEmail = (email: string) => {
    storeLeadItem('email', email)
    this.setState({email})
  }

  changePhone = (phone: string) => {
    const number = phone.replace(/[^0-9]/g, "")
    storeLeadItem('phone', number, 'change-phone-number', false)
    this.setState({phone: number})
  }

  changeNotice = (notice: string) => {
    storeLeadItem('dealerNotice', notice, 'change-notice', false)
    this.setState({notice})
  }

  selectDealer = (dealerId: number) => {
    const dealer = _.find(this.state.defaultDealers, {DealerId: _.toInteger(dealerId)})

    if (dealer) {
      storeVehicleItem('dealer', dealer)
      this.setState({dealer, dealerId: dealer.DealerId})
    }
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={3} match={this.props.match}/>

        <div className={"mt-4 md:mt-6 lg:mt-24"}>
          <h3 className="text-xl lg:text-3xl font-bold">Sie haben es fast geschafft</h3>
          <span className="mt-1">Geben Sie hier Ihre Kontaktdaten ein. Der Wert Ihres Gebrauchten wartet auf Sie!</span>
        </div>
        <div className="flex flex-col container mx-auto py-3 lg:py-6 container mx-auto">
          <div className="w-4/5">
            <h3 className="text-xl font-bold">Ihre Kontaktdaten</h3>
          </div>

          <div className="lg:pt-6">
            <h3 className="text-xl">Anrede</h3>

            <div className="mt-4">
              <div className="mt-2 flex flex-row">
                <input id="radio3" type="radio" name="radio" className="radio-input hidden" checked={this.state.salutation === '-'} onChange={() => {
                  track('gender-selected', 'other')
                  this.changeSalutation('-')
                }}/>
                <label htmlFor="radio3" className="flex items-center cursor-pointer mr-4">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500"/>
                  Keine Angabe
                </label>
                <input id="radio1" type="radio" name="radio" className="radio-input hidden" checked={this.state.salutation === 'mrs'} onChange={() => {
                  track('gender-selected', 'female')
                  this.changeSalutation('mrs')
                }}/>
                <label htmlFor="radio1" className="flex items-center cursor-pointer ml-1 mr-4">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500"/>
                  Frau
                </label>
                <input id="radio2" type="radio" name="radio" className="radio-input hidden" checked={this.state.salutation === 'mr'} onChange={() => {
                  track('gender-selected', 'male')
                  this.changeSalutation('mr')
                }}/>
                <label htmlFor="radio2" className="flex items-center cursor-pointer">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500"/>
                  Herr
                </label>
              </div>

              <div className="mt-2 flex flex-col lg:flex-row w-full lg:w-2/3">
                <FormGroup row={true} className={'w-full mt-2 mr-0 lg:mr-1.5'}>
                  <TextField type={'text'} required={true} id="first-name-input" className={'w-full'} label="Vorname" defaultValue={this.state.firstName} onChange={event => this.changeFirstName(event.target.value)}/>
                </FormGroup>

                <FormGroup row={true} className={'w-full mt-2 ml-0 lg:ml-1.5'}>
                  <TextField type={'text'} required={true} id="last-name-input" className={'w-full'} label="Nachname" defaultValue={this.state.lastName} onChange={event => this.changeLastName(event.target.value)}/>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="mt-4 lg:mt-12">
            <h3 className="text-xl">Wie können wir Sie erreichen?</h3>

            <div className="flex flex-col lg:flex-row w-full lg:w-2/3">
              <FormGroup row={true} className={'w-full mt-2 mr-0 lg:mr-1.5'}>
                <TextField type={'email'} error={(this.state.email && this.state.email.length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) || false} required={true} id="email-input" className={'w-full'} label="E-Mail" defaultValue={this.state.email} onChange={event => this.changeEmail(event.target.value)}/>
              </FormGroup>

              <FormGroup row={true} className={'w-full mt-2  ml-0 lg:ml-1.5'}>
                <FormControl style={{width: '100%'}}>
                  <InputLabel htmlFor="phone-input" required={true}>Telefonnummer</InputLabel>
                  <Input style={{width: '100%'}} value={this.state.phone || ''} onChange={(event) => this.changePhone(event.target.value)} name="phone-input" placeholder={'(0) 1234 / 56789'} id="phone-input" required={true} inputComponent={InputMasked}/>
                </FormControl>
                <Tooltip label={<span className={'cursor-pointer text-sm text-skin-button-fill'}>Warum ist das notwendig?</span>}>
                  Die Telefonnummer (mobil oder Festnetz) ist eine Pflichtangabe, damit Ihr Händler schnellstmöglich mit Ihnen Kontakt aufnehmen kann.
                </Tooltip>
              </FormGroup>
            </div>
          </div>

          <div className="mt-4 lg:mt-12">
            <h3 className="text-xl">Haben Sie eine Nachricht für das Autohaus?</h3>

            <div className="mt-2">
              <FormGroup row={true} className={'w-full'}>
                <div className={'w-full lg:w-2/3'}>
                  <TextField type={'text'} required={false} id="notice-input" className={'w-full'} label="Nachricht für das Autohaus" defaultValue={this.state.notice} onChange={event => this.changeNotice(event.target.value)}/>
                </div>
              </FormGroup>
            </div>
          </div>

          {
            this.props.match.params.domain ?
              <div className="pt-12">
                <h3 className="text-xl">Ihr Wunschhändler</h3>

                <div className={'w-full relative'}>
                  {
                    this.state.defaultDealers.length ?
                      <FormControl className={'m-1'}>
                        <InputLabel id="contact-dealer-select-label">Händler</InputLabel>
                        <Select labelId="contact-dealer-select-label" id="contact-dealer-select" value={this.state.dealerId} onChange={(e) => this.selectDealer(e.target.value as number)}>
                          {this.state.defaultDealers.map(dealer => (
                            <MenuItem key={dealer.Firm} value={dealer.DealerId}>
                              <div className={'flex flex-col text-gray-600'}>
                                <span className={''}>{dealer.Firm}</span>
                                {
                                  dealer.FirmExtra && dealer.Firm !== dealer.FirmExtra ?
                                    <span className={''}>{dealer.FirmExtra}</span>
                                    : null
                                }
                                <span className={'text-gray-800'}>{dealer.Street}</span>
                                <span className={'font-semibold text-gray-800'}>{dealer.PostalCode} {dealer.City}</span>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      : !this.state.dealer && <SelectDealer hideHeader={true} match={this.props.match} onChange={(dealer: Dealer) => {this.setState({dealer})}} />
                  }

                </div>
              </div>
              : null
          }

          {
            this.state.dealer && (!this.props.match.params.domain || !this.state.defaultDealers.length) ?
              <div className={'mt-12 fkex gap-4'}>
                <FormControl>
                  <h3 className="text-xl">Ausgewählter Händler</h3>
                  <div className={'flex flex-col'}>
                    <strong className={'font-semibold text-gray-900'}>{this.state.dealer.Firm}</strong>
                    <span className={'text-gray-800'}>{this.state.dealer.Street}</span>
                    <span className={'text-gray-800'}>{this.state.dealer.PostalCode} {this.state.dealer.City}</span>
                  </div>
                </FormControl>
              </div>
              : null
          }


          <div className="pt-6 lg:pt-12">
            <h3 className="text-xl">Der Gebrauchte ist bald weg - Suchen Sie einen Neuen?</h3>

            <div className="mt-4">
              <div className="mt-2 flex flex-col text-skin-base-accent font-semibold">
                <input id="radio5" type="radio" name="radio2" className="radio-input hidden" onChange={() => {
                  track('intention-selected', 'no-intention')
                  this.changeIntention('no-intention')
                }} checked={this.state.intention === 'no-intention'}/>
                <label htmlFor="radio5" className="flex items-center cursor-pointer text-sm">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500" style={{minWidth: '1rem'}}/>
                  Nein, ich interessiere mich nur für den Verkauf meines Gebrauchten
                </label>

                <input id="radio6" type="radio" name="radio2" className="radio-input hidden" onChange={() => {
                  track('intention-selected', 'new-vehicle-purchase')
                  this.changeIntention('new-vehicle-purchase')
                }} checked={this.state.intention === 'new-vehicle-purchase'}/>
                <label htmlFor="radio6" className="flex items-center cursor-pointer mr-4 text-sm mt-2">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500" style={{minWidth: '1rem'}}/>
                  Ja, ich suche einen Neuwagen
                </label>

                <input id="radio4" type="radio" name="radio2" className="radio-input hidden" onChange={() => {
                  track('intention-selected', 'used-vehicle-purchase')
                  this.changeIntention('used-vehicle-purchase')
                }} checked={this.state.intention === 'used-vehicle-purchase'}/>
                <label htmlFor="radio4" className="flex items-center cursor-pointer mr-4 text-sm mt-2">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-gray-500" style={{minWidth: '1rem'}}/>
                  Ja, ich suche einen Jahres- oder Gebrauchtwagen
                </label>
              </div>

              <div className="mt-8">
                <label className="flex justify-start items-start w-full lg:w-2/3">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.privacyAccepted} onChange={event => {
                      track('privacy-checkbox', event.target.checked)
                      this.setState({privacyAccepted: event.target.checked})
                    }}/>

                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none -mt-1"> Im Folgenden erklären Sie sich damit einverstanden, dass Ihre auf {hostname()} eingegebenen Kontakt- und Fahrzeugdaten an das von Ihnen ausgewählte {isAudiSite() ? 'Audi' : isSkodaSite() ? 'ŠKODA' : 'Volkswagen'} Autohaus zum Zwecke der Abwicklung Ihres Fahrzeugankaufs übertragen werden.</div>
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-row">
            <div className="flex flex-col-reverse md:block w-full md:w-auto">
              {
                getStoredAppString('procedure') === 'vehicle-vin' ?
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-vehicle-vin-clicked'} to={'vehicle-vin'} title={'Schritt zurück'} active={true}/>
                  :
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-vehicle-interior-clicked'} to={'vehicle-interior'} title={'Schritt zurück'} active={true}/>
              }

              <PrimaryButtonLink tagManagerData={{
                Option: (getStoredAppString('procedure') === 'vehicle-vin' ? 'FIN' : 'Manuell'),
              }} buttonId={'button-show-valuation'} event={'button-show-valuation-clicked'} to={'email-confirmation'} title={'E-Mail bestätigen und Fahrzeugbewertung anzeigen'} active={!!(!this.props.match.params.domain || this.state.defaultDealers.length) && !!(!this.state.isLoading && this.state.privacyAccepted && this.state.firstName && this.state.lastName && this.state.phone && (this.state.phone.length >= 8) && this.state.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email))}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
