import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import TransitionsModal from 'components/TransitionsModal'
import {track} from 'hooks'
import React from 'react'
import List from "./List"
import InfoIcon from "./InfoIcon"

const points = [
  'Einfache Abwicklung (Altfahrzeug abstellen, Neues Fahrzeug mitnehmen)',
  'Keinerlei Zeitaufwand für das Erstellen von Inseraten, Anrufe, Preisverhandlungen',
  'Keine Reklamationen bei evtl. auftretenden Mängel nach Fahrzeugübergabe, das Risiko übernimmt der Händler',
  'Sofortige Verrechnung des vereinbarten Inzahlungnahmepreises mit dem Verkaufspreis',
  'Keine Betrugsgefahr bei Fahrzeugbesichtigungen vor Ort oder bei einer möglichen Bargeldübergabe',
]

const DifferExplanation = () => (
  <TransitionsModal classes={{
    modal: 'sm:flex items-center justify-center overflow-y-auto',
    modalBox: '',
    button: 'underline text-left'
  }} buttonId={'button-valuation-explanation'} onClick={() => {
    track('valuation-explanation-opened')
  }} title={null} buttonContent={
    <div className={'flex space-x-2'}>
      <InfoIcon/>
      <span> Warum kann der Händlerpreis von den Angeboten der üblichen Fahrzeugbörsen abweichen?</span>
    </div>
  } content={
    <div className={'flex flex-col'}>
      <h3 className={'font-bold text-2xl text-left'}>Warum kann der Händlerpreis von den Angeboten der üblichen Fahrzeugbörsen abweichen?</h3>

      <div className={'rounded pb-4 mt-8 text-left'}>
        <p>
          Aufgrund von Handlingskosten, Aufbereitungskosten und Vermarktungsrisiko, laut Gesetzgeber zu gewährenden Garantie bzw. Sachmangelhaftung kann es zu einem abweichenden Händlerpreis im Gegensatz zu Angebotspreisen vergleichbarer Plattformen kommen.
        </p>

        <h4 className={'font-semibold text-xl text-left mt-8 mb-2'}>
          Daraus ergeben sich die folgenden Vorteile für Sie:
        </h4>

        <List>
          {
            points.map((point: string) => (
              <List.Item>
                <Icon icon={faCheck}/>
                <span className={'ml-1'}>{point}</span>
              </List.Item>
            ))
          }
        </List>
      </div>
    </div>
  }/>
)

export default DifferExplanation
