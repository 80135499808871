import {TextField} from '@material-ui/core'
import Header from 'components/Header'
import PrimaryButtonLink from 'components/PrimaryButtonLink'
import {LocationMatch} from 'components/Router'
import SelectInput from 'components/SelectInput'
import TertiaryButtonLink from 'components/TertiaryButtonLink'
import {Codes, Fuel} from 'domain-models'
import _ from 'lodash'
import React from 'react'
import {getStoredAppObject, getStoredVehicleBoolean, getStoredVehicleNumber, storeVehicleItem} from 'redux/actions'
import {SectionHead} from "../components/SectionHead"

type VehicleTransmissionProps = {
  match: LocationMatch
}

type VehicleTransmissionState = {
  fuels: Array<Fuel>
  transmissions: Array<{ label: string, value: string | number }>
  fuel: number | null
  transmission: number | null
  power: number | null
  fourWheelDrive: boolean
}

class VehicleTransmission extends React.Component<VehicleTransmissionProps, VehicleTransmissionState> {

  constructor(props: VehicleTransmissionProps) {
    super(props)

    const codes = getStoredAppObject('codes') as Codes

    const fuels = codes && codes.Fuel ? codes.Fuel : []

    this.state = {
      fuels: fuels,
      fuel: getStoredVehicleNumber('fuel') || null,
      power: getStoredVehicleNumber('power') || null,
      transmissions: codes && codes.Transmission ? _.filter(codes.Transmission, (transmission => ['Halbautomatik'].indexOf(transmission.label) === -1)) : [],
      transmission: getStoredVehicleNumber('transmission') || null,
      fourWheelDrive: getStoredVehicleBoolean('fourWheelDrive'),
    }
  }

  componentDidMount = async () => {
    const fuelsValues = [
      3, 4, 5, 12, 13, 14, 10, 11, 8,
    ] as Array<number>

    await this.setState({
      fuels: await _.filter(this.state.fuels, (fuel: Fuel) => {
        return fuelsValues.indexOf(fuel.value) !== -1
      }),
    })
  }

  changeTransmission = (value: string) => {
    const transmission = _.toInteger(value)
    storeVehicleItem('transmission', transmission)
    this.setState({transmission})
  }

  changeFuel = (value: string) => {
    const fuel = _.toInteger(value)

    storeVehicleItem('fuel', fuel)
    this.setState({fuel})
  }

  changePower = (value: string) => {
    let power = _.toInteger(value.slice(0, 3))

    if (power < 0) {
      power = power * -1
    }

    storeVehicleItem('power', power)
    this.setState({
      power: power || null,
    })
  }

  changeFourWheelDrive = (value: boolean) => {
    storeVehicleItem('fourWheelDrive', value ? 1 : 0)
    this.setState({fourWheelDrive: value})
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={2} match={this.props.match}/>

        <div className="mt-4 md:mt-6 lg:mt-24 mx-auto block">

          <SectionHead title={'Machen Sie Angaben zu Ihrem Motor und Antrieb'} procedure={'manual'}/>

          <div className="pt-8 flex flex-col lg:flex-row">
            <svg width="568" height="247" viewBox="0 0 568 247" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full lg:w-auto">
              <path d="M257.751 221.425L202.476 190.575" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M196.542 171.303L216.475 116.067" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M243.274 113.888L263.781 136.298" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M272.414 125.537L296.409 138.221" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M277.492 111.923L300.823 124.945" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M290.653 84.6959L342.345 98.4753" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M358.851 117.25L371.413 189.462" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M358.671 203.62L296.408 228.729" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M196.314 39.7115L221.242 15.053H347.263L371.377 38.9124" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M234.056 15.4437V45.6306" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M333.642 15.4437V45.6306" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M283.846 63.9794C290.784 63.9794 296.408 58.4144 296.408 51.5495C296.408 44.6847 290.784 39.1196 283.846 39.1196C276.908 39.1196 271.283 44.6847 271.283 51.5495C271.283 58.4144 276.908 63.9794 283.846 63.9794Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M150.874 94.918L189.435 56.7642" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M417.955 94.918L379.394 56.7642" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M158.692 101.512L141.643 118.387C136.666 123.311 133.866 129.994 133.866 136.955V141.465C133.866 151.302 141.924 159.275 151.867 159.275" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M166.523 123.465L159.727 130.189C155.079 134.788 152.465 141.033 152.465 147.538V163.217C152.465 173.789 161.127 182.359 171.811 182.359H184.17" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M182.974 132.64L174.605 140.92C171.344 144.146 169.514 148.515 169.514 153.072V168.154" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M409.398 101.512L426.448 118.381C431.425 123.306 434.225 129.988 434.225 136.949V141.459C434.225 151.296 426.167 159.269 416.224 159.269" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M401.568 123.465L408.364 130.189C413.012 134.788 415.626 141.033 415.626 147.538V163.217C415.626 173.789 406.964 182.359 396.28 182.359H383.921" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M385.117 132.64L393.486 140.92C396.747 144.146 398.577 148.515 398.577 153.072V168.154" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M322.58 244.994L340.527 223.982L383.898 212.144V127.123L428.214 83.2754L418.2 73.367L422.208 69.4013L389.635 37.1724L385.627 41.1381L377.455 33.0527L351.061 59.1673L318.584 59.1555C311.142 59.1555 304.185 62.4524 299.22 67.9393C295.499 72.053 290.091 74.6396 284.073 74.6396C278.049 74.6396 272.635 72.0471 268.914 67.9274C263.961 62.4405 256.998 59.1377 249.562 59.1377L216.085 59.1259L190.135 33.4493L181.915 41.582L177.452 37.1664L144.879 69.3954L149.342 73.8109L139.478 83.5714L184.093 127.715V212.15L226.865 223.988L238.83 245H322.58V244.994Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M284.258 230.493C307.481 230.493 326.307 211.866 326.307 188.888C326.307 165.91 307.481 147.283 284.258 147.283C261.035 147.283 242.209 165.91 242.209 188.888C242.209 211.866 261.035 230.493 284.258 230.493Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M284.126 113.604C292.571 113.604 299.417 106.831 299.417 98.4753C299.417 90.1198 292.571 83.3463 284.126 83.3463C275.682 83.3463 268.836 90.1198 268.836 98.4753C268.836 106.831 275.682 113.604 284.126 113.604Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M268.836 138.434C272.705 138.434 275.841 135.331 275.841 131.503C275.841 127.675 272.705 124.572 268.836 124.572C264.967 124.572 261.831 127.675 261.831 131.503C261.831 135.331 264.967 138.434 268.836 138.434Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M284.259 201.128C291.091 201.128 296.63 195.648 296.63 188.888C296.63 182.128 291.091 176.647 284.259 176.647C277.426 176.647 271.888 182.128 271.888 188.888C271.888 195.648 277.426 201.128 284.259 201.128Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M284.258 105.259C288.127 105.259 291.263 102.155 291.263 98.3274C291.263 94.4994 288.127 91.3962 284.258 91.3962C280.389 91.3962 277.253 94.4994 277.253 98.3274C277.253 102.155 280.389 105.259 284.258 105.259Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M230.418 127.751C234.287 127.751 237.423 124.648 237.423 120.82C237.423 116.992 234.287 113.888 230.418 113.888C226.549 113.888 223.413 116.992 223.413 120.82C223.413 124.648 226.549 127.751 230.418 127.751Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M209.481 185.165C213.35 185.165 216.486 182.062 216.486 178.234C216.486 174.406 213.35 171.303 209.481 171.303C205.612 171.303 202.476 174.406 202.476 178.234C202.476 182.062 205.612 185.165 209.481 185.165Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M356.643 196.411C360.512 196.411 363.648 193.308 363.648 189.48C363.648 185.652 360.512 182.549 356.643 182.549C352.774 182.549 349.638 185.652 349.638 189.48C349.638 193.308 352.774 196.411 356.643 196.411Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M339.294 124.199C343.163 124.199 346.299 121.096 346.299 117.268C346.299 113.44 343.163 110.337 339.294 110.337C335.425 110.337 332.289 113.44 332.289 117.268C332.289 121.096 335.425 124.199 339.294 124.199Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M298.143 138.434C302.012 138.434 305.148 135.331 305.148 131.503C305.148 127.675 302.012 124.572 298.143 124.572C294.274 124.572 291.138 127.675 291.138 131.503C291.138 135.331 294.274 138.434 298.143 138.434Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M339.557 136.298C350.113 136.298 358.671 127.831 358.671 117.387C358.671 106.942 350.113 98.4753 339.557 98.4753C329.002 98.4753 320.444 106.942 320.444 117.387C320.444 127.831 329.002 136.298 339.557 136.298Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M356.757 203.745C364.848 203.745 371.407 197.255 371.407 189.249C371.407 181.243 364.848 174.753 356.757 174.753C348.666 174.753 342.106 181.243 342.106 189.249C342.106 197.255 348.666 203.745 356.757 203.745Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M230.61 135.037C238.702 135.037 245.261 128.547 245.261 120.541C245.261 112.536 238.702 106.046 230.61 106.046C222.519 106.046 215.96 112.536 215.96 120.541C215.96 128.547 222.519 135.037 230.61 135.037Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M209.589 192.398C217.68 192.398 224.239 185.908 224.239 177.902C224.239 169.897 217.68 163.407 209.589 163.407C201.498 163.407 194.938 169.897 194.938 177.902C194.938 185.908 201.498 192.398 209.589 192.398Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M266.725 15.4436V1.82996H296.636V15.4436" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
            </svg>

            <div className="flex flex-col">
              <h3 className="font-bold text-xl mb-2">Wie wird Ihr Gebrauchter angetrieben?</h3>

              <div className={''}>
                <SelectInput required={true} classes={'w-full'} value={this.state.fuel ? this.state.fuel.toString() : ''} label={'Kraftstoff'} options={this.state.fuels} onChange={this.changeFuel}/>
              </div>

              <div className={'mt-3'}>
                <TextField required={true} type={'number'} id="power" label="Leistung (KW)" className={'w-full'} value={this.state.power || ''} onChange={event => this.changePower(event.target.value)}/>
              </div>

              <div className={'mt-3'}>
                <SelectInput required={true} classes={'w-full'} value={this.state.transmission ? this.state.transmission.toString() : ''} label={'Getriebe'} options={this.state.transmissions} onChange={this.changeTransmission}/>
              </div>

              <label className="my-8 flex flex-row w-full">
                <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                  <input type="checkbox" className="opacity-0 absolute" checked={this.state.fourWheelDrive} onChange={event => this.changeFourWheelDrive(event.target.checked)}/>
                  <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                  </svg>
                </div>
                <div className="select-none">Allrad Antrieb</div>
              </label>

              <div className="mt-12">
                <div className="flex flex-col-reverse md:block w-full md:w-auto">
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-vehicle-makes-clicked'} to={'vehicle-makes'} title={'Schritt zurück'} active={true}/>
                  <PrimaryButtonLink buttonId={'button-to-vehicle-exterior'} event={'button-to-vehicle-exterior-clicked'} to={'vehicle-exterior'} title={'Nächster Schritt'} active={!!(this.state.fuel && this.state.power && this.state.transmission)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VehicleTransmission
