import React, {useState} from 'react'
import {classNames} from '../utils/class-names'

type SkeletonProps = {
  show: boolean
}

export function ImageSkeleton({show}: SkeletonProps) {
  return show ? (
    <></>
  ) : null
}

type FallbackBannerProps = {
  show: boolean
}

export function FallbackBanner({show}: FallbackBannerProps) {
  return show ? (
    <>
      <div className="font-semibold text-sm lg:text-lg w-full text-right">&nbsp;</div>
    </>
  ) : null
}

type BannerProps = {
  src: string
  alt: string
}

export function Banner({src, alt}: BannerProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMissing, setIsMissing] = useState(false)

  return (
    <>
      <ImageSkeleton show={!isLoaded && !isMissing}/>

      <FallbackBanner show={isMissing}/>

      <div className={classNames("shadow rounded w-full max-w-full h-40 overflow-hidden", !isLoaded || isMissing ? 'hidden' : '')}>
        <img src={src} className={classNames('max-w-full w-full h-full')} onLoad={() => setIsLoaded(true)} onError={() => setIsMissing(true)} alt={alt}/>
      </div>
    </>
  )
}
