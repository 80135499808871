import Header from 'components/Header'
import ItemSelection from 'components/ItemSelection'
import PrimaryButtonLink from 'components/PrimaryButtonLink'
import {LocationMatch} from 'components/Router'
import TertiaryButtonLink from 'components/TertiaryButtonLink'
import {HeadlightType, TrailerCouplingType} from 'domain-models'
import {isFlagSet, removeFlag, setFlag, track} from 'hooks'
import _ from 'lodash'
import React from 'react'
import {VehicleFlags} from 'redux/reducers/VehicleReducer'
import Tooltip from "../components/Tooltip"
import {SectionHead} from "../components/SectionHead"
import InfoIcon from "../components/InfoIcon"

type VehicleExteriorProps = {
  match: LocationMatch
}


type VehicleExteriorState = {
  trailerCoupling: number
  headlightType: number
  alloyWheels: boolean
  panoramaRoof: boolean
  pushableRoof: boolean
  airSuspension: boolean
}

class VehicleExterior extends React.Component<VehicleExteriorProps, VehicleExteriorState> {
  trailerCouplings = [
    {
      title: 'Nein',
      value: 0,
      flag: 'EquipmentFlags1',
    },
    {
      title: 'Fest',
      value: 512,
      flag: 'EquipmentFlags1',
    },
    {
      title: 'Abnehmbar',
      value: 262144,
      flag: 'EquipmentFlags5',
    },
    {
      title: 'Schwenkbar',
      value: 524288,
      flag: 'EquipmentFlags5',
    },
  ]
  headlightTypes = [
    {
      title: 'Nein',
      value: 0,
      flag: 'EquipmentFlags1',
    },
    {
      title: 'Xenonscheinwerfer',
      value: 16,
      flag: 'EquipmentFlags1',
    },
    {
      title: 'Bi-Xenon Scheinwerfer',
      value: 268435456,
      flag: 'EquipmentFlags5',
    },
    {
      title: 'LED-Scheinwerfer',
      value: 2,
      flag: 'EquipmentFlags1',
    },
    {
      title: 'Laserlicht',
      value: 536870912,
      flag: 'EquipmentFlags5',
    },
  ]

  constructor(props: VehicleExteriorProps) {
    super(props)

    this.state = {
      trailerCoupling: _.get(_.find(this.trailerCouplings, (type: TrailerCouplingType) => {
        return type.flag && type.value && isFlagSet(type.flag, type.value)
      }), 'value', 0),
      headlightType: _.get(_.find(this.headlightTypes, (type: HeadlightType) => {
        return type.flag && type.value && isFlagSet(type.flag, type.value)
      }), 'value', 0),
      alloyWheels: isFlagSet('EquipmentFlags1', 128),
      panoramaRoof: isFlagSet('EquipmentFlags1', 32),
      pushableRoof: isFlagSet('EquipmentFlags1', 64),
      airSuspension: isFlagSet('EquipmentFlags0', 1048576),
    }
  }

  selectTrailerCoupling = async (trailerCoupling: number) => {
    await removeFlag('EquipmentFlags1', 512)
    await removeFlag('EquipmentFlags5', 262144)
    await removeFlag('EquipmentFlags5', 524288)

    const type = _.find(this.trailerCouplings, {value: trailerCoupling})
    type && await setFlag(type.flag as keyof VehicleFlags, type.value)

    track('trailer-coupling-selected', {flag: type ? type.flag : null, value: type ? type.value : null})

    this.setState({trailerCoupling})
  }

  selectHeadlightType = async (headlightType: number) => {
    await removeFlag('EquipmentFlags1', 16)
    await removeFlag('EquipmentFlags1', 2)
    await removeFlag('EquipmentFlags5', 268435456)
    await removeFlag('EquipmentFlags5', 536870912)

    const type = _.find(this.headlightTypes, {value: headlightType})
    type && await setFlag(type.flag as keyof VehicleFlags, type.value)

    track('headlight-type-selected', {flag: type ? type.flag : null, value: type ? type.value : null})

    this.setState({headlightType})
  }

  changeAlloyWheels = async (alloyWheels: boolean) => {
    alloyWheels && await setFlag('EquipmentFlags1', 128)
    !alloyWheels && await removeFlag('EquipmentFlags1', 128)

    track('allow-wheels-selected', {flag: 'EquipmentFlags1', value: 128, selected: alloyWheels})

    this.setState({alloyWheels: alloyWheels})
  }
  changePanoramaRoof = async (panoramaRoof: boolean) => {
    panoramaRoof && await setFlag('EquipmentFlags1', 32)
    !panoramaRoof && await removeFlag('EquipmentFlags1', 32)

    track('panorama-roof-selected', {flag: 'EquipmentFlags1', value: 32, selected: panoramaRoof})

    this.setState({panoramaRoof: panoramaRoof})
  }
  changePushableRoof = async (pushableRoof: boolean) => {
    pushableRoof && await setFlag('EquipmentFlags1', 64)
    !pushableRoof && await removeFlag('EquipmentFlags1', 64)

    track('sunroof-selected', {flag: 'EquipmentFlags1', value: 64, selected: pushableRoof})

    this.setState({pushableRoof: pushableRoof})
  }
  changeAirSuspension = async (airSuspension: boolean) => {
    airSuspension && await setFlag('EquipmentFlags0', 1048576)
    !airSuspension && await removeFlag('EquipmentFlags0', 1048576)

    track('air-suspension-selected', {flag: 'EquipmentFlags0', value: 1048576, selected: airSuspension})

    this.setState({airSuspension: airSuspension})
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={2} match={this.props.match}/>
        <div className="mt-4 md:mt-6 lg:mt-24 mx-auto block">
          <SectionHead title={'Machen Sie Angaben zum Exterieur Ihres Fahrzeuges'} procedure={'manual'}/>

          <div className="pt-8 flex flex-col lg:flex-row">
            <svg width="570" height="181" viewBox="0 0 570 181" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full lg:w-auto">
              <path d="M124.373 178.294C145.758 178.294 163.094 160.981 163.094 139.625C163.094 118.268 145.758 100.955 124.373 100.955C102.988 100.955 85.6514 118.268 85.6514 139.625C85.6514 160.981 102.988 178.294 124.373 178.294Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M451.512 164.646C465.35 164.646 476.567 153.444 476.567 139.625C476.567 125.806 465.35 114.603 451.512 114.603C437.675 114.603 426.457 125.806 426.457 139.625C426.457 153.444 437.675 164.646 451.512 164.646Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M124.942 164.646C138.779 164.646 149.997 153.444 149.997 139.625C149.997 125.806 138.779 114.603 124.942 114.603C111.104 114.603 99.8867 125.806 99.8867 139.625C99.8867 153.444 111.104 164.646 124.942 164.646Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M451.511 178.294C472.897 178.294 490.233 160.981 490.233 139.625C490.233 118.268 472.897 100.955 451.511 100.955C430.126 100.955 412.79 118.268 412.79 139.625C412.79 160.981 430.126 178.294 451.511 178.294Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M185.815 65.7432L430.637 54.512C433.262 54.3926 435.745 53.295 437.607 51.4412L442.111 46.943C445.476 43.5822 446.171 38.3845 443.797 34.2617L438.017 24.219C434.538 18.1684 428.228 14.2787 421.253 13.8863L335.621 10.7586C291.217 10.5368 252.228 17.4006 216.752 39.6925L184.084 60.2271C181.482 61.8649 182.746 65.8854 185.815 65.7432Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M503.045 61.1484L490.518 81.6205L518.551 80.3467C523.738 80.1136 527.662 75.5756 527.149 70.4177C526.711 66.0447 523.192 62.6213 518.801 62.3028L503.045 61.1484Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M34.9717 102.661L40.0966 106.073L67.4123 104.401C67.7938 104.379 68.1639 104.242 68.4657 104.009L86.9324 89.9343C88.1226 89.0245 87.872 87.1649 86.4769 86.6076L79.3704 83.77C74.9857 82.0185 70.1057 81.973 65.6869 83.6449L39.5271 93.5625" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M347.881 11.4468L334.545 58.9193" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M209.645 49.792C214.964 46.5904 221.746 50.4119 221.746 56.6104C221.746 60.7958 218.5 64.2703 214.32 64.5547L190.427 65.692" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M203.188 147.359L322.461 144.464C329.579 144.293 335.717 139.426 337.5 132.539L350.192 83.5312C351.098 80.0225 351.229 76.3603 350.568 72.8004L347.875 58.3107L357.84 11.1055" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M315.702 73.0905L341.327 71.9531" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M170.497 156.116H404.534" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M496.686 47.2556C499.602 51.333 504.317 53.7555 509.333 53.7555H516.144L489.711 24.4122C487.547 21.984 487.456 18.3502 489.489 15.8082L490.798 14.3411C491.163 13.9316 490.918 13.2776 490.371 13.2037C343.201 -6.44949 274.072 6.56734 251.927 11.674C229.719 16.792 163.095 58.3049 163.095 58.3049C51.4863 61.1482 38.9588 93.5624 38.9588 93.5624L31.7611 107.148C29.8479 111.76 28.709 114.404 28.709 118.339V133.898" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M497.352 151.567L526.962 145.88C526.962 145.88 538.351 139.625 537.212 104.936C537.212 104.936 526.962 104.367 526.962 93.5625" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
              <path d="M28.708 148.155L61.8035 155.502C63.6484 155.911 65.5389 156.116 67.4295 156.116H78.2487" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="3" strokeMiterlimit="10"/>
            </svg>

            <div className="flex flex-col ml-4">
              <h3 className="font-bold text-xl mb-2">Hat Ihr Gebrauchter eine Anhängerkupplung?</h3>
              <div className="inline-flex w-full flex-wrap -mx-2">
                {
                  this.trailerCouplings.map((coupling: { title: string, value: number }) => {
                    return (
                      <div className={''} key={'trailerCoupling.' + coupling.title}>
                        <ItemSelection variant={ItemSelection.variant.SLIM} key={coupling.title} onClick={() => this.selectTrailerCoupling(coupling.value)} checked={this.state.trailerCoupling === coupling.value} icon={''} title={coupling.title}/>
                      </div>
                    )
                  })
                }
              </div>

              <h3 className="font-bold text-xl mb-2 mt-6">Hat Ihr Gebrauchter besondere Scheinwerfer?</h3>

              <div className="flex flex-row flex-wrap -mx-2">
                {
                  this.headlightTypes.map((headlight) => {
                    return (
                      <div className={''} key={'headlight.' + headlight.title}>
                        <ItemSelection variant={ItemSelection.variant.SLIM} key={headlight.title} onClick={() => this.selectHeadlightType(headlight.value)} checked={this.state.headlightType === headlight.value} icon={''} title={headlight.title}/>
                      </div>
                    )
                  })
                }
              </div>

              <h3 className="font-bold text-xl mb-2 mt-6">Sonstige Außenausstattung</h3>

              <div className="flex flex-row flex-wrap mt-3">
                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.alloyWheels} onChange={event => this.changeAlloyWheels(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none hidden sm:block">Leichtmetallfelgen</div>
                  <div className="select-none sm:hidden flex flex-col">
                    <span>Leichtmetall -</span>
                    <span>felgen</span>
                  </div>
                </label>

                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.panoramaRoof} onChange={event => this.changePanoramaRoof(event.target.checked)}/>

                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">Panorama-Dach</div>
                </label>
              </div>

              <div className="flex flex-row flex-wrap mt-3">
                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.pushableRoof} onChange={event => this.changePushableRoof(event.target.checked)}/>

                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">Schiebedach</div>
                </label>

                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.airSuspension} onChange={event => this.changeAirSuspension(event.target.checked)}/>

                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">
                    <Tooltip label={<span className={'flex space-x-2'}>
                      <span>Luftfederung</span>
                      <InfoIcon/></span>}>
                      Luftfederung mit bspw. automatischer Niveauregelung, Höheneinstellung und elektronischer Dämpferregelung
                    </Tooltip>
                  </div>
                </label>
              </div>

              <div className={'mt-6 flex space-x-2'}>
                <span>Weitere Features addieren wir vor Ort zum Wert Ihres Gebrauchten.</span>
              </div>

              <div className="mt-12">
                <div className="flex flex-col-reverse md:block w-full md:w-auto">
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-vehicle-transmission-clicked'} to={'vehicle-transmission'} title={'Schritt zurück'} active={true}/>
                  <PrimaryButtonLink buttonId={'button-to-vehicle-interior'} event={'button-to-vehicle-interior-clicked'} to={'vehicle-interior'} title={'Nächster Schritt'} active={(this.state.trailerCoupling !== null && this.state.headlightType !== null)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VehicleExterior
