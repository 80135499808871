import {faAngleRight, faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import Header from 'components/Header'
import {history, LocationMatch} from 'components/Router'
import VinExplanation from 'components/VinExplanation'
import {createUrl, isAudiSite} from 'hooks'
import React, {useContext, useEffect} from 'react'
import {storeAppItem} from 'redux/actions'
import TertiaryButtonLink from '../components/TertiaryButtonLink'
import {classNames} from "../utils/class-names"
import {AppContext} from "../App"

type ProcedureSelectionProps = {
  match: LocationMatch
}

export default function ProcedureSelection({match}: ProcedureSelectionProps) {
  const {app} = useContext(AppContext)

  useEffect(() => {
    if (app?.config?.disableManualRequests) {
      history.push(createUrl('/vehicle-vin', match))
    }
  }, [app.config])

  return <div className="px-6 lg:px-16 block">
    <Header step={2} match={match}/>
    <div className="mt-4 md:mt-6 lg:mt-24 mx-auto block">
      {
        app.dealer && app.dealer.Firm ?
          <div className={'font-semibold mb-1 text-lg'}>Ihr ausgewählter Partner: {app.dealer?.Firm}</div>
          : null
      }
      <h3 className="text-lg lg:text-3xl font-bold">Starten Sie Ihre Fahrzeugbewertung</h3>
      <div className="mt-2 md:mt-12 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 pr-0 lg:pr-2">
          <button onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            storeAppItem('procedure', 'vehicle-vin')
            history.push(createUrl('/vehicle-vin', match))
          }} className={classNames('border border-solid shadow rounded text-gray-800 text-left border-gray-400 px-3 sm:px-4 md:px-6 py-6 flex flex-row justify-between h-full w-full cursor-pointer', isAudiSite() ? '' : 'hover:bg-gray-100')} id="button-select-procedure-vin">
            <div className="block ml-3">
              <p>Schnell & Exakt</p>
              <p className="font-bold">Fahrgestellnummer/FIN eingeben</p>
              <p className="mt-1">
                <Icon icon={faCheck} fixedWidth={true}/> Enthält Angaben zu Modell, Motor und Ausstattung
              </p>
            </div>
            <span className={'text-3xl h-full flex items-center'}>
              <Icon icon={faAngleRight}/>
            </span>
          </button>
        </div>

        <span className={'-mt-2 ml-1 lg:ml-0 lg:mt-0 block lg:hidden'}>
          <VinExplanation/>
        </span>

        <div className="w-full lg:w-1/2 pl-0 lg:pl-2 mt-6 lg:mt-0">
          <button onClick={() => {
            storeAppItem('procedure', 'manual')
            history.push(createUrl('/vehicle-makes', match))
          }} className={classNames('border border-solid shadow rounded text-gray-800 text-left border-gray-400 px-3 sm:px-4 md:px-6 py-6 flex flex-row justify-between h-full w-full cursor-pointer', isAudiSite() ? '' : 'hover:bg-gray-100')} id="button-select-procedure-manual">
            <div className="block ml-3">
              <p>Alternativ</p>
              <p className="font-bold">Fahrzeugdaten manuell eingeben</p>
              <p className="mt-1">Sie wählen Ihre Marke, Modell und Ausstattung aus und bestimmen den Wert Ihres Gebrauchten manuell.</p>
            </div>
            <span className={'text-3xl h-full flex items-center'}>
              <Icon icon={faAngleRight}/>
            </span>
          </button>
        </div>
      </div>

      <span className={'pt-4 ml-1 lg:mt-0 hidden lg:block'}>
        <VinExplanation/>
      </span>

      {
        match.params.domain ? null :
          <div className={'w-full mt-20'}>
            <TertiaryButtonLink buttonId={'button-back-to-select-dealer'} event={'button-back-to-dealer-selection-clicked'} to={'select-dealer'} title={'Zurück zur Händlerauswahl'} active={true}/>
          </div>
      }
    </div>
  </div>
}
