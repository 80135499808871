import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import React from 'react'

const labels: { [index: string]: string } = {
  1: 'Ich kam gar nicht klar',
  2: 'Zu kompliziert',
  3: 'Es war okay',
  4: 'Recht einfach',
  5: 'Ein Kinderspiel für mich!',
}
type HoverRatingProps = {
  onChange?: (rating: number | null) => void
  disabled: boolean
  rating?: number
}

export default function HoverRating(props: HoverRatingProps) {
  const [value, setValue] = React.useState<number | null>(0)
  const [hover, setHover] = React.useState(-1)

  if (props.rating && props.rating !== value) {
    setValue(props.rating)
  }

  const handleRating = (newValue: number | null) => {
    setValue(newValue)
    props.onChange && props.onChange(newValue)
  }

  return (
    <div className={'flex flex-col bg-skin-modal border border-solid border-skin-button-fill text-skin-modal-inverted rounded py-6 lg:py-12 px-6 lg:px-12 lg:items-center'}>
      <span className={'text-sm'}>Helfen Sie uns, unseren Service zu verbessern</span>
      <span className={'text-xl lg:text-3xl font-bold w-full lg:max-w-2xl'}>Wie einfach empfanden Sie den Vorgang, bis Sie die Preisbewertung erhalten haben?</span>
      <div className={'w-full lg:max-w-2xl mt-2 flex flex-col lg:hidden items-center'}>
        <Rating size={'small'} name="hover-feedback" value={value} precision={1} disabled={props.disabled} onChange={(event, newValue) => {
          handleRating(newValue)
        }} onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}/>

        {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
      </div>

      <div className={'w-full lg:max-w-2xl mt-2 flex-col hidden lg:flex items-center'}>
        <Rating size={'large'} name="hover-feedback" value={value} precision={1} disabled={props.disabled} onChange={(event, newValue) => {
          handleRating(newValue)
        }} onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}/> {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]} &nbsp;</Box>}
      </div>
    </div>
  )
}