import {FormGroup, TextField} from '@material-ui/core'
import Header from 'components/Header'
import PrimaryButtonLink from 'components/PrimaryButtonLink'
import RegistrationDatePicker from 'components/RegistrationDatePicker'
import {LocationMatch} from 'components/Router'
import TertiaryButtonLink from 'components/TertiaryButtonLink'
import VinExplanation from 'components/VinExplanation'
import {track} from 'hooks'
import _ from 'lodash'
import React, {ChangeEvent} from 'react'
import {getStoredAppBoolean, getStoredVehicleBoolean, getStoredVehicleNumber, getStoredVehicleString, storeAppItem, storeVehicleItem} from 'redux/actions'
import Tooltip from "../components/Tooltip"
import {SectionHead} from "../components/SectionHead"
import InfoIcon from "../components/InfoIcon"
import {AppContext} from "../App"

type VehicleVinProps = { match: LocationMatch }

type VehicleVinState = {
  registrationDate: string | null
  vin: string | null
  vinValid: boolean
  kilometers: number | null
  prevOwners: number | null
  vatable: boolean
}

class VehicleVin extends React.Component<VehicleVinProps, VehicleVinState> {
  constructor(props: VehicleVinProps) {
    super(props)

    const vin = getStoredVehicleString('vin')
    const prevOwners = getStoredVehicleNumber('prevOwners')

    this.state = {
      registrationDate: getStoredVehicleString('registrationDate') || null,
      vin: vin ? vin.toUpperCase() : '',
      vinValid: getStoredAppBoolean('vinValid'),
      prevOwners: (prevOwners === 0 ? 1 : (prevOwners || 1)),
      kilometers: getStoredVehicleNumber('kilometers'),
      vatable: getStoredVehicleBoolean('vatable', false)
    }
  }

  componentWillMount() {
    storeAppItem('procedure', 'vehicle-vin')
  }

  componentDidMount() {
    this.isVinValid()
  }

  isVinValid = () => {
    let valid = true

    const vin = this.state.vin || ''

    if (vin && vin.length && vin.length < 17) {
      valid = false
    }

    const last5 = vin.substring(vin.length - 5)
    if (vin && vin.length && (!/^[0-9]+$/.test(last5) || last5.toString().length !== 5)) {
      valid = false
    }

    storeAppItem('vinValid', valid)
    this.setState({vinValid: valid})
  }

  changeKilometers = (event: ChangeEvent<HTMLInputElement>) => {
    let kilometers = _.toInteger(event.target.value.slice(0, 6))

    if (kilometers < 0) {
      kilometers = kilometers * -1
    }

    storeVehicleItem('kilometers', kilometers)

    this.setState({
      kilometers: kilometers || 0,
    })
  }

  changeVin = async (event: ChangeEvent<HTMLInputElement>) => {
    let vin = event.target ? _.toString(event.target.value).toUpperCase().slice(0, 17) : ''
    vin = vin.replace(/([^a-z0-9]+)/gi, '')
    await storeVehicleItem('vin', vin)

    await this.setState({
      vin,
    })

    this.isVinValid()
  }

  changePrevOwners = (event: ChangeEvent<HTMLInputElement>) => {
    let prevOwners = _.toInteger(event.target.value.slice(0, 2))

    if (prevOwners < 0) {
      prevOwners = prevOwners * -1
    }

    storeVehicleItem('prevOwners', prevOwners)
    this.setState({
      prevOwners: prevOwners || 0,
    })
  }

  changeVatable = async (vatable: boolean) => {
    track('vatable-selected', {selected: vatable})

    storeVehicleItem('vatable', vatable)

    this.setState({vatable: vatable})
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={2} match={this.props.match}/>
        <div className="mt-4 md:mt-6 lg:mt-24 mx-auto block">
          <SectionHead title={'Identifizieren Sie Ihren Gebrauchten mit der Fahrgestellnummer/FIN'} procedure={'vin'}/>

          <div className="flex flex-col lg:flex-row w-full mt-8">
            <FormGroup row={true} className={'w-full overflow-hidden'}>
              <TextField required={true} error={!this.state.vinValid} type={'text'} id="vin-select" className={'w-full lg:w-2/5'} label="Fahrgestellnummer (17 Stellen)" value={this.state.vin} onChange={this.changeVin} inputProps={{
                pattern: '[A-Za-z0-9]{17}',
              }}/>

              <div className={'w-full lg:w-3/5 lg:mt-6 lg:pl-4'}>
                <VinExplanation/>
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="mt-12 flex flex-col lg:flex-row w-full">
          <div className="flex flex-col w-full lg:w-2/5">
            <h4 className="font-bold text-xl">Kilometerstand eingeben</h4>
            <FormGroup row={true} className={'w-full mt-2'}>
              <TextField inputProps={{
                min: 0,
                max: 999999,
              }} required={true} type={'number'} id="kilometer-select" className={'w-full'} label="Kilometerstand" value={this.state.kilometers || ''} onChange={this.changeKilometers}/>
            </FormGroup>
          </div>
          <div className="flex flex-col mt-6 lg:mt-0 justify-between lg:ml-3">
            <h4 className="font-bold text-xl">Wann wurde Ihr Gebrauchter zuerst zugelassen?</h4>
            <FormGroup row={true} className={'w-full mt-2'}>
              <RegistrationDatePicker registrationDate={this.state.registrationDate} onChange={() => this.setState({registrationDate: getStoredVehicleString('registrationDate') || null})}/>
            </FormGroup>
          </div>
        </div>

        <div className="mt-10 flex flex-col lg:flex-row w-full">
          <div className="flex flex-col w-full">
            <h4 className="font-bold text-xl">Auf wie viele Halter wurde das Fahrzeug bereits zugelassen?</h4>
            <FormGroup row={true} className={'w-full lg:w-2/5 mt-2'}>
              <TextField required={true} type={'number'} id="prev-owner-input" className={'w-full'} label="Anzahl Halter" value={this.state.prevOwners || ''} onChange={this.changePrevOwners}/>
            </FormGroup>
          </div>
        </div>

        <div className="my-8 flex flex-col lg:flex-row w-full">
          <div className="flex flex-row w-full space-x-2">
            <label className="flex justify-start items-start cursor-pointer">
              <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                <input type="checkbox" className="opacity-0 absolute" checked={this.state.vatable} onChange={event => this.changeVatable(event.target.checked)}/>

                <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                </svg>
              </div>
              <div className="select-none">Firmenfahrzeug</div>
            </label>
            <Tooltip label={(<InfoIcon/>)}>
              Das Fahrzeug ist regelbesteuert und somit kann die MwSt. ausgewiesen werden.
            </Tooltip>
          </div>
        </div>

        <div className="mt-12">
          <div className="flex flex-col-reverse md:block w-full md:w-auto gap-4 md:space-x-4">
            <AppContext.Consumer>
              {({app}) => {
                return app.config?.disableManualRequests && this.props.match.params?.domain ? null :
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-procedure-selection-clicked'} to={app.config?.disableManualRequests ? 'select-dealer' : 'procedure-selection'} title={'Zurück zur Auswahl'} active={true}/>
              }}
            </AppContext.Consumer>
            <PrimaryButtonLink margin={'m-0'} buttonId={'button-to-contact'} event={'button-to-contact-clicked'} to={'contact'} title={'Weiter zu Kontaktdaten'} active={!!(this.state.vinValid && this.state.kilometers && this.state.registrationDate && this.state.prevOwners)}/>
          </div>
        </div>
      </div>
    )
  }
}

export default VehicleVin
