import Header from 'components/Header'
import ItemSelection from 'components/ItemSelection'
import PrimaryButtonLink from 'components/PrimaryButtonLink'
import {LocationMatch} from 'components/Router'
import TertiaryButtonLink from 'components/TertiaryButtonLink'
import {SpeedometerTypes} from 'domain-models'
import {isFlagSet, removeFlag, setFlag} from 'hooks'
import _ from 'lodash'
import React from 'react'
import {getStoredVehicleNumber, storeVehicleItem} from 'redux/actions'
import {VehicleFlags} from 'redux/reducers/VehicleReducer'
import {FormGroup, TextField} from "@material-ui/core"
import Tooltip from "../components/Tooltip"
import {SectionHead} from "../components/SectionHead"
import InfoIcon from "../components/InfoIcon"

type VehicleInteriorProps = {
  match: LocationMatch
}


type VehicleInteriorState = {
  interior: number
  numSeats: number | null
  speedometer: number
  navigationSystem: boolean
  seatHeating: boolean
  auxiliaryHeating: boolean
  headUpDisplay: boolean
  digital: boolean
}

class VehicleInterior extends React.Component<VehicleInteriorProps, VehicleInteriorState> {

  interiors = [
    {
      title: 'Stoff',
      value: 3,
    },
    {
      title: 'Velours',
      value: 4,
    },
    {
      title: 'Alcantara',
      value: 5,
    },
    {
      title: 'Teilleder',
      value: 2,
    },
    {
      title: 'Vollleder',
      value: 6,
    },
  ]
  speedometers = [
    {
      title: 'Nein',
      value: 0,
      flag: 'EquipmentFlags0',
    },
    {
      title: 'Tempomat',
      value: 32768,
      flag: 'EquipmentFlags0',
    },
    {
      title: 'Abstandstempomat',
      value: 1048576,
      flag: 'EquipmentFlags6',
    },
  ]

  constructor(props: VehicleInteriorProps) {
    super(props)
    this.state = {
      interior: getStoredVehicleNumber('interior', 3),
      numSeats: getStoredVehicleNumber('numSeats', undefined) || null,
      speedometer: _.get(_.find(this.speedometers, (type: SpeedometerTypes) => {
        return type.flag && type.value && isFlagSet(type.flag, type.value)
      }), 'value', 0),
      navigationSystem: isFlagSet('EquipmentFlags2', 16),
      seatHeating: isFlagSet('EquipmentFlags2', 2097152),
      auxiliaryHeating: isFlagSet('EquipmentFlags2', 16384),
      headUpDisplay: isFlagSet('EquipmentFlags0', 16777216),
      digital: isFlagSet('EquipmentFlags6', 2048),
    }
  }

  selectInterior = (interior: number) => {
    storeVehicleItem('interior', interior)
    this.setState({interior})
  }

  changeNumSeats = (numSeats: string) => {
    storeVehicleItem('numSeats', numSeats)
    this.setState({numSeats: numSeats ? _.toInteger(numSeats) : null})
  }

  selectSpeedometer = async (speedometer: number) => {
    await removeFlag('EquipmentFlags0', 32768)
    await removeFlag('EquipmentFlags6', 1048576)

    const type = _.find(this.speedometers, {value: speedometer})
    type && await setFlag(type.flag as keyof VehicleFlags, type.value)

    this.setState({speedometer})
  }

  changeNavigationSystem = async (navigationSystem: boolean) => {
    navigationSystem && await setFlag('EquipmentFlags2', 16)
    !navigationSystem && await removeFlag('EquipmentFlags2', 16)

    this.setState({navigationSystem: navigationSystem})
  }
  changeSeatHeating = async (seatHeating: boolean) => {
    seatHeating && await setFlag('EquipmentFlags2', 2097152)
    !seatHeating && await removeFlag('EquipmentFlags2', 2097152)

    this.setState({seatHeating: seatHeating})
  }
  changeAuxiliaryHeating = async (auxiliaryHeating: boolean) => {
    auxiliaryHeating && await setFlag('EquipmentFlags2', 16384)
    !auxiliaryHeating && await removeFlag('EquipmentFlags2', 16384)

    this.setState({auxiliaryHeating: auxiliaryHeating})
  }
  changeHeadUpDisplay = async (headUpDisplay: boolean) => {
    headUpDisplay && await setFlag('EquipmentFlags0', 16777216)
    !headUpDisplay && await removeFlag('EquipmentFlags0', 16777216)

    this.setState({headUpDisplay: headUpDisplay})
  }
  changeDigital = async (digital: boolean) => {
    digital && await setFlag('EquipmentFlags6', 2048)
    !digital && await removeFlag('EquipmentFlags6', 2048)

    this.setState({digital: digital})
  }

  render() {
    return (
      <div className="px-6 lg:px-16">
        <Header step={2} match={this.props.match}/>
        <div className="mt-4 md:mt-6 lg:mt-24 mx-auto block">
          <SectionHead title={'Machen Sie Angaben zum Interieur Ihres Fahrzeuges'} procedure={'manual'}/>

          <div className="pt-8 flex flex-col lg:flex-row">
            <svg width="471" height="238" viewBox="0 0 471 238" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full lg:w-auto">
              <path d="M79.5 47.0996C79.5 36.6992 87.9312 28.2681 98.3316 28.2681H155.347C165.748 28.2681 174.179 36.6993 174.179 47.0996V60.9733H79.5V47.0996Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2"/>
              <path d="M34.6808 89.9601H19.0896C9.58885 89.9601 1.88867 82.256 1.88867 72.7505V43.3421C1.88867 33.8366 9.58885 26.1326 19.0896 26.1326H29.5294C40.4492 26.1326 50.9039 21.7243 58.5296 13.9039C66.1552 6.08357 76.6099 1.67529 87.5297 1.67529H140.426C151.346 1.67529 161.801 6.08357 169.426 13.9039C177.052 21.7243 187.507 26.1326 198.427 26.1326H451.826C461.327 26.1326 469.027 33.8366 469.027 43.3421V72.7475C469.027 82.253 461.327 89.9571 451.826 89.9571H305.35C298.061 89.9571 291.756 95.0335 290.194 102.156L278.377 156.075C273.974 176.163 271.753 196.668 271.753 217.233V237.897" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M202.891 237.896V217.108C202.891 196.629 200.688 176.208 196.323 156.2L194.856 149.478L184.533 102.177C182.977 95.0482 176.669 89.9629 169.374 89.9629" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M55.7661 32.9927H21.7615C15.2329 32.9927 9.93848 38.2898 9.93848 44.8216V70.6748C9.93848 77.2067 15.2329 82.5037 21.7615 82.5037H34.0884" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M241.115 155.594V183.78C241.115 186.497 238.912 188.701 236.196 188.701C233.48 188.701 231.277 186.497 231.277 183.78V155.594" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M238.178 156.191H233.319C229.229 156.191 225.911 152.872 225.911 148.779V137.356C225.911 133.264 229.229 129.944 233.319 129.944H238.178C242.268 129.944 245.586 133.264 245.586 137.356V148.779C245.586 152.872 242.268 156.191 238.178 156.191Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M242.077 166.332H252.144C255.271 166.332 257.808 168.87 257.808 171.999V197.053C257.808 200.182 255.271 202.72 252.144 202.72H221.439C218.311 202.72 215.774 200.182 215.774 197.053V171.999C215.774 168.87 218.311 166.332 221.439 166.332H230.856" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M257.51 176.584H265.559V200.284C265.559 205.974 260.947 210.586 255.262 210.586H218.917C213.229 210.586 208.62 205.971 208.62 200.284V176.584H215.775" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M207.499 89.0071H202.321C198.43 89.0071 195.276 85.8515 195.276 81.9592V68.1289H281.132V81.9592C281.132 85.8515 277.978 89.0071 274.088 89.0071H268.313" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M268.314 68.1289H207.5V106.604H268.314V68.1289Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M197.25 102.932C199.391 102.932 201.126 101.196 201.126 99.0546C201.126 96.9132 199.391 95.1772 197.25 95.1772C195.11 95.1772 193.375 96.9132 193.375 99.0546C193.375 101.196 195.11 102.932 197.25 102.932Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M278.266 103.733C280.406 103.733 282.141 101.997 282.141 99.8559C282.141 97.7145 280.406 95.9785 278.266 95.9785C276.126 95.9785 274.391 97.7145 274.391 99.8559C274.391 101.997 276.126 103.733 278.266 103.733Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M213.666 129.312C217.782 129.312 221.118 125.974 221.118 121.856C221.118 117.738 217.782 114.399 213.666 114.399C209.55 114.399 206.213 117.738 206.213 121.856C206.213 125.974 209.55 129.312 213.666 129.312Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M261.962 129.312C266.078 129.312 269.414 125.974 269.414 121.856C269.414 117.738 266.078 114.399 261.962 114.399C257.845 114.399 254.509 117.738 254.509 121.856C254.509 125.974 257.845 129.312 261.962 129.312Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M19.2686 39.1621H45.8003" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M14.4424 44.8711L43.3282 44.8711" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M14.7051 50.48H38.852" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M286.043 57.033C286.043 57.4893 285.673 57.8593 285.216 57.8593H189.387C188.931 57.8593 188.561 57.4893 188.561 57.033V42.9284C188.561 37.9952 192.555 33.9985 197.486 33.9985H277.054C282.018 33.9985 286.043 38.025 286.043 42.9911V57.033Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M195.831 40.0635H230.412" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M195.258 45.6724H230.412" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M195.258 51.2812H230.412" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M243.624 40.0635H279.397" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M243.624 45.6724H279.581" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M243.624 51.2812H279.581" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M462.052 55.4555H416.561V32.6943H453.064C458.028 32.6943 462.052 36.5839 462.052 41.381V55.4555V55.4555Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M420.436 38.4609H455.016" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M420.436 44.0698H457.699" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M420.436 49.6792H457.401" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M299.701 69.9771H459.786" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M23.5595 73.7253C27.8401 73.7253 31.3103 70.2534 31.3103 65.9706C31.3103 61.6877 27.8401 58.2158 23.5595 58.2158C19.2788 58.2158 15.8086 61.6877 15.8086 65.9706C15.8086 70.2534 19.2788 73.7253 23.5595 73.7253Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M102.481 7.77941C64.6862 7.71081 33.7871 38.5628 33.7871 76.3791C33.7871 100.938 46.6893 122.481 66.0784 134.599C67.9058 135.742 70.0254 136.329 72.1807 136.329H132.527C134.668 136.329 136.778 135.751 138.594 134.617C158.001 122.502 170.918 100.953 170.918 76.3791C170.918 38.5359 140.29 7.85099 102.481 7.77941ZM102.352 17.9202C118.456 17.9202 133.022 24.5177 143.495 35.1566C151.74 43.5318 157.449 54.4152 159.378 66.5574H123.649C119.395 59.0651 111.355 54.0096 102.129 54.0096C92.9023 54.0096 84.8623 59.0651 80.6083 66.5574H45.327C49.7062 38.9923 73.5669 17.9202 102.352 17.9202ZM46.26 89.4816H79.8183C81.9796 93.9764 85.4615 97.7165 89.7572 100.204V120.432C89.7572 123.611 87.1816 126.188 84.0037 126.188H77.1919C75.1588 126.188 73.1704 125.604 71.4563 124.512C59.0519 116.638 49.8523 104.162 46.26 89.4816ZM127.51 126.188H121.446C118.268 126.188 115.693 123.611 115.693 120.432V99.4673C119.44 97.0037 122.481 93.5528 124.439 89.4816H158.445C154.853 104.159 145.653 116.635 133.246 124.512C131.531 125.604 129.543 126.188 127.51 126.188Z" fill="white" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M102.052 95.1694C111.108 95.1694 118.448 87.8249 118.448 78.7651C118.448 69.7053 111.108 62.3608 102.052 62.3608C92.997 62.3608 85.6562 69.7053 85.6562 78.7651C85.6562 87.8249 92.997 95.1694 102.052 95.1694Z" stroke="#001E50" className={'stroke-current text-skin-accent'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M70.988 82.9408H53.5217C52.1713 82.9408 51.0742 81.8462 51.0742 80.4921V75.8423C51.0742 74.4911 52.1683 73.3936 53.5217 73.3936H70.988C72.3384 73.3936 73.4355 74.4882 73.4355 75.8423V80.4921C73.4355 81.8462 72.3384 82.9408 70.988 82.9408Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M57.9326 73.3965V83.239" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M65.9824 73.3965V83.239" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M151.477 82.9408H134.011C132.661 82.9408 131.563 81.8462 131.563 80.4921V75.8423C131.563 74.4911 132.658 73.3936 134.011 73.3936H151.477C152.828 73.3936 153.925 74.4882 153.925 75.8423V80.4921C153.925 81.8462 152.828 82.9408 151.477 82.9408Z" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M138.424 73.3965V83.239" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M146.473 73.3965V83.239" stroke="#00B0F0" className={'stroke-current text-skin-accent-muted'} strokeWidth="2" strokeMiterlimit="10"/>
            </svg>

            <div className="flex flex-col lg:ml-8">
              <h3 className="font-bold text-xl mb-2">Wie viele Sitze hat Ihr Gebrauchter?</h3>
              <FormGroup row={true} className={'w-full lg:w-1/2'}>
                <TextField required={false} type={'number'} id="num-seats-input" className={'w-full'} label="Anzahl Sitze" value={this.state.numSeats || ''} onChange={event => this.changeNumSeats(event.target.value)}/>
              </FormGroup>

              <h3 className="font-bold text-xl mt-6 mb-2">Wie sind Ihre Sitze bezogen?</h3>
              <div className="flex flex-row flex-wrap -mx-2">
                {
                  this.interiors.map((interior: { title: string, value: number }) => {
                    return (
                      <div className={''} key={'interior.' + interior.title}>
                        <ItemSelection variant={ItemSelection.variant.SLIM} key={interior.title} onClick={() => this.selectInterior(interior.value)} checked={this.state.interior === interior.value} icon={''} title={interior.title}/>
                      </div>
                    )
                  })
                }
              </div>

              <h3 className="font-bold text-xl mb-2 mt-6">Hat Ihr Gebrauchter einen Tempomat?</h3>

              <div className="flex flex-row flex-wrap -mx-2">
                {
                  this.speedometers.map((speedometer: { title: string, value: number }) => {
                    return (
                      <div className={''} key={'speedometer.' + speedometer.title}>
                        <ItemSelection variant={ItemSelection.variant.SLIM} key={speedometer.title} onClick={() => this.selectSpeedometer(speedometer.value)} checked={this.state.speedometer === speedometer.value} icon={''} title={speedometer.title}/>
                      </div>
                    )
                  })
                }
              </div>

              <h3 className="font-bold text-xl mb-2 mt-6">Sonstige Innenausstattung</h3>

              <div className="flex flex-row flex-wrap mt-3">
                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.navigationSystem} onChange={event => this.changeNavigationSystem(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">Navigation</div>
                </label>

                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.seatHeating} onChange={event => this.changeSeatHeating(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">Sitzheizung</div>
                </label>
              </div>

              <div className="flex flex-row flex-wrap mt-3">
                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.auxiliaryHeating} onChange={event => this.changeAuxiliaryHeating(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">Standheizung</div>
                </label>

                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.headUpDisplay} onChange={event => this.changeHeadUpDisplay(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">
                    <Tooltip label={<span className={'flex space-x-2 truncate'}>
                      <span>Head-Up Display</span>
                      <InfoIcon/></span>}>
                      Das Head-up-Display ist ein Anzeigesystem im Bereich der Windschutzscheibe, welches der Fahrerin / dem Fahrer wichtige Informationen ins Sichtfeld projiziert.
                    </Tooltip>
                  </div>
                </label>
              </div>

              <div className="flex flex-row flex-wrap mt-3">
                <label className="flex justify-start items-start w-1/2 cursor-pointer">
                  <div className="bg-white border border-skin-base w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
                    <input type="checkbox" className="opacity-0 absolute" checked={this.state.digital} onChange={event => this.changeDigital(event.target.checked)}/>
                    <svg className="fill-current hidden w-4 h-4 text-skin-base-accent pointer-events-none" viewBox="0 0 20 20">
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                    </svg>
                  </div>
                  <div className="select-none">
                    <Tooltip label={<span className={'flex space-x-2 truncate'}>
                      <span>Volldigitales Cockpit</span>
                      <InfoIcon/></span>}>
                      Im digitalen Cockpit bzw. volldigitalen Kombiinstrument werden alle Instrumente, also Tachometer, Drehzahlmesser etc. als Anzeigen auf einem Display hinter dem Lenkrad dargestellt. Meist lassen sich hier auch relevante Details zu Navigation, Medien oder Fahrassistenz einblenden.
                    </Tooltip>
                  </div>
                </label>
              </div>

              <div className={'mt-6 flex space-x-2'}>
                <span>Weitere Features addieren wir vor Ort zum Wert Ihres Gebrauchten.</span>
              </div>

              <div className="mt-6 flex flex-row">
                <div className="flex flex-col-reverse md:block w-full md:w-auto">
                  <TertiaryButtonLink buttonId={'button-back'} event={'button-back-to-vehicle-exterior-clicked'} to={'vehicle-exterior'} title={'Schritt zurück'} active={true}/>
                  <PrimaryButtonLink buttonId={'button-to-contact'} event={'button-to-contact-clicked'} to={'contact'} title={'Weiter zu Kontaktdaten'} active={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VehicleInterior
