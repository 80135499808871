import {getItemFromStorage, storeLocalItem} from 'hooks'
import {LeadState, VehicleInterest} from 'redux/reducers/LeadReducer'
import {store} from 'store'

export interface UpdateLead {
  type: 'UPDATE_LEAD'
  lead: LeadState
}

export const updateLead: (lead: LeadState) => UpdateLead = lead => ({
  type: 'UPDATE_LEAD',
  lead,
})

export const updateLeadField = async (field: keyof LeadState, data: any) => {
  const lead = store.getState().lead
  lead[field] = data

  store.dispatch(updateLead(lead))
}

export const storeLeadItem = (field: keyof LeadState, data: any, event: string | undefined = undefined, trackEvent: boolean = true) => {
  storeLocalItem(field, data, trackEvent, event)
  updateLeadField(field, data)
}

export const getStoredLeadString = (field: keyof LeadState, fallback: string | undefined = undefined): string => {
  const storageItem = getItemFromStorage(field) || ''
  const item = store.getState().lead[field] || storageItem || fallback || ''

  storeLocalItem(field, item, false)
  storeLeadItem(field, item)

  return item
}

export const storeVehicleInterest = (data: VehicleInterest) => {
  storeLocalItem('vehicleInterest', data, false)
}

export const getVehicleInterest = (fallback = '{}'): VehicleInterest => {
  return JSON.parse(getItemFromStorage('vehicleInterest') || fallback)
}
