import {track} from 'hooks'
import React from 'react'
import {NavLink} from 'react-router-dom'

const Footer = () => (
  <div className="px-6 lg:px-16 mb-8 mt-24" id="footer">
    <div className="text-skin-button-fill flex flex-col lg:flex-row border-t border-solid border-skin-base pt-4 container mx-auto lg:items-center lg:justify-center">
      <div className="flex flex-row sm:justify-center flex-wrap">
        <NavLink id={'button-to-impressum'} onClick={() => track('footer-link-imprint-clicked')} to={'imprint'} className="cursor-pointer hover:underline hover:text-skin-button-muted">Impressum/rechtliche Hinweise</NavLink>

        <div className="px-4 hidden sm:block">|</div>

        <NavLink id={'button-to-privacy'} onClick={() => track('footer-link-privacy-clicked')} to={'privacy'} className="cursor-pointer hover:underline hover:text-skin-button-muted">Datenschutzerklärung</NavLink>
      </div>
    </div>
  </div>
)

export default Footer
