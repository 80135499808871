import React, {useContext} from 'react'
import {NavLink} from "react-router-dom"
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"
import {getStoredVehicleObject} from "../redux/actions"
import {Dealer} from "../domain-models"
import {AppContext} from "../App"

type Props = {
  title: string,
  procedure: string
}

export const SectionHead = ({title, procedure}: Props) => {
  const {app} = useContext(AppContext)
  let switchTo = 'vin'
  let nextProcedureLink = 'vehicle-vin'
  let linkTitle = 'Zur Fahrgestellnummer/FIN Eingabe wechseln'

  if (procedure === switchTo) {
    switchTo = 'manual'
    nextProcedureLink = 'vehicle-makes'
    linkTitle = 'Zur manuellen Eingabe wechseln'
  }

  const dealer = getStoredVehicleObject('dealer') as Dealer

  return (
    <div className={'flex flex-col '}>
      {
        dealer && dealer.Firm ?
          <div className={'font-semibold mb-1 text-lg'}>Ihr ausgewählter Partner: {dealer?.Firm}</div>
          : null
      }

      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">

        <h3 className="text-xl lg:text-3xl font-bold">{title}</h3>
        {app.config?.disableManualRequests ? null :
          <NavLink id={'button-switch-to-' + switchTo + '-procedure'} to={nextProcedureLink} className="text-skin-button-fill hover:text-skin-button-muted underline cursor-pointer whitespace-pre-wrap md:whitespace-no-wrap"> {linkTitle} <Icon icon={faAngleRight} className="ml-1"/></NavLink>}
      </div>
    </div>
  )
}
