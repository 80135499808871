import {Dealer, Make} from 'domain-models'

export type VehicleState = VehicleBooleans & VehicleObjects & VehicleStrings & VehicleNumbers

export type VehicleBooleans = {
  fourWheelDrive?: boolean
  vatable?: boolean
}

export type VehicleObjects = {
  dealer?: Dealer
  make?: Make
}

export type VehicleStrings = {
  registrationDate?: string
  vin?: string
}

export type VehicleNumbers = {
  body?: number
  fuel?: number
  interior?: number
  numSeats?: number
  kilometers?: number
  model?: number
  power?: number
  prevOwners?: number
  transmission?: number
  color?: number
} & VehicleFlags

export type VehicleFlags = {
  EquipmentFlags0?: number
  EquipmentFlags1?: number
  EquipmentFlags2?: number
  EquipmentFlags3?: number
  EquipmentFlags4?: number
  EquipmentFlags5?: number
  EquipmentFlags6?: number
}

type VehicleAction = {
  type: string
  vehicle: VehicleState
}

const initialState = {}

const vehicleReducer = (state: VehicleState = initialState, action: VehicleAction) => {
  switch (action.type) {
    case 'UPDATE_VEHICLE':
      return {
        ...state,
        ...action.vehicle
      }
    default:
      return state
  }
}

export default vehicleReducer
