import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'

type ModalProps = {
  classes: {
    modal: string | undefined
    modalBox: string | undefined
    button: string | undefined
  }
  title: any
  content: any
  buttonContent: any
  onClick?: CallableFunction
  buttonId: string
  icon?: any
}

export default function TransitionsModal(props: ModalProps) {
  const [open, setOpen] = React.useState(false)

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    props.onClick && props.onClick(event)
    setOpen(true)
  }

  return (
    <>
      <button id={props.buttonId} type="button" onClick={handleOpen} className={props.classes.button + ' focus:outline-none'}>
        {props.buttonContent}
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-skin-modal text-skin-modal-inverted rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl lg:max-w-4xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" className="text-white rounded-md text-skin-modal-inverted hover:text-skin-modal-inverted-muted focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <Icon icon={faTimes} className="h-6 w-6" aria-hidden="true"/>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div>
                    {props.icon}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="mt-2">
                      {props.content}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}