import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import React from 'react'

type LoadingIndicatorProps = {}

export default function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <div className={'flex flex-col bg-gray-200 text-gray-800 w-full h-full rounded-lg py-12 px-12 mt-6 items-center justify-center'}>
      <div className={'flex flex-col relative'}>
        <span className={'text-xs text-center mt-6'}>
          <Icon icon={faSpinner} spin={true} className={'mr-2'}/> Preisbewertung wird geladen
        </span>
      </div>
    </div>
  )
}
