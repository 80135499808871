import {getItemFromStorage, storeLocalItem} from 'hooks'
import _ from 'lodash'
import {AppBooleans, AppNumbers, AppObjects, AppState, AppStrings} from 'redux/reducers/AppReducer'
import {store} from 'store'

export interface UpdateApp {
  type: 'UPDATE_APP'
  app: AppState
}

export const updateApp: (app: AppState) => UpdateApp = app => ({
  type: 'UPDATE_APP',
  app,
})

export const updateAppField = async (field: keyof AppState, data: any) => {
  const app = store.getState().app
  app[field] = data

  store.dispatch(updateApp(app))
}

export const storeAppItem = (field: keyof AppState, data: any, event: string | undefined = undefined, trackEvent: boolean = true) => {
  storeLocalItem(field, data, trackEvent, event)
  updateAppField(field, data)
}

export const getStoredAppString = (field: keyof AppStrings, fallback: string | undefined = undefined): string => {
  const storageItem = getItemFromStorage(field) || ''
  const item = store.getState().app[field] || storageItem || fallback || ''

  storeLocalItem(field, item, false)
  storeAppItem(field, item)

  return item
}

export const getStoredAppObject = (field: keyof AppObjects, fallback: object | undefined = undefined, create: boolean = true): object => {
  const storageItem = getItemFromStorage(field) || ''
  let item
  try {
    item = store.getState().app[field] || storageItem ? JSON.parse(storageItem) : fallback || {}
  } catch (e) {
    item = fallback || ''
  }

  if (create) {
    storeLocalItem(field, item, false)
    storeAppItem(field, item)
  }

  return item
}

export const getStoredAppNumber = (field: keyof AppNumbers, fallback: number | undefined = undefined): number => {
  const storageItem = getItemFromStorage(field) || ''
  let item = store.getState().app[field] || storageItem || fallback || 0

  item = _.toInteger(item)

  storeLocalItem(field, item, false)
  storeAppItem(field, item)

  return item
}

export const getStoredAppBoolean = (field: keyof AppBooleans, fallback: boolean | undefined = undefined, create = true): boolean => {
  const stringBoolean = getItemFromStorage(field) || ''
  const storageItem = stringBoolean === 'true'

  const stateItem = store.getState().app[field]
  let item = stateItem || storageItem || fallback || false

  if (create) {
    storeLocalItem(field, item, false)
    updateAppField(field, item)
  }

  return item
}