import {getItemFromStorage, storeLocalItem} from 'hooks'
import _ from 'lodash'
import {VehicleBooleans, VehicleNumbers, VehicleObjects, VehicleState, VehicleStrings} from 'redux/reducers/VehicleReducer'
import {store} from 'store'

export interface UpdateVehicle {
  type: 'UPDATE_LEAD'
  vehicle: VehicleState
}

export const updateVehicle: (vehicle: VehicleState) => UpdateVehicle = vehicle => ({
  type: 'UPDATE_LEAD',
  vehicle,
})

export const updateVehicleField = async (field: keyof VehicleState, data: any) => {
  const vehicle = store.getState().vehicle
  vehicle[field] = data

  store.dispatch(updateVehicle(vehicle))
}

export const storeVehicleItem = (field: keyof VehicleState, data: any, event: string | undefined = undefined, trackEvent: boolean = true) => {
  storeLocalItem(field, data, trackEvent, event)
  updateVehicleField(field, data)
}

export const getStoredVehicleString = (field: keyof VehicleStrings, fallback: string | undefined = undefined): string => {
  const storageItem = getItemFromStorage(field) || ''
  const item = store.getState().vehicle[field] || storageItem || fallback || ''

  storeLocalItem(field, item, false)
  storeVehicleItem(field, item)

  return item
}

export const getStoredVehicleObject = (field: keyof VehicleObjects, fallback: object | undefined = undefined): object => {
  const storageItem = getItemFromStorage(field) || ''
  let item
  try {
    item = store.getState().vehicle[field] || storageItem ? JSON.parse(storageItem) : fallback || {}
  } catch (e) {
    item = fallback || ''
  }

  storeLocalItem(field, item, false)
  storeVehicleItem(field, item)

  return item
}

export const getStoredVehicleNumber = (field: keyof VehicleNumbers, fallback: number | undefined = undefined): number => {
  const storageItem = getItemFromStorage(field) || ''
  let item = store.getState().vehicle[field] || storageItem || fallback || 0

  item = _.toInteger(item)

  storeLocalItem(field, item, false)
  storeVehicleItem(field, item)

  return item
}


export const getStoredVehicleBoolean = (field: keyof VehicleBooleans, fallback: boolean | undefined = undefined): boolean => {
  const stringBoolean = getItemFromStorage(field) || ''
  const storageItem = stringBoolean === 'true'

  let item = store.getState().vehicle[field] || storageItem || fallback || false

  storeLocalItem(field, item, false)
  storeVehicleItem(field, item)

  return item
}