import {track} from 'hooks'
import React from 'react'
import {NavLink} from 'react-router-dom'
import {classNames} from "../utils/class-names"

type ButtonLinkProps = {
  event: string
  to: string
  title: string
  active: boolean
  buttonId: string
}

const TertiaryButtonLink = (props: ButtonLinkProps) => (
  <NavLink onClick={(e) => {
    if (!props.active) {
      return e.preventDefault()
    }
    if (props.event) {
      track(props.event)
    }
  }} to={props.to} id={props.buttonId} className={classNames(
    'rounded-skin-button text-center text-skin-button-secondary-text border border-solid border-skin-button-secondary-border hover:border-skin-button-secondary-border-muted p-skin-button my-2 lg:my-0 shadow',
    'bg-skin-button-secondary-fill hover:bg-skin-button-secondary-muted'
  )}>{props.title}</NavLink>
)

export default TertiaryButtonLink