import React from 'react'
import {classNames} from "../utils/class-names"
import {isAudiSite} from "../hooks"

type ItemSelectionProps = {
  icon: any | null
  title: string
  checked: boolean
  onClick: CallableFunction
  classes?: string
  parentClasses?: string
  variant: Variant
}


enum Variant {
  SLIM,
  WIDE,
}

const VARIANTS: Record<Variant, string> = {
  [Variant.SLIM]: 'w-56 h-12 m-2',
  [Variant.WIDE]: 'w-36 h-24 m-2',
}

const ItemSelection = (props: ItemSelectionProps) => (
  <div className={classNames('relative', props.classes ? (' ' + props.classes + ' ') : VARIANTS[props.variant])}>
    <div className={classNames(
      'border-2 border-solid flex items-center justify-center flex-col cursor-pointer select-none h-full w-full',
      (props.checked ? 'border-skin-button-fill item-selection' : 'opacity-60'),
      isAudiSite() ? 'rounded-skin-button' : 'rounded-lg hover:bg-gray-100',
      props.checked ? 'rounded-tr-lg' : '',
    )} onClick={() => props.onClick()}>

      {props.icon ? props.icon : null}

      {
        isAudiSite() && props.title === 'Audi' ? null :
          <span className="text-skin-button-fill text-sm text-center">{props.title}</span>
      }
    </div>
  </div>
)

ItemSelection.defaultProps = {
  variant: Variant.WIDE
}

ItemSelection.variant = Variant

export default ItemSelection
