import React, {ReactNode, useState} from 'react'

type Props = {
  children?: ReactNode
  label: ReactNode
}


export function Tooltip({children, label}: Props) {
  const [isShown, setIsShown] = useState(false)

  return (
    <div className={'relative'} onClick={(event) => {
      event.preventDefault()
      setIsShown(!isShown)
    }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <div className={''}>
        {label}
      </div>

      {
        isShown && (
          <div className={'px-4 py-2 absolute z-50 inline-block text-sm w-full min-w-min xl:min-w-max rounded-lg border shadow border-skin-modal-inverted bg-skin-modal text-skin-modal-inverted'} style={{minWidth: '150px'}}>
            {children}
          </div>
        )
      }
    </div>
  )
}


export default Tooltip