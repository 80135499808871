import {LocationMatch} from 'components/Router'
import {hostname, isAudiSite, isSkodaSite, track} from 'hooks'
import React from 'react'
import {classNames} from "../utils/class-names"
import {Banner, FallbackBanner} from "./Banner"
import {getStoredVehicleObject} from "../redux/actions"
import {Dealer} from "../domain-models"
import {NavLink} from "react-router-dom"

type HeaderProps = {
  step: number
  match: LocationMatch
}

export default function Header(props: HeaderProps) {
  const dealer = getStoredVehicleObject('dealer') as Dealer

  return props.match.params.domain ?
    <div className={'font-sans-headline'}>
      <div className="flex flex-col mt-4 lg:mt-2">
        <div className="font-semibold text-sm w-full text-right md:whitespace-no-wrap">
          Diese Seite wird bereitgestellt durch die AUTOHAUSEN® AG. - <NavLink id={'button-to-impressum'} onClick={() => track('footer-link-imprint-clicked')} to={'imprint'} className="cursor-pointer hover:underline hover:text-skin-button-muted text-skin-button-fill">Impressum</NavLink>
        </div>
        {
          props.match.params.dealerId ?
            <Banner src={'https://ars-mia.de/images/pricetool/' + props.match.params.dealerId + '.jpg'} alt={'logo'}/>
            : <FallbackBanner show/>
        }
      </div>

      <div className={'flex-row w-full mt-8 text-skin-base' + (props.step === 0 ? ' hidden ' : ' flex ')}>
        <div className={classNames('flex flex-col w-1/3', isAudiSite() ? 'mx-2' : '')}>
          <span className={'border-b-2 border-solid pb-1' + (props.step >= 2 ? ' border-skin-base' : ' border-skin-status-muted')}>01</span>
          <span className={'pt-1 text-xs lg:text-sm' + (
            props.step === 1 ? 'sm:block font-bold text-skin-base' : ' hidden lg:block'
          )}>Fahrzeugdaten eingeben
          </span>
        </div>

        <div className={classNames('flex flex-col w-1/3', isAudiSite() ? 'mx-2' : '')}>
          <span className={'border-b-2 border-solid pb-1 cursor-pointer' + (props.step >= 3 ? ' border-skin-base' : ' border-skin-status-muted')}>02</span>
          <span className={'pt-1 text-xs lg:text-sm' + (
            props.step === 2 ? 'sm:block font-bold text-skin-base' : ' hidden lg:block'
          )}>Kontaktdaten eingeben
          </span>
        </div>
        <div className={classNames('flex flex-col w-1/3', isAudiSite() ? 'mx-2' : '')}>
          <span className={'border-b-2 border-solid pb-1' + (props.step >= 4 ? ' border-skin-base' : ' border-skin-status-muted')}>03</span>
          <span className={'pt-1 text-xs lg:text-sm' + (
            props.step === 3 ? 'sm:block font-bold text-skin-base' : ' hidden lg:block'
          )}>Preisbewertung erhalten
          </span>
        </div>
      </div>
    </div>
    : (
      <div className={'font-sans-headline'}>
        <div className="flex flex-col lg:flex-row lg:justify-between mt-4 lg:mt-2">
          <span>&nbsp;</span>
          <div className="font-semibold text-sm w-full text-right md:whitespace-no-wrap">
            Diese Seite wird bereitgestellt durch die AUTOHAUSEN® AG. - <NavLink id={'button-to-impressum'} onClick={() => track('footer-link-imprint-clicked')} to={'imprint'} className="cursor-pointer hover:underline hover:text-skin-button-muted text-skin-button-fill">Impressum</NavLink>
          </div>
        </div>

        <div className={'flex-row w-full mt-8 text-skin-base' + (props.step === 0 ? ' hidden ' : ' flex ')}>
          <div className={classNames('flex flex-col w-1/4', isAudiSite() ? 'mx-2' : '')}>
            <span className={'border-b-2 border-solid pb-1' + (props.step >= 1 ? ' border-skin-base' : ' border-skin-status-muted') + (props.step === 1 ? ' font-bold ' : '')}>01</span>
            <span className={'pt-1 text-xs lg:text-sm ' + (
              props.step === 1 ? ' sm:block font-bold text-skin-base ' : ' font-bold hidden lg:block'
            )}>{hostname().includes('audi.') ? 'Audi' : isSkodaSite() ? 'ŠKODA' : 'Volkswagen'} Partner finden
            </span>
          </div>
          <div className={classNames('flex flex-col w-1/4', isAudiSite() ? 'mx-2' : '')}>
            <span className={'border-b-2 border-solid pb-1' + (props.step >= 2 ? ' border-skin-base' : ' border-skin-status-muted') + (props.step === 2 ? ' font-bold ' : '')}>02</span>
            <span className={classNames('pt-1  lg:text-sm ', (
                props.step === 2 ? ' sm:block font-bold text-skin-base ' : ' hidden lg:block'
              ),
              isAudiSite() ? 'text-xxs md:text-xs' : 'text-xs'
            )}>Fahrzeugdaten eingeben
            </span>
          </div>
          <div className={classNames('flex flex-col w-1/4', isAudiSite() ? 'mx-2' : '')}>
            <span className={'border-b-2 border-solid pb-1' + (props.step >= 3 ? ' border-skin-base' : ' border-skin-status-muted') + (props.step === 3 ? ' font-bold ' : '')}>03</span>
            <span className={classNames('pt-1 text-xs lg:text-sm ', (
                props.step === 3 ? ' sm:block font-bold text-skin-base ' : ' hidden lg:block'
              ),
              isAudiSite() ? 'text-xxs md:text-xs' : 'text-xs'
            )}>Kontaktdaten eingeben
            </span>
          </div>
          <div className={classNames('flex flex-col w-1/4', isAudiSite() ? 'mx-2' : '')}>
            <span className={'border-b-2 border-solid pb-1' + (props.step >= 4 ? ' border-skin-base' : ' border-skin-status-muted') + (props.step === 4 ? ' font-bold ' : '')}>04</span>
            <span className={classNames('pt-1 text-xs lg:text-sm ', (
                props.step === 4 ? ' sm:block font-bold text-skin-base ' : ' hidden lg:block'
              ),
              isAudiSite() ? 'text-xxs md:text-xs' : 'text-xs'
            )
            }>Preisbewertung erhalten
            </span>
          </div>
        </div>
      </div>
    )
}
