import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import _ from "lodash"

const createMask = (value: string) => {
  const valueArray = _.split(value, '')

  let mask = ['(', /\d/, ')', ' ']
  if (valueArray) {
    valueArray.forEach((char: string, index: number) => {
      if (index === 0) {
        return
      }

      mask.push(/\d/)
    })
  }

  if (typeof mask[8] !== 'undefined') {
    mask.splice(8, 0, ' ')
    mask.splice(8, 0, '/')
    mask.splice(8, 0, ' ')
  }


  if (typeof mask[15] !== 'undefined') {
    mask.splice(15, 0, ' ')
  }

  return mask
}

function InputMasked(props: any) {
  const {inputRef, ...other} = props
  const [value, setValue] = React.useState(props.value.replace(/[^0-9]/g, ""))
  const [mask, setMask] = React.useState(createMask(value))
  const [isValid, setValid] = React.useState(!value.length || value.length >= 8)

  return (
    <MaskedInput
      {...other} ref={(ref) => {
      inputRef(ref ? ref.inputElement : null)
    }} mask={mask} placeholderChar={'\u2000'} onFocus={() => {
      props.onFocus && props.onFocus()
      setValid(true)
      mask.push(/\d/)
    }} onClickCapture={() => {
      props.onClickCapture && props.onClickCapture()
      setValid(true)
      mask.push(/\d/)
    }} onBlur={() => {
      props.onBlur && props.onBlur()
      setMask(createMask(value))
      setValid(!value.length || value.length >= 8)
    }} style={{color: isValid ? 'black' : 'red'}} maxLength={25} onChange={async (event) => {
      const newValue = event.target.value.replace(/[^0-9]/g, "")
      const newMask = createMask(newValue)
      newMask.push(/\d/)
      setMask(newMask)

      setValue(newValue)
      props.onChange && props.onChange(event)
    }} showMask={!!value}/>
  )
}

InputMasked.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export default InputMasked
