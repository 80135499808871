import bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {AxiosError, AxiosInstance} from 'axios'
import {isUsingIE} from 'hooks'
import _ from 'lodash'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import {store} from 'store'
import swal from 'sweetalert2'
import Router, {history} from './components/Router'
import './css/main.css'
import * as serviceWorker from './serviceWorker'
import {classNames} from "./utils/class-names"
import {PrimaryButtonStyling, PrimaryButtonStylingActive} from "./components/PrimaryButton"

window.axios = require('axios')

library.add(fas, fab, far)

declare global {
  interface Window {
    dataLayer: any
    Popper: any
    axios: AxiosInstance
    __forceSmoothScrollPolyfill__: boolean
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    ActiveXObject: any
  }
}

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.axios.interceptors.response.use((response) => {
  return response
}, (error: AxiosError) => {
  if (error && error.request && error.request.status === 500 && process.env.REACT_APP_RELEASE_STAGE === 'production') {
    swal.fire({
      title: 'Ein unerwarteter Fehler ist aufgetreten!',
      text: 'Wir arbeiten mit hochdruck daran den Fehler zu beheben. Versuchen Sie es bitte erneut.',
      icon: 'error',
      customClass: {
        confirmButton: classNames(PrimaryButtonStyling, PrimaryButtonStylingActive)
      }
    })
  }
  return Promise.reject(error)
})

const BugsnagClient = bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  collectUserIp: false,
  appVersion: '1.0.1',
  enabledReleaseStages: ['production'],
  enabledBreadcrumbTypes: ['error', 'request', 'navigation'],
  enabledErrorTypes: {unhandledRejections: false},
  releaseStage: process.env.REACT_APP_RELEASE_STAGE || 'local',
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (isUsingIE()) {
      return
    }
    event.addMetadata('app', {location: history.location})
    event.errors[0].errorClass = '[PRICETOOL][FRONTEND] ' + event.errors[0].errorClass + ': ' + event.errors[0].errorMessage
    event.addMetadata('app', {'trackingId': store.getState().app.trackingId})
    const appState = _.cloneDeep(store.getState().app)
    appState.codes = undefined
    event.addMetadata('app', {app: appState, vehicle: store.getState().vehicle, lead: store.getState().lead})
  },
  onUnhandledRejection: (event) => {
    return
  }
})

const ErrorBoundary = BugsnagClient.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(<ErrorBoundary><Router/></ErrorBoundary>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
