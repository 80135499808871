import TransitionsModal from 'components/TransitionsModal'
import {isAudiSite, track} from 'hooks'
import React from 'react'
import InfoIcon from "./InfoIcon"

const ValuationValueExplanation = () => (
  <TransitionsModal classes={{
    modal: 'sm:flex items-center justify-center overflow-y-auto',
    modalBox: '',
    button: 'underline text-left'
  }} buttonId={'button-valuation-explanation'} onClick={() => {
    track('valuation-explanation-opened')
  }} title={null} buttonContent={
    <div className={'flex space-x-2'}>
      <InfoIcon/>
      <span> Wie wird der Ankaufswert berechnet?</span>
    </div>
  } content={
    <div className={'flex flex-col'}>
      <h3 className={'font-bold text-2xl text-left'}>Wie wird der Ankaufswert meines Fahrzeugs berechnet?</h3>

      <div className={'rounded py-4 text-left'}>
        <p>Der gezeigte Ankaufswert spiegelt den aktuellen Ankaufswert Ihres Gebrauchten für den Ankauf oder die Inzahlungnahme durch den Händler wider. {isAudiSite() ? 'Wir nutzen dafür sowohl DAT Marktdaten als auch aktuelle Marktpreise' : 'Wir nutzen dafür sowohl DAT Marktdaten als auch Preisdaten von mobile.de.'}</p>
        <p className={'mt-4'}>Der gezeigte Wert dient zur Orientierung - Wert beeinflussende Regionalfaktoren, Sonderausstattungen und der individuelle Zustand Ihres Fahrzeugs können hier nicht vollends berücksichtigt werden.</p>
        <p className={'mt-4'}>Aus diesen Gründen kann die endgültige Bewertung erst nach einer Prüfung des Fahrzeugs durch den Händler bzw. einen Kfz-Sachverständigen erfolgen.</p>
      </div>
    </div>
  }/>
)

export default ValuationValueExplanation
