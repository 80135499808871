import {track} from 'hooks'
import React from 'react'
import {classNames} from 'utils/class-names'

type ButtonProps = {
  event: string
  title: string
  active: boolean
  margin?: string
  onClick: CallableFunction
  buttonId: string
}

const TertiaryButton = (props: ButtonProps) => (
  <button id={props.buttonId} onClick={(e) => {
    if (!props.active) {
      return e.preventDefault()
    }
    if (props.event) {
      track(props.event)
    }
    props.onClick && props.onClick()
  }} className={classNames('rounded-skin-button text-center text-skin-button-secondary-text border border-solid border-skin-button-secondary-border hover:border-skin-button-secondary-border-muted p-skin-button my-2 lg:my-0 shadow bg-skin-button-secondary-fill hover:bg-skin-button-secondary-muted',
    (props.margin ? props.margin : 'ml-0 md:ml-4'),
    (props.active ? '' : 'opacity-50 cursor-not-allowed')
  )}>{props.title}</button>
)

export default TertiaryButton