import {combineReducers} from 'redux'
import AppReducer from 'redux/reducers/AppReducer'
import LeadReducer from 'redux/reducers/LeadReducer'
import VehicleReducer from 'redux/reducers/VehicleReducer'

const rootReducer = combineReducers({
  app: AppReducer,
  vehicle: VehicleReducer,
  lead: LeadReducer,
})

export default rootReducer
