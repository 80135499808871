import {faAngleRight, faCrosshairs, faMapMarkerAlt, faSearch, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome'
import {FormGroup, TextField} from '@material-ui/core'
import axios from 'axios'
import Header from 'components/Header'
import {history, LocationMatch} from 'components/Router'
import {Dealer} from 'domain-models'
import {createUrl, isUsingIE, track} from 'hooks'
import React, {createRef} from 'react'
import {storeVehicleItem} from 'redux/actions'
import swal from 'sweetalert2'
import _ from "lodash"
import {classNames} from "../utils/class-names"

type SelectDealerProps = {
  match: LocationMatch
  hideHeader?: boolean
  onChange?: CallableFunction
}
type SelectDealerState = {
  dealers: Array<Dealer>
  isLoading: boolean
  search: string | null
  searchingLocation: boolean
}

const CancelToken = axios.CancelToken
let cancel: CallableFunction | null = null

type GetLocationResponse = {
  dealers: Array<Dealer>
}

class SelectDealer extends React.Component<SelectDealerProps, SelectDealerState> {
  private searchRef = createRef<HTMLInputElement>()

  constructor(props: SelectDealerProps) {
    super(props)

    this.state = {
      dealers: [],
      isLoading: false,
      search: null,
      searchingLocation: false,
    }
  }

  search = async (value: string | null) => {
    if (!value) {
      return this.setState({dealers: [], isLoading: false, search: value})
    }

    await this.setState({dealers: [], isLoading: true, search: value})

    cancel && cancel()

    if (!value || value.length !== 5) {
      return this.setState({isLoading: false})
    }

    const dealers = await window.axios.post<Array<Dealer>>('/api/get-dealers', {search: value}, {cancelToken: new CancelToken((c) => cancel = c)})
      .then((response) => {
        track('dealer-searched', value)
        return response
      })

    if (!dealers || !dealers.data || !dealers.data.length) {
      this.setState({isLoading: false})
      return swal.fire({
        title: 'Keine Autohäuser gefunden!',
        text: 'Die PLZ oder der Ort, den Sie eingegeben haben, existiert nicht, oder wird von uns nicht gefunden. Bitte korrigieren Sie Ihre Eingabe',
        icon: 'warning',
      })
    }

    if (isUsingIE()) {
      dealers.data = _.take(dealers.data, 10)
    }

    await this.setState({
      dealers: dealers.data,
      isLoading: false,
    })

    cancel = null
  }

  selectDealer = (dealer: Dealer) => {
    storeVehicleItem('dealer', dealer)

    if (this.props.hideHeader) {
      this.props.onChange && this.props.onChange(dealer)
      return
    }
    history.push(createUrl('/procedure-selection', this.props.match))
  }

  onGetLocationError = () => {
    swal.fire({
      title: 'Wir konnten Ihren Standort leider nicht finden',
      text: 'Bitte prüfen Sie ob Ihr Browser die Ortungsdienste Ihres Geräts verwenden darf.',
      icon: 'warning',
    })
  }

  getLocation = async () => {
    const geolocation = navigator.geolocation
    this.setState({searchingLocation: true, dealers: []})

    geolocation.getCurrentPosition(async location => {
      let response = await axios.post<GetLocationResponse>('/api/get-location', {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      })

      if (!response.data.dealers) {
        this.onGetLocationError()
      }

      this.setState({dealers: response.data.dealers, searchingLocation: false})
    }, () => {
      this.onGetLocationError()
      this.setState({searchingLocation: false})
    })
  }

  render() {
    return <div className={classNames("flex flex-col", this.props.hideHeader ? '' : 'px-6 lg:px-16')}>
      {
        !this.props.hideHeader && <Header step={1} match={this.props.match}/>
      }

      <div className={classNames("mt-6 max-w-lg container flex flex-col", this.props.hideHeader ? '' : 'lg:mt-24 mx-auto')}>
        <h3 className="text-xl md:text-2xl lg:text-3xl font-bold">Finden Sie Ihren Händler</h3>
        <div className={classNames("mt-2 block w-full mb-1", this.props.hideHeader ? '' : 'lg:mt-12')}>
          <div className="inline-flex w-full h-full">
            <span className={'border-transparent border-4 text-skin-button-fill rounded mt-auto text-sm'}>
              <Icon icon={faSearch} fixedWidth={true} className="mr-2"/>
            </span>
            <FormGroup row={true} className={'w-full h-full'}>
              <TextField type={'number'} id="address" label="PLZ eingeben" className={'w-full'} value={this.state.search || ''} onChange={e => this.search(e.target.value)}/>
            </FormGroup>

            {
              navigator.geolocation ?
                <span className={'border-transparent border-4 text-skin-button-fill hover:text-skin-button-muted cursor-pointer rounded mt-auto text-sm'} onClick={() => this.getLocation()}>
                  {
                    this.state.searchingLocation ?
                      <Icon icon={faSpinner} spin={true} fixedWidth={true} className="ml-2"/>
                      :
                      <>
                        <Icon icon={faCrosshairs} fixedWidth={true} className="ml-2"/>
                      </>
                  }
                </span>
                : null
            }
          </div>
        </div>

        {
          this.state.isLoading ?
            <div className={'w-full h-full flex justify-center mt-4'}>
              <Icon icon={faSpinner} spin={true}/>
            </div>
            :
            <div className={classNames('w-full h-full overflow-y-auto mt-2', this.state.dealers.length ? 'border border-r-0 border-gray-300 shadow-sm rounded' : '')} style={{maxHeight: '500px'}}>
              {this.state.dealers.map((dealer: Dealer, index) => (
                <div key={index} className="flex flex-row text-xs items-center hover:bg-skin-button-secondary-muted cursor-pointer px-4 py-2 border-b-list" id={'button-select-dealer-' + dealer.DealerId} onClick={() => this.selectDealer(dealer)}>
                  <div className={'w-1/5 whitespace-no-wrap w-15'}>
                    <Icon icon={faMapMarkerAlt} className="mr-1 text-skin-button-muted"/>
                    <span>{dealer.Distance} km</span>
                  </div>

                  <span className="flex flex-col ml-4 w-3/4">
                    <span className="font-semibold">{dealer.Firm}</span>
                    <span>{dealer.Street}, {dealer.PostalCode} {dealer.City}</span>
                  </span>

                  {
                    navigator.geolocation ?
                      <Icon icon={faAngleRight} fixedWidth={true} className="ml-auto"/>
                      : null
                  }
                </div>
              ))}
            </div>
        }
      </div>
    </div>
  }
}


export default SelectDealer
