export type LeadState = {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  intention?: 'no-intention' | 'new-vehicle-purchase' | 'used-vehicle-purchase'
  salutation?: string
  dealerNotice?: string
}

export type VehicleInterest = {
  Marke?: string
  Modell?: string
  KommNr?: string
  Modellcode?: string
  PreOwn?: string
  EZ?: string
}

type LeadAction = {
  type: string
  lead: LeadState
}

const initialState = {}

const leadReducer = (state: LeadState = initialState, action: LeadAction) => {
  switch (action.type) {
    case 'UPDATE_LEAD':
      return {
        ...state,
        ...action.lead
      }
    default:
      return state
  }
}

export default leadReducer
