import {LocationMatch} from 'components/Router'
import sha256 from 'crypto-js/sha256'
import {Dealer} from 'domain-models'
import _ from 'lodash'
import {getStoredAppBoolean, getStoredAppObject, getStoredVehicleNumber, getStoredVehicleObject, storeAppItem, storeVehicleItem, updateAppField} from 'redux/actions'
import {VehicleFlags} from 'redux/reducers/VehicleReducer'
import {THEMES, Themes} from "./App"
import {classNames} from "./utils/class-names"

export const track = _.debounce(async (event: string, data?: any, ignoreCookies: boolean = false) => {
  if (!event) {
    return
  }
  if (event.includes('codes')) {
    data = null
  }

  if (data && data.constructor === Object && !data.referrer) {
    data.referrer = localStorage.getItem('referrer') || document.referrer
  }

  if (!ignoreCookies && !getStoredAppBoolean('cookiesAccepted', false, false)) {
    storeEventPayload(event, data, 'track')
  }
  if (!ignoreCookies && !getStoredAppBoolean('statistic', false, false)) {
    return
  }
  const dealer = getStoredVehicleObject('dealer') as Dealer | null

  const trackEvent = {
    event,
    dealerId: dealer ? dealer.DealerId || null : null,
    data,
  }

  const trackingHash = sha256(JSON.stringify(trackEvent)).toString()
  const trackingHashes = getStoredAppObject('trackingHashes', []) as Array<string>

  if (!trackingHashes.includes(trackingHash)) {
    trackingHashes.push(trackingHash)

    storeAppItem('trackingHashes', trackingHashes, undefined, false)

    await window.axios.post('/api/track', trackEvent)
  }
}, 600)

const storeEventPayload = (event: string, data: any, type: 'track') => {
  const events = getStoredAppObject('eventPayload', []) as Array<{ event: string, data: any, type: 'track' }>

  if (event === 'session-close' || _.find(events, {event, type})) {
    return
  }

  events.push({event, data, type})
  storeAppItem('eventPayload', events, '', false)
}

export const removeFlag = async (flag: keyof VehicleFlags, bit: number) => {
  let currentValue = await getStoredVehicleNumber(flag)
  storeVehicleItem(flag, (currentValue &= ~bit))
}

export const setFlag = async (flag: keyof VehicleFlags, bit: number) => {
  let currentValue = await getStoredVehicleNumber(flag)
  storeVehicleItem(flag, (currentValue |= bit))
}

export const isFlagSet = (flag: keyof VehicleFlags, bit: number): boolean => {
  return !!(getStoredVehicleNumber(flag) & bit)
}

export const createUrl = (path: string, match: LocationMatch) => {
  return (match.params.domain ? '/' + match.params.domain : '') + (match.params.dealerId ? '/' + match.params.dealerId : '') + path
}

export const exportEventPayload = async () => {
  const events = getStoredAppObject('eventPayload', []) as Array<{ event: string, data: any, type: 'track' }>
  storeAppItem('eventPayload', [], '', false)

  _.forEach(events, event => {
    if (event.type === 'track') {
      track(event.event, event.data)
    }
  })

  storeAppItem('eventPayload', [], '', false)
}

export const storeLocalItem = (field: string, data: any, trackEvent: boolean, event: string | undefined = undefined) => {
  try {
    if (trackEvent && !(getItemFromStorage(field) === data || getItemFromStorage(field) === JSON.stringify(data))) {
      track(event ? event : (field + '_stored'), data)
    }
    localStorage.setItem(field, _.isString(data) ? data : JSON.stringify(data))
  } catch (error) {
  }
}

export const getItemFromStorage = (field: string): string | null => {
  let item = null

  try {
    item = localStorage.getItem(field)
  } catch (error) {
  }

  return item
}

export const hostname = () => {
  return window.location.hostname
}

export const isVolkswagenSite = () => {
  return !isAudiSite() && !isSkodaSite() && (hostname().includes('gib-uns-deinen-gebrauchten.de') || hostname().includes('was-ist-mein-gebrauchter-wert.de'))
}

export const isNissanSite = () => {
  return hostname().includes('inzahlungnahme.nissan.de') || hostname().includes('nissan')
}

export const isAudiSite = () => {
  return hostname().includes('audi.gib-uns-deinen-gebrauchten.de')
}

export const isSkodaSite = () => {
  return hostname().includes('skoda.gib-uns-deinen-gebrauchten.de')
}

export const loadGaScript = async () => {
  if (!getStoredAppBoolean('gaLoaded', false, false)) {
    const script = document.createElement("script")

    script.src = '/ga.js'

    document.getElementsByTagName("head")[0].prepend(script)

    await updateAppField('gaLoaded', true)
  }
}

export const isUsingIE = () => {
  const userAgent = window.navigator.userAgent
  const msie = userAgent.indexOf("MSIE ")

  return msie > 0 || (!(window.ActiveXObject) && "ActiveXObject" in window)
}

export const applyTheme = (theme: Themes) => {
  document.body.className = classNames('font-sans text-smooth h-full mx-auto flex flex-col justify-between overflow-y-auto', THEMES[theme])
}
